import React, {useEffect} from 'react'
import {ReactComponent as BackArrow} from "../../../assets/left_arrow_black.svg";
import {ReactComponent as GreatJob} from "../../../assets/great-job.svg";
import Button from "../../../components/button/Button";
import "./Congratulations.css"

interface CongratulationsPropType {
    continueFromCongratulationsHandler: () => void,
    onBackArrowClick: () => void;
}

const Congratulations: React.FC<CongratulationsPropType> = ({continueFromCongratulationsHandler, onBackArrowClick}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={'congrats-page-container'}>
            <div className={'congrats-header-container '}>
                <BackArrow onClick={onBackArrowClick} width={48} height={48}/>
                <div/>
                <div/>
            </div>
            <div className={'congrats-body-container'}>
                <GreatJob style={{marginLeft:'8px',width:'100%',alignSelf:'center'}} height={57}/>
                <span className={"congrats-title"}>
                    {`Your reminders are set!`}
                </span>
                <span className={"congrats-title"}>
                    {`You can change the reminders anytime from`}
                </span>
                <span className={"congrats-title"}>
                    {`the 'profile' section.`}
                </span>
            </div>
            <Button isSticky={true} style={{alignSelf: 'center', marginBottom: '24px'}}
                    onClick={continueFromCongratulationsHandler}
                    label={'Sign Up'}></Button>
        </div>
    )
}

export default Congratulations;
