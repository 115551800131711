import { initializeApp } from "firebase/app";
import { getAuth,signInWithPhoneNumber,RecaptchaVerifier } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import {CLOUD_FUNCTION_URL, postRequest} from "./Axios";
import {Mixpanel} from "../analytics/Mixpanel";
import {getCurrentDate} from "../utils/utils";
import {getStorage} from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use



const firebaseConfig = {
    apiKey: "AIzaSyDSg1X4BeBxcbApXKgMaftCzDACXKnS9UE",
    authDomain: "seek-c3955.firebaseapp.com",
    projectId: "seek-c3955",
    storageBucket: "seek-c3955.appspot.com",
    messagingSenderId: "1084470586799",
    appId: "1:1084470586799:web:204180414fe4ad61a6935a",
    measurementId: "G-0MBVXXGMZP"
};



//
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage(app);
// const auth = getAuth();



export const SignInWithPoneNumber = async (phoneNumber) =>{
    // console.log("SignInWithPoneNumber",phoneNumber)
    try {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {});
        const appVerifier = window.recaptchaVerifier;
        const confirmationResult = await signInWithPhoneNumber(auth,'+'+phoneNumber,appVerifier);
        // console.log("confirmationResult",confirmationResult);
        return confirmationResult;
    }catch (e) {
        // console.log("confirmationResult error",e.toString());
    }

}

export const IsUserLoggedIn = () =>{
    const [user] = useAuthState(auth);
    return user;
}

// const provider = new GoogleAuthProvider();
// provider.setCustomParameters({
//     prompt: "select_account"
// });

// function isEmpty(obj) {
//     if(obj){
//         return Object.keys(obj).length === 0;
//     }
//     return true;
//
// }

// export const signInWithGoogle = async () => {
//     try {
//         const result = await signInWithPopup(auth,provider);
//         console.log("signInWithGoogle",result.user);
//         const userResponse = await postRequest(`${CLOUD_FUNCTION_URL}/user-getuserbyid`,{email:result.user.email});
//         const firebaseUser = userResponse.data?.user;
//         if (!isEmpty(firebaseUser)) {
//             const user = firebaseUser;
//             return {
//                 ...user,
//                 id:userResponse.data?.userID,
//                 isNewUser: false,
//             };
//         } else {
//             const firebaseUser = result.user;
//             const nameParts = firebaseUser?.displayName?.split(" ");
//             const fName = nameParts[0]; // First name
//             const lName = nameParts.slice(1).join(" "); // Last name (if there are multiple words)
//             const newFirebaseUser = {
//                 name: firebaseUser?.displayName,
//                 firstName:fName || '',
//                 lastName:lName || '',
//                 description: '',
//                 email: firebaseUser?.email,
//                 phone: firebaseUser?.phoneNumber,
//                 photo: firebaseUser?.photoURL||'',
//                 createdAt: serverTimestamp(),
//                 isOnboarded: false,
//                 token:null,
//                 userType:'USER',
//                 helpQuestion:  '',
//                 requestedCallback: false,
//             };
//
//             const newUserResponse = await postRequest(`${CLOUD_FUNCTION_URL}/user-signupusers`,{
//                 user:newFirebaseUser,
//             })
//             console.log("SignUpUser",newUserResponse.data);
//             return {
//                 ...newUserResponse.data.user,
//                 id: newUserResponse.data.id,
//                 isNewUser: true,
//                 userType:'USER',
//             };
//         }
//     }catch (e) {
//         return null;
//     }
// }
//
// export const signInWithApple = () => {
//     signInWithPopup(auth,provider).then((result)=>{
//         console.log("signInWithApple",result.user);
//     }).catch((error)=>{
//         console.log("error",error.message);
//     })
// }
//

export const firebaseLogout = async () => {
    try {
        Mixpanel.resetIdentity();
        await auth.signOut()
        return true;
    } catch (e ) {
        // console.log("error",e.message);
        return false;
    }
};

//
// export const signUpUser = async(userSignUpData) => {
//     // console.log("signUpUser",userSignUpData);
//
//     try {
//         const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-signupwithcustomtrialstartdate`,{
//             user:userSignUpData,
//         })
//         // console.log("signUpUser api",response.data)
//         if(response.data?.status === 200 || response.data?.status === 201){
//             return response.data;
//         }
//         throw response;
//
//     } catch (e ) {
//
//     }
// };

export const signUpUser = async(userSignUpData) => {
    console.log("signUpUser",userSignUpData);
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-signupuserwithouttrialdev`,{
            user:userSignUpData,
        })
        console.log("signUpUser api",response.data)
        if(response.data?.status === 200 || response.data?.status === 201){
            return response.data;
        }
        throw response;

    } catch (e ) {

    }
};


export const resendOtp = async(phone) => {
    // console.log("resendOtp",phone)
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-resendotp`,{
            phone,
        })
        // console.log("resendOtp api",response.data)
        if(response.data?.status===200){
            return response.data;
        }
        throw response;

    } catch (e ) {

    }
};

// TODO login user will be changed to otpless login
export const loginUser = async(phone) => {
    // console.log("loginUser ",phone)
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-logindev`,{
            phone
        })
        // console.log("loginUser api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;

    } catch (e ) {

    }
};


// TODO login user will be changed to otpless login
export const verifyOtp = async(otp,phone,masterPhone) => {
    // console.log("verifyOtp ",otp,phone,masterPhone)
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-verifyotpdev`,{
            otp,
            phone,
            masterPhone,
        })
        // console.log("verifyOtp api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;

    } catch (e ) {

    }
};


export const getUserByUserId = async(userId) => {
    // console.log("loginUser ",email,phone)
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-getuserbyuserid`,{
            userId
        })
        // console.log("getUserByUserId api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;

    } catch (e ) {

    }
};




export const getLiveStreamingReferralCode = async(userId) => {
    // console.log("getLiveStreamingReferralCode ",userId)
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-getlivestreamingreferralcode`,{
           userId:userId
        })

        // console.log("getLiveStreamingReferralCode api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;

    } catch (e ) {

    }
};

export const getLiveStreamingPlans = async() => {
    try {
        const response = await postRequest(`https://plans-getlivestreamingplans-3iwkghmfua-uc.a.run.app`)

        // console.log("getLiveStreamingPlans api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;

    } catch (e ) {

    }
};


// export const getCheckLiveStreamingWindowStatus = async() => {
//     try {
//         const response = await postRequest(`${CLOUD_FUNCTION_URL}/plans-checklivestreamingtimewindow`)
//         if(response.status===200){
//             return response.data;
//         }
//         throw response;
//
//     } catch (e ) {
//
//     }
// };


export const enrollUserToLiveStreamingClass = async(userId,selectedPlanVariant) => {
    // console.log("enrollUserToLiveStreamingClass api",userId,selectedPlanVariant);

    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-enrollusertolivestreamingclasswithouttrial`,{
            userId:userId,
            // payment_info:payment_info,
            selectedPlanVariant
        })
        // console.log("enrollUserToLiveStreamingClass api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;

    } catch (e ) {

    }
};

export const enrollUserForPocCounselling = async(params) => {
    // console.log("enrollUserToLiveStreamingClass api",params);

    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-enrollUserForPocCounselling`,params)
        // console.log("enrollUserToLiveStreamingClass api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;

    } catch (e ) {

    }
};


export const getCurrentStreamingPlanByUserid = async(userId,payment_info,selectedPlanVariant) => {
    // console.log("getCurrentStreamingPlanByUserid",userId)
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-getcurrentstreamingplanbyuserid`,{
            userId:userId,
        })
        // console.log("getCurrentStreamingPlanByUserid api",response.data);
        if(response.status===200){
            return response.data;
        }
        throw response;

    } catch (e ) {
        // console.log("getCurrentStreamingPlanByUserid err",e)
    }
};

// export const markNewUsersActivitiesCheckboxes = async(userId,payload) => {
//     // console.log("enrollUserToLiveStreamingClass",userId,payment_info,selectedPlanVariant);
//     //  console.log("enrollUserToLiveStreamingClass",`${CLOUD_FUNCTION_URL}/plans-streamingpaymentstatus`);
//
//     try {
//         const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-updatenewusersactivitiescheckboxes`,{
//             userId:userId,
//             activity_name:
//         })
//         console.log("markNewUsersActivitiesCheckboxes api",response.data)
//         if(response.status===200){
//             return response.data;
//         }
//         throw response;
//
//     } catch (e ) {
//
//     }
// };


// TODO change email to userID
export const checkUserExistOrNot = async(phoneNumber) => {
    // console.log("checkUserExistOrNot ",phoneNumber)
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-checkuserexistornotdev`,{
            // email,
            phone:phoneNumber,
        })

        // console.log("checkuserexistornot api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;

    } catch (e ) {

    }
};

// TODO check user with email , change it to userID
export const checkexistinguserforcourse = async(phoneNumber) => {
    // console.log("checkUserExistOrNot ",phoneNumber)
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-checkexistinguserforcoursedev`,{
            phone:phoneNumber,
        })

        // console.log("checkexistinguserforcourse api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;

    } catch (e ) {

    }
};


export const checkReferralCodeValidation = async(referralCode) => {
    // console.log("checkreferralcodevalidation ",referralCode)
    if(!referralCode) return;
    else if(referralCode && referralCode?.length < 5){
        return;
    }
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-checkreferralcodevalidation`,{
            referralCode:referralCode?.toUpperCase(),
        })

        // console.log("checkreferralcodevalidation api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;

    } catch (e ) {

    }
};

// TODO update user data with userid,change email to phone number
export const updateUser = async(userId,data) => {
    // console.log("updateUser",userId,data);
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-updatedev`,{
            userId,
            data
        })
        // console.log("updateUser api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;

    } catch (e ) {

    }
};


// TODO marking attendace with email,change it to userId
export const markLiveStreamingAttendance = async (userId) => {
    // console.log("markLiveStreamingAttendance",userId);
   const currentDate = getCurrentDate();
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-marklivestreamingattendance`,{
            // email:email,
            userId:userId,
            currentDate:currentDate,

        })
        // console.log("markLiveStreamingAttendance res",response);
        if(response.status === 200){
            return response.data;
        }
        throw response;
    }catch (e) {

    }
}


export const enrollUserToTheCourse = async (userId,courseId,razorpay_id,applied_karma_points,batchId) => {
    // console.log("enrollUserToTheCourse",courseId,userId,applied_karma_points,batchId);
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-enrollusertocoursedev`,{
            batchId:batchId,
            courseId:courseId,
            userId:userId,
            razorpay_id:razorpay_id,
            applied_karma_points:applied_karma_points,
        })
        if(response.status === 200){
            return response.data;
        }
        throw response;
    }catch (e) {

    }
}


// TODO alloting karma with email, change it to userId
export const allotKarmaPoints = async (userId) => {
    // console.log("allotKarmaPoints",userId);
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-allotkarmapointsdev`,{
            userId:userId,
        })


        // console.log("allotKarmaPoints api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;
    }catch (e) {

    }
}

// TODO replace email with userID
export const getKarmaPoints = async (userId,weekStartDate,weekEndDate) => {
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-getkarmapointsdev`,{
            userId:userId,
            weekStartDate:weekStartDate,
            weekEndDate:weekEndDate,
        })

        if(response.status===200){
            return response.data;
        }
        throw response;
    }catch (e) {

    }
}


// TODO email id with userID
export const getLiveStreamingAttendance = async (userId,data) => {
    // console.log("getLiveStreamingAttendance",userId,data);
    if (!userId || !data) {
        return null; // or handle it as needed
    }

    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-getlivestreamingattendancedev`,{
            userId,
            data
        })
        // console.log("getLiveStreamingAttendance api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;
    }catch (e) {

    }
}

export const getLeaderBoardUsers= async (lastDocument,weekStartDate,weekEndDate) => {
    // console.log("getLeaderBoardUsers",weekStartDate,weekEndDate);
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-getweeklyleaderboardusersdev`,{
            lastDocument:null,
            weekStartDate,
            weekEndDate
        })
        // console.log("getLeaderBoardUsers api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;
    }catch (e) {
        console.log("getLeaderBoardUsers api err",e.message)
    }
}

export const getGlobalLeaderBoard= async () => {
    // console.log("getLeaderBoardUsers",weekStartDate,weekEndDate);
    try {
        // const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-user-getgloballeaderboard`)
        const response = await postRequest(`https://user-getgloballeaderboard-3iwkghmfua-uc.a.run.app`)
        // console.log("getLeaderBoardUsers api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;
    }catch (e) {

    }
}

export const getUpcomingClasses= async (userId) => {
    // console.log("getUpcomingClasses",userId);

    try {
        // const response = await postRequest(`${CLOUD_FUNCTION_URL}/plans-getdailyliveclass`)
        // const response = await postRequest(`${CLOUD_FUNCTION_URL}/plans-getliveclassmultipletracksfortheday`,{
        //     userId
        // })
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/plans-getliveclassmultipletrackslanguagebased`,{
            userId
        })
        // console.log("getUpcomingClasses api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;
    }catch (e) {

    }
}

export const getNudges= async (userId) => {
    // console.log("getNudges",userId);
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/course-getnudges`,{
            userId
        })
        // console.log("getNudges api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;
    }catch (e) {

    }
}

export const getFavouriteClasses= async () => {
    // console.log("getLeaderBoardUsers",weekStartDate,weekEndDate);
    try {
        // const response = await postRequest(`${CLOUD_FUNCTION_URL}/plans-getdailyliveclass`)
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/plans-getfavouriteclasses`)
        // console.log("getFavouriteClasses api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;
    }catch (e) {

    }
}

export const getUpcomingCourses= async (userId) => {
    // console.log("getUpcomingCourses api",userId);
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/course-getUpcomingCoursesDev`,{
            userId:userId,
        })
        // console.log("getUpcomingCourses response",response.data);
        if(response.status===200){
            return response.data;
        }
        throw response;
    }catch (e) {

    }
}

export const getUpcomingCourseByCourseId= async (courseId,userId) => {
    // console.log("getUpcomingCourseByCourseId api",courseId,userId);
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/course-getUpcomingCoursesByCourseIdDev`,{
            userId:userId,
            courseId:courseId,
        })
        // console.log("getUpcomingCourseByCourseId response",response.data);
        if(response.status===200){
            return response.data;
        }
        throw response;
    }catch (e) {

    }
}

export const getUpcomingCourseClass = async (userId) => {
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/plans-getUpcomingCourseClass`,{
            userId:userId,
        })
        if(response.status===200){
            return response.data;
        }
        throw response;
    }catch (e) {

    }
}

export const getCourseHomework = async (homeworkId) => {
    // console.log("getCourseHomework",homeworkId);
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/plans-getcoursehomeworkbyid`,{
            homeworkId:homeworkId,
        })
        // console.log("getCourseHomework data",response.data);
        if(response.status===200){
            return response?.data;
        }
        throw response;
    }catch (e) {

    }
}

export const checkUserEnrolledToCourse= async (userId,courseId) => {
    // console.log("checkUserEnrolledToCourse id",userId,courseId);
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/course-checkuserenrolledtocoursedev`,{
            userId:userId,
            courseId:courseId,
        })

        // console.log("checkUserEnrolledToCourse",response)
        if(response.status === 200){
            return response.data;
        }
        throw response;
    }catch (e) {

    }
}

// // This api is checking class window time
// export const getYoutubeLiveClassLink= async (email) => {
//     console.log("getYoutubeLiveClassLink",email);
//     try {
//         if(email && Testers.includes(email)){
//             // console.log("getYoutubeLiveClassLink",email)
//             const response = await postRequest(`${CLOUD_FUNCTION_URL}/plans-getlivestreamingclasslinkversiontwo`)
//             console.log("getYoutubeLiveClassLink tester",response.data)
//             if(response.status===200){
//                 return response.data;
//             }
//             throw response;
//         }else {
//             const response = await postRequest(`${CLOUD_FUNCTION_URL}/plans-getlivestreamingclasslink`)
//             // console.log("getYoutubeLiveClassLink",response.data)
//             if(response.status===200){
//                 return response.data;
//             }
//             throw response;
//         }
//
//
//     }catch (e) {
//
//     }
// }

// This api is checking class window time
export const getYoutubeLiveClassLink= async () => {
    // console.log("getYoutubeLiveClassLink",email);
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/plans-getlivestreamingclasslinkversiontwo`)
        // console.log("getYoutubeLiveClassLink tester",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;

    }catch (e) {

    }
}

// This api is checking class window time
export const getPocCounsellingTimeWindow = async () => {
    // console.log("getYoutubeLiveClassLink",email);
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/plans-getpoccounsellingtimewindow`)
        // console.log("getpoccounsellingtimewindow tester",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;

    }catch (e) {

    }
}


// This api is checking class window time
export const updateTrialStartDate= async (userId,updatedTrailStartDate) => {
    // console.log("updateTrialStartDate",userId);
    try {
        const response = await postRequest(`${CLOUD_FUNCTION_URL}/user-updatetrialstartdatebyuserid`,{
            userId:userId,
            updatedTrailStartDate:updatedTrailStartDate,

        })
        // console.log("updateTrialStartDate api",response.data)
        if(response.status===200){
            return response.data;
        }
        throw response;
    }catch (e) {

    }
}








