import React, { useEffect, useState} from 'react'
import {Card, Col, Container, Row} from "react-bootstrap";
import './PlanDescriptionPage.css'

import {
    Faqs,
     LANDING_COMPONENT,
    peopleStats,
     Reviews,
} from "../../../utils/utils";
import {Mixpanel} from "../../../analytics/Mixpanel";
import FAQ from "./FAQ";
import TestimonialsVideoSlider from "./TestimonialsVideoSlider";
import PlanVariant from "./PlanVariant";
import PausePlanModel from "../../../components-v2/models/PausePlanModel";
import BestOutOfItIconWithName from "../../../components-v2/rows/BestOutOfItIconWithName";
import CustomerReview from "../../../components-v2/rows/CustomerReview";
import PeopleStats from "../../../components-v2/rows/PeopleStats";

interface TriggeredEvents {
    [key: string]: boolean;
}

const sectionRanges = {
    section1: {start: 0, end: 500, section_heading: 'Choose your plan duration'},
    section2: {start: 500, end: 1400, section_heading: 'Real Outcomes for Real People.'},
    section3: {start: 1500, end: 1800, section_heading: 'FAQ and Support'},
} as { [key: string]: { start: number; end: number; section_heading: string } };


interface PlanDescriptionPageProps {
    plans: any,
    isCommunityMember: boolean,
    selectedPlanIndex:number
    onProceedFromPlanDetailsPageHandler: (planVariant: any,selectedPlanVariantIndex:number) => void,
}

const  PlanDescriptionPage: React.FC<PlanDescriptionPageProps> = ({
                                                                     selectedPlanIndex,
                                                                     onProceedFromPlanDetailsPageHandler,
                                                                     plans,
                                                                     isCommunityMember
                                                                 }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [selectedFAQIndex, setSelectedFAQIndex] = useState<number | null>()
    const [showMore, setShowMore] = useState(false);
    const [showText, setShowText] = useState('Show More')
    const [reviews, setReviews] = useState(Reviews);
    const [bestOutOfIt, setBestOutOfIt] = useState(['Live Classes Everyday', 'Daily Wisdom Audio', 'Community Access', 'Course Discounts']);
    const [showPauseBanner, setShowPauseBanner] = useState(false);
    const [selectedPlanVariantIndex, setSelectedPlanVariantIndex] = useState<number>(selectedPlanIndex||0)


    const [triggeredEvents, setTriggeredEvents] = useState<TriggeredEvents>({
        section1: false,
        section2: false,
        section3: false,
    });

    const markMixPanelEvent = (position: number) => {
        const checkAndTriggerEvent = (sectionKey: string, start: number, end: number) => {
            if (!triggeredEvents[sectionKey] && position >= start - 10 && position <= end + 10) {
                Mixpanel.track('view_payment_landing_page_section', {
                    event_action: 'viewed',
                    event_section: sectionKey,
                    section_heading: sectionRanges[sectionKey].section_heading,
                    event_page: 'plan_landing_page',
                });
                setTriggeredEvents((prevEvents: any) => ({...prevEvents, [sectionKey]: true}));
            }
        };

        Object.entries(sectionRanges).forEach(([sectionKey, {start, end}]) => {
            checkAndTriggerEvent(sectionKey, start, end);
        });
    };


    useEffect(() => {
        const handleScroll = () => {
            markMixPanelEvent(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [triggeredEvents]);




    const onClickShowMoreReviews = () => {
        setShowMore(prevState => !prevState)
        Mixpanel.track('click_show_more_reviews', {
            event_action: 'clicked',
            event_page: 'plan_landing_page',
        });
    }

    useEffect(() => {
        if (showMore) {
            setReviews(Reviews);
            setShowText('Show Less')
        } else {
            setShowText('Show More')
            setReviews(Reviews.slice(0, 3)); // Show only the first 3 reviews
        }
    }, [showMore, Reviews]);

    const onFAQClickHandler = (index: number) => {
        try {
            Mixpanel.track('click_view_FAQs_on_plan_landing_page', {
                event_action: 'clicked',
                faq_heading: Faqs[index],
                event_page: 'plan_landing_page',
            });
        } catch (e) {
        }
        setSelectedFAQIndex((prevState) => prevState === index ? null : index);
    }

    const onProceed = () => {
        onProceedFromPlanDetailsPageHandler(plans[selectedPlanVariantIndex],selectedPlanVariantIndex);
    }

    return (
        <Container className={'plan-description-container'}>
            <div className={'plan-description-component-wrapper'} style={{
                width:'100%',
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingLeft: '16px',
                    maxWidth: '400px',
                    width: '100%'
                }}>
                        <span style={{
                            color: '#1B1625',
                            fontFamily: 'Galaxie-Copernicus-Book',
                            fontSize: '20px',
                            width: '100%',
                            alignSelf: 'start',
                            textAlign: 'start',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '28px',
                        }}>Choose your plan duration</span>
                </div>
                {
                    plans?.sort((a: any, b: any) => b.duration - a.duration)
                        .map((plan: any, index: number) => (
                            <PlanVariant
                                count={plans?.length}
                                free_extension={plan?.free_extension}
                                extra_offer={plan?.extra_offer}
                                strike_price={plan?.strike_price}
                                pause_limit={plan?.pause_limit}
                                key={index} // Remember to add a unique key when mapping over arrays in React
                                onClick={(selectedIndex) => setSelectedPlanVariantIndex(selectedIndex)}
                                // onClick={onProceed}
                                setShowPauseBanner={setShowPauseBanner}
                                index={index}
                                name={plan?.display_name || plan?.name}
                                selectedIndex={selectedPlanVariantIndex || 0}
                                type={plan?.type}
                                duration={plan?.duration}
                                monthly_price={isCommunityMember ? plan?.community_monthly_price : plan?.monthly_price}
                                total_price={isCommunityMember ? plan?.community_total_price : plan?.total_price}
                                recommended={plan.recommended}
                            />
                        ))
                }
                {bestOutOfIt?.filter(value => value !== null).map((value, index) => (
                    <BestOutOfItIconWithName key={index} index={index} name={value}/>
                ))}
            </div>


            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                paddingLeft: '16px',
                marginTop: '16px',
                maxWidth: '400px',
                width: '100%'
            }}>
                        <span style={{
                            color: '#1B1625',
                            fontFamily: 'Galaxie-Copernicus-Book',
                            fontSize: '20px',
                            width: '100%',
                            alignSelf: 'start',
                            textAlign: 'start',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '28px',
                        }}>Real outcomes for real people</span>
            </div>

            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <TestimonialsVideoSlider
                    style={{
                        width: '364px',
                        height: '208px',
                        marginTop: '24px',
                    }} naturalSlideHeight={256} naturalSlideWidth={240}
                    component_name={LANDING_COMPONENT.TESTIMONIAL}/>
            </div>

            <svg style={{marginTop: '18px'}} xmlns="http://www.w3.org/2000/svg" width="345" height="1"
                 viewBox="0 0 345 1" fill="none">
                <path d="M1 0.500183H344" stroke="#E4E4E4" stroke-linecap="round"/>
            </svg>
            {
                reviews?.map((review: any, index: number) => <CustomerReview count={showMore ? 6 : 3} index={index}
                                                                      imageSrc={review.imageSrc}
                                                                      name={review.name}
                                                                      period={review.period}
                                                                      bio={review.bio}
                                                                      rating={review.rating}/>)
            }
            <div className={'horizontal-container'}
                 style={{width: '343px', alignItems: 'center', justifyContent: 'start',marginTop:'12px'}}
                 onClick={onClickShowMoreReviews}>
                <span style={{
                    color: "var(--purple-gradient, #000)",
                    fontFamily: "Gordita-Medium",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    alignSelf: 'center',
                    textDecorationLine: 'underline',
                    lineHeight: "16px" // or "150%"
                }}>{showText}</span>
                {showMore ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path d="M12.5 10.7502L8 6.25024L3.5 10.7502" stroke="#1B1625" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path d="M12.5 6.25018L8 10.7502L3.5 6.25018" stroke="#1B1625" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                }
            </div>

            <svg style={{marginTop:'18px'}} xmlns="http://www.w3.org/2000/svg" width="345" height="1" viewBox="0 0 345 1" fill="none">
                <path d="M1 0.500183H344" stroke="#E4E4E4" stroke-linecap="round"/>
            </svg>

            {
                peopleStats?.map((stat: any, index: number) => <PeopleStats name={stat?.title}
                                                                            percentage={stat.percentage}
                                                                            index={index}/>)
            }

            <p className={'plan-description-faq-title'}
               style={{alignSelf: "center", width: '343px', margin: '40px auto 0px auto'}}>FAQs</p>
            {
                Faqs?.map((faq, index) =>
                    <FAQ selectedIndex={selectedFAQIndex} index={index} item={faq} onClickHandler={onFAQClickHandler}/>)
            }


            <div style={{marginBottom: '132px'}}></div>
            <div className="plan-fixed-bottom-container">
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span
                        className="plan-fixed-bottom-price">{'₹' + plans?.[selectedPlanVariantIndex]?.total_price}</span>
                    <span className="plan-fixed-bottom-duration">{plans?.[selectedPlanVariantIndex]?.display_name}</span>
                </div>
                <button className="plan-fixed-bottom-button" onClick={onProceed}>
                    Continue
                </button>
            </div>
            <PausePlanModel setShowPauseBanner={setShowPauseBanner} showPauseBanner={showPauseBanner}/>
        </Container>
    )
}

export default PlanDescriptionPage
