import {useState} from 'react';
import {useDispatch} from 'react-redux';
import dayjs from 'dayjs';
import {Mixpanel} from "../analytics/Mixpanel";
import {getCurrentStreamingPlanByUserid, loginUser, verifyOtp} from "../services/Firebase";
import {addUsers} from "../store/features/AuthSlice";
import {
    updateDaysToExpire,
    updateDaysToExpireTrail,
    updateTotalClassesAttendedTillDate, updateUsersPlan
} from "../store/features/PlanSlice";

interface LoginComponentProps {
    page:string,
    onLoginSuccess: (loginResult:any)=>void;
    onLoginFailure: (loginResult:any)=>void;
    setLoader: (loader: boolean) => void;
    setError: (error: string) => void;
    setShowOtpPopup:(showOtpPopup:boolean) =>void;
    setShowLoginPopup:(showLoginPopup:boolean) =>void;
}


interface useLoginReturnType {
    isLoginSuccessful:boolean,
    isLoginFailure:boolean,
    setIsLoginSuccessful: (show: boolean) => void;
    setIsLoginFailure: (failure: boolean) => void;
    onLoginSubmitHandler: (phone: string) => Promise<void>;
    onOtpVerifyHandler: (phone: string,otp:string) => Promise<void>;

}

const useLoginHook = ({page,setLoader, setError,setShowOtpPopup,setShowLoginPopup,onLoginSuccess,onLoginFailure}: LoginComponentProps): useLoginReturnType => {
    const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
    const [isLoginFailure, setIsLoginFailure] = useState(false);
    const dispatch = useDispatch();

    const onLoginSubmitHandler = async (phone: string) => {
        if (phone === "" || phone?.length < 10) {
            setError('Please enter a valid mobile number')
            setTimeout(()=>setError(''),2000)
            return;
        }
        try {
            const userLoginResult = await loginUser(phone);
            if (userLoginResult?.status === 200 && userLoginResult?.message === 'User login success.Open Otp page') {
                setShowOtpPopup(true);
                Mixpanel.track('view_otp_screen', {
                    event_action: 'viewd',
                    event_page: 'otp_screen',
                    page:page,
                });
                return;
            }else if (userLoginResult?.status === 404) {
                setLoader(false);
                setIsLoginFailure(true);
                onLoginFailure(userLoginResult);
            }
        } catch (e) {
            setIsLoginFailure(true);
            onLoginFailure(null);
        }
    };

    const onOtpVerifyHandler = async (phone:string,otp: string) => {
        try {
            const userLoginResult = await verifyOtp(otp,phone,null);
            if (userLoginResult?.status === 200) {
                setIsLoginSuccessful(true)
                setShowLoginPopup(false);
                setShowOtpPopup(false);
                Mixpanel.identify(userLoginResult.userData?.uid)
                const user = {
                    phone: userLoginResult?.userData?.phone,
                    name: userLoginResult?.userData?.name,
                    phoneNumber: userLoginResult?.userData?.phoneNumber,
                    uid: userLoginResult.userData?.uid
                }
                //TODO set user here in redux and access this user in home page
                dispatch(addUsers(userLoginResult.userData))
                getCurrentStreamingPlanByUserid(userLoginResult.userData.uid).then((activePlanRes: any) => {
                    if (activePlanRes.activePlan != null) {
                        const formattedEndDate = dayjs(new Date(activePlanRes.activePlan?.userLiveStreamingPlans[0]?.endDate?._seconds * 1000))?.format('MMM DD YYYY');
                        const todayDate = dayjs().format('MMM DD YYYY');
                        const date1 = dayjs(formattedEndDate);
                        const date2 = dayjs(todayDate);
                        const daysDifference = date1.diff(date2, 'day');
                        dispatch(updateDaysToExpire(daysDifference));
                        dispatch(updateDaysToExpireTrail(activePlanRes.activePlan.daysToExpireTrail));
                        dispatch(updateTotalClassesAttendedTillDate(activePlanRes.activePlan.totalClassesAttendedTillDate));
                        dispatch(updateUsersPlan(activePlanRes.activePlan?.userLiveStreamingPlans[0]));

                    }
                });
                window.localStorage.setItem('user', JSON.stringify(user))
                onLoginSuccess(userLoginResult);
            } else {
                // setLoader(false);
                setError('Please enter the correct OTP')
                setTimeout(()=>setError(''),2000)
                onLoginFailure(userLoginResult);
            }
        } catch (e) {
        }
    }


    return {
        isLoginSuccessful,
        isLoginFailure,
        setIsLoginFailure,
        setIsLoginSuccessful,
        onLoginSubmitHandler,
        onOtpVerifyHandler,
    };
};

export default useLoginHook;
