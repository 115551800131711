import React, {useEffect, useState} from 'react'
import {Container, Spinner} from "react-bootstrap";
import './CourseCheckoutPage.css'
import '../../../styles.css'
import moment from "moment-timezone";
import {Mixpanel} from "../../../analytics/Mixpanel";
import karma_slider_badge from '../../../assets/slider_badge.svg';
import {ReactComponent as RiskFree} from "../../../assets/new-icons/course-risk-free.svg";
import NumberPickerSlider from "./NumberPickerSlider";
import {ReactComponent as Coins} from "../../../assets/new-icons/coins.svg";
import {KARMA_BASED_USER_TYPE} from "../../../utils/utils";
import {checkUserEnrolledToCourse} from "../../../services/Firebase";
import {toast, ToastContainer} from "react-toastify";
import {COLORS} from "../../../utils/colors";
import BackButton from "../../../components-v2/buttons/BackButton";
import {LazyLoadImage} from "react-lazy-load-image-component";
import useAuthenticatedUsers from "../../../hooks/useAuthenticatedUsers";


interface CheckoutPageProps {
    payNowClickHandler: any,
    onBackArrowClick: () => void,
    courseItem: any,
    totalKarma: any,
    userId: any;
    imageUrl: string,

}

const CourseCheckoutPage: React.FC<CheckoutPageProps> = ({
                                                             payNowClickHandler,
                                                             onBackArrowClick,
                                                             courseItem,
                                                             totalKarma,
                                                             userId,
                                                             imageUrl
                                                         }) => {
    // const formattedDate = moment(new Date(courseItem?.streaming_date._seconds * 1000 + courseItem?.streaming_date._nanoseconds / 1000000)).format("DD MMM YY");
    const userData = useAuthenticatedUsers(true);
    const formattedDate = moment(new Date(courseItem?.batch[0]?.start_date._seconds * 1000 + courseItem?.batch[0]?.start_date._nanoseconds / 1000000)).format('DD MMM YYYY');
    const endDate = moment(new Date(courseItem?.batch[0]?.end_date._seconds * 1000 + courseItem?.batch[0]?.end_date._nanoseconds / 1000000)).format('DD MMM YYYY');


    const [discountedAmountToPay, setDiscountedAmountToPay] = useState(courseItem?.price)
    const [appliedKarma, setAppliedKarma] = useState(0)
    const [loader, setLoader] = useState(false);

    // console.log("total_karma", totalKarma, typeof courseItem?.price);


    let amount_to_pay = courseItem?.price;
    const applicable_karma = Math.min(totalKarma, amount_to_pay * 0.1);
    let userType = '';
    // console.log("applicable_karma", applicable_karma);

    if (totalKarma < 40) {
        userType = KARMA_BASED_USER_TYPE.user_with_kp_less_than_min_required;
    } else if (totalKarma > applicable_karma) {
        userType = KARMA_BASED_USER_TYPE.user_with_kp_greater_than_discount_percent;
    } else {
        userType = KARMA_BASED_USER_TYPE.user_with_kp_less_than_discount_percent;
    }

    if (amount_to_pay !== undefined && amount_to_pay !== null) {
        amount_to_pay = amount_to_pay.replace(/,/g, '');
        amount_to_pay = parseInt(amount_to_pay);
    }

    const [isToggled, setIsToggled] = useState<boolean>(false);

    const onPayNowClick = async () => {
        setLoader(true);
        try {
            Mixpanel.track('click_pay_now_course_payment_page', {
                event_action: 'clicked',
                event_page: 'course_payment_page',
                price: amount_to_pay,
                total_amount_to_pay: discountedAmountToPay,
                selected_karma_points: appliedKarma,
                karma_points_discount: appliedKarma,
                user_type: userType,
                course_name: courseItem?.title,
                phone: userData?.phoneNumber || userData?.phone,
            });
            const result = await checkUserEnrolledToCourse(userId, courseItem?.courseId);
            if (result?.status === 200) {
                toast.error(`You are already enrolled in this course`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                });
                setLoader(false);
                return;
            }
        } catch (e) {
        }
        setTimeout(()=>{
            setLoader(false);
        },3000)
        if (isToggled) {
            payNowClickHandler(discountedAmountToPay, appliedKarma)
        } else {
            payNowClickHandler(amount_to_pay, appliedKarma)
        }
    }

    const handleToggle = () => {
        try {
            Mixpanel.track('click_redeem_karma_points', {
                event_action: 'clicked',
                event_page: 'course_payment_page',
                price: amount_to_pay,
                total_amount_to_pay: discountedAmountToPay,
                selected_karma_points: appliedKarma,
                karma_points_discount: appliedKarma,
                user_type: userType,
                discount: '10%',
            });
        } catch (e) {
            console.log("mixpanel karma points");
        }
        setIsToggled(prevState => !prevState)
    }

    const onSliderChangeHandler = (karmaPoints: any) => {
        setIsToggled(false)
        if (parseInt(karmaPoints) > 0) {
            const discountedPrice = amount_to_pay - parseInt(karmaPoints);
            setDiscountedAmountToPay(discountedPrice);
            setAppliedKarma(parseInt(karmaPoints));
        }
    }


    useEffect(() => {
        if (courseItem) {
            Mixpanel.track('view_course_payment_details_page', {
                event_action: 'viewed',
                course_name: courseItem?.title,
                starting_date: formattedDate,
                ending_date: endDate,
                event_page: 'course_payment_page',
                max_karma_points: totalKarma,
                total_amount_to_pay: discountedAmountToPay,
            });
        }
    }, [courseItem])


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <Container className={'course-checkout-container'}>
            <div style={{width: '100%', maxWidth: '400px'}}>
                <BackButton onClick={onBackArrowClick}/>
            </div>

            {loader && <Spinner style={{
                marginTop: '64px',
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: COLORS.PRIMARY
            }}/>}

            <span className={'course-checkout-details'}>Course Details</span>
            <div className={'checkout-plan-billing-container'}>
                <div className={'checkout-plan-billing-row-container'}>
                    {<LazyLoadImage src={imageUrl} alt="User"
                                    style={{width: '85px', height: '64px', borderRadius: '8px'}}/>}
                    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '16px'}}>
                        <span className={'course-checkout-plan-name'}>{`${courseItem?.title}`}</span>
                        <span style={{
                            color: '#A4A4A4',
                            fontFamily: 'Gordita-Regular',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            alignSelf: 'start',
                            marginTop: '6px',
                            lineHeight: 'normal'
                        }}>{`${formattedDate}`}</span>
                    </div>
                </div>
            </div>

            {/*<span className={'course-checkout-details'} style={{marginTop: '32px'}}>Batch Details</span>*/}
            {/*<div style={{background: '#EEEDFF', borderRadius: '12px', marginTop: '12px'}}>*/}
            {/*    <div className={'course-checkout-plan-details-container'} style={{marginTop: '0px'}}>*/}
            {/*        <span className={'course-checkout-plan-start-date'}>{'Starting Date'}</span>*/}
            {/*        <span className={'course-checkout-plan-start-date-value'}>{`${formattedDate}`}</span>*/}
            {/*    </div>*/}
            {/*    <div className={'course-checkout-plan-details-container'} style={{marginTop: '-12px'}}>*/}
            {/*        <span className={'course-checkout-plan-start-date'}>{'Ending Date'}</span>*/}
            {/*        <span className={'course-checkout-plan-start-date-value'}>{`${endDate.format('DD MMM YY')}`}</span>*/}
            {/*    </div>*/}
            {/*</div>*/}


            <span className={'course-checkout-details'}>Payment Details</span>
            {totalKarma >= 40 ? <div className={'apply-karma-points-container'}>
                <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'center', alignItems: 'center'}}>
                    <Coins width={24} height={24}/>
                    <span style={{
                        color: '#3A3A3A',
                        fontFamily: 'Gordita-Medium',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        marginLeft: '5px',
                        lineHeight: '20px',
                    }}>Pay using Karma Points</span>
                </div>
                <p style={{
                    color: '#1A1A1A',
                    fontFamily: 'Gordita-Regular',
                    fontSize: '10px',
                    width: '310px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    marginLeft: '5px',
                    lineHeight: '14px',
                    marginTop: '2px'
                }}>{totalKarma > applicable_karma ? 'Maximum Karma Point redemption is 10% of the total amount.(1 Karma Point = 1 Rs)' : 'Redeem your Karma Points for a discount equivalent to your total points. (1 Karma Point = 1 Rs)'}</p>
                <NumberPickerSlider min={0} max={applicable_karma} backgroundImage={karma_slider_badge}
                                    onSliderChangeHandler={onSliderChangeHandler}/>

                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignSelf: 'center',
                    marginTop: '4px',
                }}>
                    <p style={{
                        color: '#2A2A2A',
                        fontFamily: 'Gordita-Medium',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: 'normal',
                    }}>0 <span style={{
                        color: '#2A2A2A',
                        fontFamily: 'Gordita-Regular',
                        fontSize: '10px',
                        fontStyle: 'normal',
                        textAlign: 'center',
                        fontWeight: 400,
                        alignSelf: 'center',
                        lineHeight: 'normal',
                    }}>points</span></p>
                    <p style={{
                        color: '#2A2A2A',
                        fontFamily: 'Gordita-Medium',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: 'normal',
                    }}>{applicable_karma} <span style={{
                        color: '#2A2A2A',
                        fontFamily: 'Gordita-Regular',
                        fontSize: '10px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        alignSelf: 'center',
                        lineHeight: 'normal',
                    }}>points</span></p>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignSelf: 'center',
                }}>
                    <span style={{
                        color: '#2A2A2A',
                        fontFamily: 'Gordita-Medium',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '16px',
                    }}>Redeem Points </span>
                    <label className="switch">
                        <input type="checkbox" checked={isToggled} onChange={handleToggle}/>
                        <span className="slider round"></span>
                    </label>
                </div>


            </div> : <div className={'apply-karma-points-container'}>
                <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'center', alignItems: 'center'}}>
                    <Coins width={24} height={24}/>
                    <span style={{
                        color: '#3A3A3A',
                        fontFamily: 'Gordita-Medium',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        marginLeft: '5px',
                        lineHeight: '20px',
                    }}>Pay using Karma Points</span>
                </div>
                <p style={{
                    color: '#1A1A1A',
                    fontFamily: 'Gordita-Regular',
                    fontSize: '10px',
                    width: '310px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    marginLeft: '5px',
                    lineHeight: '14px',
                    marginTop: '2px',
                }}>Looks like you do not have enough karma points to redeem for additional discount.</p>
                {/*{totalKarma && <NumberPickerSlider disable={true} opacity={0.5} min={0} max={totalKarma || 0}*/}
                {/*                     backgroundImage={Slider_badge_grey}*/}
                {/*                     onSliderChangeHandler={onSliderChangeHandler}/>}*/}
                {/*{totalKarma && <div style={{*/}
                {/*    display: 'flex',*/}
                {/*    justifyContent: 'space-between',*/}
                {/*    alignItems: 'center',*/}
                {/*    alignSelf: 'center',*/}
                {/*    marginTop: '4px',*/}
                {/*    opacity: 0.5,*/}
                {/*}}>*/}
                {/*    <p style={{*/}
                {/*        color: '#2A2A2A',*/}
                {/*        fontFamily: 'Gordita-Medium',*/}
                {/*        fontSize: '12px',*/}
                {/*        fontStyle: 'normal',*/}
                {/*        fontWeight: 500,*/}
                {/*        lineHeight: 'normal',*/}
                {/*    }}>0 <span style={{*/}
                {/*        color: '#2A2A2A',*/}
                {/*        fontFamily: 'Gordita-Regular',*/}
                {/*        fontSize: '10px',*/}
                {/*        fontStyle: 'normal',*/}
                {/*        textAlign: 'center',*/}
                {/*        fontWeight: 400,*/}
                {/*        alignSelf: 'center',*/}
                {/*        lineHeight: 'normal',*/}
                {/*    }}>points</span></p>*/}
                {/*    <p style={{*/}
                {/*        color: '#2A2A2A',*/}
                {/*        fontFamily: 'Gordita-Medium',*/}
                {/*        fontSize: '12px',*/}
                {/*        fontStyle: 'normal',*/}
                {/*        fontWeight: 500,*/}
                {/*        lineHeight: 'normal',*/}
                {/*    }}>{applicable_karma} <span style={{*/}
                {/*        color: '#2A2A2A',*/}
                {/*        fontFamily: 'Gordita-Regular',*/}
                {/*        fontSize: '10px',*/}
                {/*        fontStyle: 'normal',*/}
                {/*        fontWeight: 400,*/}
                {/*        alignSelf: 'center',*/}
                {/*        lineHeight: 'normal',*/}
                {/*    }}>points</span></p>*/}
                {/*</div>}*/}
            </div>}


            <svg style={{marginTop: totalKarma>=40?'24px':'10px'}} xmlns="http://www.w3.org/2000/svg" width="345" height="2"
                 viewBox="0 0 345 2" fill="none">
                <path d="M1 1H344" stroke="#E4E4E4" stroke-width="0.64" stroke-linecap="round"
                      stroke-dasharray="4 4"/>
            </svg>

            <div className={'course-checkout-plan-price-container'}>
                <div className={'course-checkout-plan-details-container'} style={{marginTop: '0px'}}>
                    <span className={'course-checkout-plan-start-date'}>{'Course Price'}</span>
                    <span className={'course-checkout-plan-start-date-value'}>{`INR ${courseItem?.price}`}</span>
                </div>

                {isToggled && <div className={'course-checkout-plan-details-container'}>
                    <span className={'course-checkout-plan-start-date'}>{'Karma Points Discount'}</span>
                    <span className={'course-checkout-plan-price-discount'}>{`- INR ${appliedKarma}`}</span>
                </div>}
            </div>


            <RiskFree style={{marginTop: '24px', marginBottom: '92px'}}/>
            <div className="plan-fixed-bottom-container">
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span
                        className="plan-fixed-bottom-price">{`₹ ${isToggled ? discountedAmountToPay : amount_to_pay}`}</span>
                </div>
                <button className="plan-fixed-bottom-button" onClick={onPayNowClick}>
                    {'Pay Now'}
                </button>
            </div>
            <ToastContainer/>
        </Container>
    )
}

export default CourseCheckoutPage
