import React, {useState, CSSProperties, useEffect, useCallback} from 'react';
import {CarouselProvider, Slider, Slide} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {Mixpanel} from "../../../analytics/Mixpanel";
import {customerTestimonials, LANDING_COMPONENT} from "../../../utils/utils";
import TestimonialCard from "./TestimonialCard";

interface CustomCarouselProps {
    component_name: string;
    naturalSlideWidth: number;
    naturalSlideHeight: number;
    style?: CSSProperties;
    width?: any;
    margin?: any;
    pageName?: string,
}

const CustomCarousel: React.FC<CustomCarouselProps> = ({
                                                           component_name,
                                                           naturalSlideWidth,
                                                           naturalSlideHeight,
                                                           style,
                                                           width,
                                                           margin,
                                                           pageName,
                                                       }) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [playingVideo, setPlayingVideo] = useState(false);


    useEffect(() => {
        const handleScroll = () => {
            if(pageName === 'course_overview'){
                if (window.scrollY >= 2600 && window.scrollY < 3200) {
                    setPlayingVideo(true)
                } else {
                    setPlayingVideo(false)
                }
            } else if(pageName === 'course_schedule'){
                if (window.scrollY >= 800 && window.scrollY < 1600) {
                    setPlayingVideo(true)
                } else {
                    setPlayingVideo(false)
                }
            }else if(pageName === 'course_instructor'){
                if (window.scrollY >=1400 && window.scrollY < 2200) {
                    setPlayingVideo(true)
                } else {
                    setPlayingVideo(false)
                }
            } else if (window.scrollY >= window.innerHeight / 5 && window.scrollY < window.innerHeight - 100) {
                setPlayingVideo(true)
            } else {
                setPlayingVideo(false)
            }

        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [pageName]);


    const onVideoPlayPause = (index: number, title: string) => {
        if (index === selectedIndex) {
            Mixpanel.track('click_plan_landing_customer_testimonial', {
                event_action: 'clicked',
                event_page: 'plan_landing_page',
                action: 'pause',
                title: title,
            });
            setSelectedIndex(-1);
        } else {
            setSelectedIndex(index);
            Mixpanel.track('click_plan_landing_customer_testimonial', {
                event_action: 'clicked',
                event_page: 'plan_landing_page',
                action: 'play',
                title: title,
            });
        }

    };

    return (
        <CarouselProvider
            naturalSlideWidth={naturalSlideWidth}
            naturalSlideHeight={naturalSlideHeight}
            totalSlides={customerTestimonials?.length}
            visibleSlides={2.1} // Set the number of visible slides
        >
            <Slider style={style}>
                {customerTestimonials?.map((item, index) => (
                    <Slide
                        style={{
                            border: 'none',
                            borderRadius: component_name === LANDING_COMPONENT.TESTIMONIAL ? '16px' : '0',
                        }}
                        key={index}
                        index={index}
                    >
                        {component_name === LANDING_COMPONENT.TESTIMONIAL && (
                            <TestimonialCard
                                playingVideo={playingVideo}
                                index={index}
                                selectedIndex={selectedIndex}
                                onVideoPlayPause={onVideoPlayPause}
                                name={item.name}
                                location={item.location}
                                videoUrl={item.videoUrl}
                            />
                        )}
                    </Slide>
                ))}
            </Slider>
        </CarouselProvider>
    );
};

export default CustomCarousel;
