import React, {useEffect, useState} from 'react'
import './AccountCreated.css'

import {ReactComponent as Watch} from "../../../assets/time_account.svg";
import {ReactComponent as Calendar} from "../../../assets/calendar_account.svg";
import {ReactComponent as Tap} from "../../../assets/touch_account.svg";
import {ReactComponent as WhatsApp} from "../../../assets/whatsapp_account.svg";
import Button from "../../../components/button/Button";
import {Mixpanel} from "../../../analytics/Mixpanel";
import {events, events_action, events_page} from "../../../analytics/Events";
import {useLogin} from "../../../context/LoginContext";
import {COLORS} from "../../../utils/colors";
import {useNavigate} from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import {ToastContainer} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {updateEventsStatus} from "../../../store/features/EventsSlices";




interface AccountCreatedProps {
    onProceed: (isUserPartOfEnrolledCourse: boolean) => void
}

export enum STATES {
    ACCOUNT_CREATED,
}

const AccountCreated: React.FC<AccountCreatedProps> = ({onProceed}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [state, setState] = useState<STATES>(STATES.ACCOUNT_CREATED);
    const [userPartOfEnrolledCourse, setUserPartOfEnrolledCourse] = useState<boolean>(true)
    const {user} = useLogin()
    // console.log("user",user);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const dispatch = useDispatch();
    const view_account_created_screen = useSelector((state: any) => state?.events?.eventsLog?.view_account_created_screen);
    useEffect(() => {
        if (!view_account_created_screen) {
            Mixpanel.track(events.view_account_created_screen, {
                event_page: events_page.account_created,
                event_action: events_action.viewed,
            })
            dispatch(updateEventsStatus({key:'view_account_created_screen',value:true}))
        }
    }, [view_account_created_screen])


    const proceedHandler = () => {
        try {
            Mixpanel.track(events.click_choose_your_plan_from_account_created, {
                event_page: events_page.account_created,
                event_action: events_action.clicked,
                response: userPartOfEnrolledCourse,
            })
        } catch (e) {
        }

        // navigate('/home');
        navigate('/plan',{state:{screenName:'AccountCreated',user:user},replace:false});
    }

    return (
        <div style={{display:'flex',alignSelf:'center',alignItems:'center',justifyContent:'center'}}>
            {loader && <Loader/>}
            {state === STATES.ACCOUNT_CREATED && <div className={'account-created-container'}>
                {/*<img src={account_created_background_url} style={{alignSelf: 'center', width: 280, height: 280}}*/}
                {/*     alt="Landing Banner"/>*/}
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '343px',
                    alignSelf:'center',
                    padding:'16px',
                }}>
                    <span className={'account-title'}>Account Created ✅</span>
                </div>

                <div className={'account-created-body-container'}>
                    <span className={'account-subtitle'}>How to Join Live class?</span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'start',
                        marginTop: '16px',
                        marginBottom:'10px'

                    }}>

                        <WhatsApp  width={32} height={32}/>
                        <span className={'account-description'}>Come to www.seekdaily.live through whatsapp reminders or directly</span>
                    </div>
                    <div style={{
                        width:'100%',
                        height:'1px',
                        background:'#FFD39E',
                    }}/>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'start',
                        marginTop: '12px',
                        marginBottom:'10px'
                    }}>

                        <Calendar width={32} height={32}/>
                        <span className={'account-description'}>See available time slots for morning and evening at the top of the screen</span>
                    </div>
                    <div style={{
                        width:'100%',
                        height:'1px',
                        background:'#FFD39E',
                    }}/>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'start',
                        marginTop: '12px',
                        marginBottom:'10px'
                    }}>

                        <Watch width={32} height={32}/>
                        <span className={'account-description'}>Select which class you want to attend</span>
                    </div>
                    <div style={{
                        width:'100%',
                        height:'1px',
                        background:'#FFD39E',
                    }}/>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'start',
                        marginTop: '12px',
                    }}>

                        <Tap width={32} height={32}/>
                        <span className={'account-description'}>Click on 'Join Live Session'</span>
                    </div>

                </div>


                <Button isSticky={true} style={{
                    marginTop: '32px',
                    marginBottom: '32px',
                    alignSelf: 'center',
                    backgroundColor: COLORS.PRIMARY
                }} onClick={proceedHandler} label={'Choose your plan'}/>
            </div>}
            {/*{state === STATES.HOW_TO_JOIN_LIVE_CLASS && <HowToJoinLiveClass parent={'AccountCreated'} continueHandler={continueHandler}/>}*/}
            <ToastContainer/>
        </div>
    )
}

export default AccountCreated
