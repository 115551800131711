import React from 'react';
import './App.css';
import './Calendar.css'
import "./fonts/Lora-Medium.ttf";
import "./fonts/Lora-Regular.ttf";
import "./fonts/Lora-Italic.ttf";
import "./fonts/Lora-SemiBold.ttf";
import "./fonts/Poppins-Regular.ttf";
import "./fonts/Poppins-Medium.ttf";
import "./fonts/Poppins-SemiBold.ttf";
import "./fonts/Poppins-Bold.ttf";
import "./fonts/Poppins-Light.ttf";

import "./fonts/Gordita-Black.otf";
import "./fonts/Gordita-Bold.otf";
import "./fonts/Gordita-Light.otf";
import "./fonts/Gordita-Medium.otf";
import "./fonts/Gordita-Regular.otf";
import "./fonts/Galaxie-Copernicus-Bold.otf";
import "./fonts/Galaxie-Copernicus-Book.ttf";
import "bootstrap/dist/css/bootstrap.css";



import {LoginProvider} from "./context/LoginContext";
import Landing from "./screens/Landing";




function App() {
  return (
    <div className="App">
           <LoginProvider>
              <Landing/>
           </LoginProvider>
    </div>
  );
}

export default App;
