import {useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import moment from "moment-timezone";
const useAuthenticatedUsers = (isPublic?:boolean) => {


    const userString = useMemo(() => window.localStorage.getItem('user'), []);
    const userData = useMemo(() => (userString ? JSON.parse(userString) : null), [userString]);
    const user = useSelector((state: any) => state?.auth?.user?.data);
    const navigate = useNavigate();
    let isProfileCompleted = false;
    try {
        if(moment(new Date(user?.createdAt?._seconds * 1000)).isAfter(moment('Jul 19 2024')) && !user?.isProfileCompleted){
            isProfileCompleted = false;
        }else{
            isProfileCompleted = true;
        }
    }catch (e) {
        // console.log("signupdate logi errn",e);
    }

    // console.log("useAuthenticatedUsers isPublic",isPublic);
    // console.log("useAuthenticatedUsers user",user);
    // console.log("useAuthenticatedUsers userData",userData);
    // console.log("useAuthenticatedUsers isProfileCompleted",isProfileCompleted);

    useEffect(()=>{
        if(!userData && !isPublic && isProfileCompleted){
            console.log("if")
            navigate('/')
        }else if(userData && !isPublic && !isProfileCompleted){
            console.log("else")
            navigate('/complete-your-profile');
        }
    },[userData,isProfileCompleted])

    return userData;

}

export default useAuthenticatedUsers;
