import React from 'react'
import './BackButton.css'
import {ReactComponent as BackArrow} from "../../assets/left_arrow_black.svg";


function BackButton({onClick}:any) {
    return (
        <div onClick={onClick} className={'back-button-container'}>
            <BackArrow width={32} height={32}/>
            <span className={'back-button-title'}>{'Back'}</span>
        </div>
    )
}

export default BackButton
