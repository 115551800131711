import React, {useEffect, useState} from 'react'
import './ManageSignupReminders.css'
import {ReactComponent as BackArrow} from "../../assets/left_arrow_black.svg";
import {useNavigate} from "react-router-dom";
import {managedAvailableClassSlots, ProgressSteps} from "../../utils/utils";
import Button from "../../components/button/Button";
import {useDispatch, useSelector} from "react-redux";
import ToggleComponent from "../live-class/components/ToggleComponent";
import ProgressBar from "./components/ProgressBar";
import {toast} from "react-toastify";
import {Container} from "react-bootstrap";

interface ManageSignupRemindersPropType {
    continueFromSlotHandler: (reminderToUpdate: any) => void,
    onBackArrowClick: () => void;
    progress: any,
}

const ManageSignupReminders: React.FC<ManageSignupRemindersPropType> = ({
                                                                            progress,
                                                                            continueFromSlotHandler,
                                                                            onBackArrowClick
                                                                        }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const userPreferences = useSelector((state:any) => state.auth.userPreferences);
    const indexArray = Object.values(userPreferences?.managedReminders).filter(value => value !== undefined);
    const [selectedSlotCount, setSelectedSlotCount] = useState<number>(indexArray?.length||2)
    const [userManagedReminders, setUserManagedReminders] = useState<any>(indexArray||['5 AM', '7 PM'])


    const onToggleSlotHandler = (slotValue: string, isChosen: boolean) => {
        const updatedReminders = [...userManagedReminders];
        const index = updatedReminders.indexOf(slotValue);

        if (index !== -1 && !isChosen) {
            updatedReminders.splice(index, 1);
            setSelectedSlotCount(updatedReminders?.length)
        } else if (index === -1 && isChosen && updatedReminders.length < 4) {
            updatedReminders.push(slotValue);
            setSelectedSlotCount(updatedReminders?.length)
        }
        setUserManagedReminders(updatedReminders);
    };

    const onSlotUpdateHandler = () => {
        userManagedReminders.sort((a: any, b: any) => {
            // Extract hours and AM/PM from strings
            const [hoursA, periodA] = a?.split(' ');
            const [hoursB, periodB] = b?.split(' ');

            // Convert hours to numbers
            const hoursNumA = parseInt(hoursA);
            const hoursNumB = parseInt(hoursB);

            // Convert AM/PM to 24-hour format
            const hours24FormatA = periodA === 'AM' ? hoursNumA : hoursNumA + 12;
            const hours24FormatB = periodB === 'AM' ? hoursNumB : hoursNumB + 12;

            // Compare hours in 24-hour format
            if (hours24FormatA < hours24FormatB) {
                return -1;
            } else if (hours24FormatA > hours24FormatB) {
                return 1;
            } else {
                // If hours are equal, compare based on AM/PM
                if (periodA === 'AM' && periodB === 'PM') {
                    return -1;
                } else if (periodA === 'PM' && periodB === 'AM') {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
        const reminderToUpdate = {
            slot_one: userManagedReminders?.[0],
            slot_two: userManagedReminders?.[1],
            slot_three: userManagedReminders?.[2],
            slot_four: userManagedReminders?.[3],
        }
        continueFromSlotHandler(reminderToUpdate);
    }


    return (
        <Container className={'manage-signup-reminder-container'}>
            <div className={'manage-signup-reminder-header-container'}>
                <BackArrow onClick={onBackArrowClick} width={48} height={48}/>
                <span className={'manage-signup-reminder-header-title'}>3. Setting up your plan</span>
                <div/>
            </div>

            <div className={'manage-signup-reminder-body-container'}>
                <ProgressBar threshold={ProgressSteps.SECTION_THREE} progress={progress}></ProgressBar>
                <p className={'manage-signup-reminder-title'}>Set your reminders</p>
                <p style={{
                    color: '#4A4A4A',
                    textAlign: 'center',
                    fontFamily: 'Gordita-Regular',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    alignSelf:'center',
                    fontWeight: 400,
                    lineHeight: '24px',
                    marginTop: '-8px',
                }}>Reminders will be sent on WhatsApp </p>
                {/*<p className={'manage-signup-reminder-note'} style={{marginTop: '-12px',marginBottom:'12px'}}> Note: Reminders will be sent on WhatsApp</p>*/}
                <div style={{width:'100%', display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    paddingBottom:'120px',
                    alignItems: 'center'}}>
                {
                    managedAvailableClassSlots?.map((slot: any, index: number) => <ToggleComponent
                        selectedSlotCount={selectedSlotCount} onToggleSlot={onToggleSlotHandler} slotValue={slot?.slotValue}
                        userManagedReminders={userManagedReminders} title={slot?.title} subtitle={slot?.subtitle}/>)
                }
                </div>

                <Button isSticky={true} disabled={selectedSlotCount === 0} style={{
                    alignSelf: 'center',
                    marginBottom: '24px',
                    height:'50px',
                }} onClick={onSlotUpdateHandler} label={'Save'}></Button>
            </div>

        </Container>
    )
}

export default ManageSignupReminders;
