import React, {CSSProperties} from 'react';
import './PrimaryButton.css';

interface PrimaryButtonProps {
    onClick?: () => void;
    className?: string;
    children: any;
    disabled?: boolean;
    isSticky?:boolean;
    style?:CSSProperties;
    textStyle?:CSSProperties;

}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({style, isSticky,onClick, className, children, disabled,textStyle }) => {

    const buttonStyle: CSSProperties = {
        background:disabled?'var(--Inactive-Color, #D6D6D6)':'var(--Primary_Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%))',
        ...style,
    };

    if (isSticky) {
        // Add sticky styles
        buttonStyle.position = 'fixed';
        buttonStyle.bottom = '10px'; // Adjust the distance from the bottom as needed
        buttonStyle.left = '50%';
        buttonStyle.transform = 'translateX(-50%)';
    }


    return (
        <button
            className={`primary-button ${className} ${disabled ? 'primary-button-disabled' : ''}`}
            onClick={disabled ? undefined : onClick}
            disabled={disabled}
            style={buttonStyle}
        >
            <span className={`primary-button-title ${disabled ? 'disabled':''}`} style={textStyle}>{children}</span>
        </button>
    );
};

export default PrimaryButton;
