import React, {useEffect, useState} from 'react'
import seek_logo_url, {ReactComponent as SeekLogo} from "../../assets/seek-logo.svg";
import './Leaderboard.css'
import {useLocation, useNavigate} from "react-router-dom";
import useAuthenticatedUsers from "../../hooks/useAuthenticatedUsers";
import Header from "../../components-v2/header/Header";
import {Mixpanel} from "../../analytics/Mixpanel";
import BackButton from "../../components-v2/buttons/BackButton";
import LeaderNudge from "./components/LeaderNudge";
import {useSelector} from "react-redux";

const Leaderboard = () => {
    const userData = useAuthenticatedUsers();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    // const [loader,setLoader] = useState<boolean>(false);
    const leaderboardUsers = useSelector((state: any) => state?.leaderboard?.data?.leaderboardUsers)
    const user = state?.user;
    console.log("userData",userData);
    console.log("user",user);
    console.log("leaderboardUsers",leaderboardUsers?.[0]);
    const currentUserLeaderboardStats = leaderboardUsers?.find((leaderboardUser:any)=> leaderboardUser?.phone === user?.phone);


    // const currentUser = leaderboardUsers?.find((user:any) => user.email === userEmail);
    // const onDateChangeHandler = async (parsedStartDate:string,parsedEndDate:string) => {
    //     setLoader(true)
    //     getLeaderBoardUsers(null,parsedStartDate,parsedEndDate).then((result)=>{
    //         setLoader(false)
    //         setLeaderboardUsers(result.users);
    //     })
    // }

    const onKarmaPointsClickHandler = () => {
        Mixpanel.track('click_karma_points_badge', {
            event_action: 'clicked',
            context_user_id: user?.uid,
            total_karma_points: user?.total_karma || 0,
            event_page: 'home_screen',
        });
        navigate('/karma', {state: {email: user?.email, total_karma: user?.total_karma}, replace: false})
    }
     return (
        <div className={'leaderboard-container'}>
            <Header onClick={onKarmaPointsClickHandler} logoUrl={seek_logo_url} karma_points={user?.total_karma}/>
            <div style={{width: '90%', maxWidth: '400px', marginTop: '24px'}}>
                <BackButton onClick={navigate.bind(null, -1)}/>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection:'column',
                marginTop: '24px',
                marginBottom:'16px',
                maxWidth: '375px',
                width: '90%'
            }}>
                <span className={'leaderboard-screen-title'}>{'Weekly Leaderboard'}</span>
                <span className={'leaderboard-screen-subtitle'}>{'Climb the charts and find your inner peace:'}</span>
                <span className={'leaderboard-screen-subtitle'}>{'This week\'s top meditators!'}</span>
            </div>
            <LeaderNudge currentUserLeaderboardStats={currentUserLeaderboardStats} onClick={()=>{}} phone={user?.phone||userData?.phone}
                         leaderboardUsers={leaderboardUsers}/>

        </div>
    )
}

export default Leaderboard
