// TODO new upcoming code
import React, {useEffect, useState} from 'react'
import useAuthenticatedUsers from "../../hooks/useAuthenticatedUsers";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import moment from "moment-timezone";
import {ReactComponent as CalendarIcon} from "../../assets/course-calandar.svg";
import {ReactComponent as TimeIcon} from "../../assets/course-watch.svg";
import {ReactComponent as ProgressIcon} from "../../assets/progress.svg";
import {ReactComponent as CLose} from "../../assets/close_blue.svg";
import {ReactComponent as LanguageIcon} from "../../assets/new-icons/language-icon.svg";
import {ReactComponent as RiskFree} from "../../assets/new-icons/course-risk-free.svg";
import {ReactComponent as Star} from "../../assets/star.svg";
import {ReactComponent as Support} from "../../assets/new-icons/support.svg";


import {
    checkUserExistOrNot,
    enrollUserToTheCourse, getUpcomingCourseByCourseId, getUserByUserId,
    signUpUser, verifyOtp,
} from "../../services/Firebase";

import {storage} from "../../services/Firebase";
import './UpcomingCourseDescriptionPage.css'
import {
    isEmpty,
} from "../../utils/utils";
import {Mixpanel} from "../../analytics/Mixpanel";
import Button from "../../components/button/Button";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {toast} from "react-toastify";
import Loader from "../../components/loader/Loader";
import {COLORS} from "../../utils/colors";
import {useDispatch, useSelector} from "react-redux";
import {getDownloadURL, ref} from "firebase/storage";
import {FaqsCourse} from "../../utils/utils";
import FAQ from "../plans/components/FAQ";
import WeeklyCourseTabView from "./components/WeeklyCourseTabView";
import CourseCheckoutPage from "./components/CourseCheckoutPage";
import {ReactComponent as PaymentSuccess} from "../../assets/payment-success.svg";
import {setForceRefreshOnCoursePurchase, setUpcomingCourses} from "../../store/features/UpcomingClassesSlice";
import CourseRegistraionForm from "./components/CourseRegistraionForm";
import ReactPlayer from "react-player";
import BackButton from "../../components-v2/buttons/BackButton";
import {events, events_action, events_page} from "../../analytics/Events";
import {addUsers} from "../../store/features/AuthSlice";
import CourseOtpForm from "./components/CourseOtpForm";
import watch_intro from "../../assets/new-icons/watch_intro.svg";
import Shimmer from "../../components-v2/shimmer/Shimmer";
import PrimaryButton from "../../components-v2/buttons/PrimaryButton";

interface TriggeredEvents {
    [key: string]: boolean;
}

const sectionRanges = {
    section1: {start: 0, end: 200, section_heading: 'Course landing top video'},
    section2: {start: 0, end: 400, section_heading: 'Course landing top grid'},
    section3: {start: 0, end: 500, section_heading: '7 days risk free'},
    section4: {start: 200, end: 500, section_heading: 'Class timings'},
    section5: {start: 500, end: 2300, section_heading: 'Course info tabs'},
    section6: {start: 4900, end: 5200, section_heading: 'FAQs'},
    // Add other sections as needed
} as { [key: string]: { start: number; end: number; section_heading: string } };


export enum STATES {
    LOADING,
    COURSE_DESCRIPTION_PAGE,
    COURSE_CHECKOUT_PAGE,
    COURSE_SIGNUP_FORM,
    COURSE_OTP_FORM,
    PAYMENT_SUCCESS_PAGE,
    PAYMENT_FAILURE_PAGE,

}


const UpcomingCourseDescriptionPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();
    let calendarItem = useSelector((state: any) => state.upcomingClasses?.upcomingCourse?.data?.[0]);
    // let calendarItem:any;
    const user = useSelector((state: any) => state?.auth?.user?.data);
    const userData = useAuthenticatedUsers(true);
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState<STATES>(STATES.COURSE_DESCRIPTION_PAGE);

    const formattedDate = moment(new Date(calendarItem?.batch[0]?.start_date._seconds * 1000 + calendarItem?.batch[0]?.start_date._nanoseconds / 1000000)).format('DD MMM YY');
    // const endDate = moment(new Date(calendarItem?.batch[0]?.end_date._seconds * 1000 + calendarItem?.batch[0]?.end_date._nanoseconds / 1000000)).format('DD MMM YY');


    const currentDateMoment = moment();


    // const [specialInstruction, setSpecialInstruction] = useState<any>(null);
    const [imageUrl, setImageUrl] = useState('');
    const [videoUrl, setVideoUrl] = useState('');

    const [selectedFAQIndex, setSelectedFAQIndex] = useState<number | null>()
    const [appliedKarmaPoints, setAppliedKarmaPoints] = useState(0)
    const [phone, setPhone] = useState<string>('')
    const [fullName, setFullName] = useState<string>('')
    const dispatch = useDispatch();
    const {courseId} = useParams();
    const [error, setError] = useState('');

    const [isPlayingTopVideo, setIsPlayingTopVideo] = useState(false);
    const force_refresh_on_course_purchase = useSelector((state: any) => state.upcomingClasses?.force_refresh_on_course_purchase); // Assuming upcomingClasses state is stored in Redux


    const [triggeredEvents, setTriggeredEvents] = useState<TriggeredEvents>({
        section1: false,
        section2: false,
        section3: false,
        section4: false,
        section5: false,
        section6: false,
    });

    const markMixPanelEvent = (position: number) => {
        const checkAndTriggerEvent = (sectionKey: string, start: number, end: number) => {
            if (!triggeredEvents[sectionKey] && position >= start - 10 && position <= end + 10) {
                Mixpanel.track('view_course_landing_page_section', {
                    event_action: 'viewed',
                    // event_section: sectionKey,
                    section_heading: sectionRanges[sectionKey].section_heading,
                    event_page: 'course_landing_page',
                    course_name: calendarItem?.title,
                    course_start_date: formattedDate
                });
                setTriggeredEvents((prevEvents: any) => ({...prevEvents, [sectionKey]: true}));
            }
        };

        Object.entries(sectionRanges).forEach(([sectionKey, {start, end}]) => {
            checkAndTriggerEvent(sectionKey, start, end);
        });
    };


    useEffect(() => {
        const handleScroll = () => {
            markMixPanelEvent(window.scrollY);
            if (window.scrollY > 280) {
                setIsPlayingTopVideo(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [triggeredEvents]);


    useEffect(() => {
        if (isEmpty(user) && userData?.uid) {
            getUserByUserId(userData?.uid).then((userLoginResult) => {
                dispatch(addUsers(userLoginResult?.userData))
                Mixpanel.identify(userData?.uid)
            })
        }
    }, [userData])


    useEffect(() => {
        if (calendarItem) {
            Mixpanel.track('view_course_landing_page', {
                event_action: 'viewed',
                course_name: calendarItem?.title,
                course_date: formattedDate,
                instructor_name: calendarItem?.instructor,
                event_page: 'course_landing_page',
                course_type: calendarItem?.batch[0]?.tag_name,
            });
        }
    }, [calendarItem])


    useEffect(() => {
        if ((isEmpty(calendarItem) && courseId) || (courseId && force_refresh_on_course_purchase)) {
            // console.log("fetching course with courseId url", courseId)
            setLoader(true)
            getUpcomingCourseByCourseId(courseId, userData?.uid)
                .then((result: any) => {
                    // Update upcomingClasses in Redux
                    console.log("Api call and Update getUpcomingCourses in Redux", result.data);
                    dispatch(setForceRefreshOnCoursePurchase(false))
                    dispatch(setUpcomingCourses(result.data));
                    setLoader(false)
                    // calendarItem = result.data[0];
                })
                .catch((err: any) => {
                    // console.log("getUpcomingClasses err", err);
                });
        }
    }, [calendarItem, courseId, userData?.uid,force_refresh_on_course_purchase]);


    useEffect(() => {
        (async () => {
            try {
                if (calendarItem?.top_video) {
                    const videoRef = ref(storage, calendarItem?.top_video);
                    const videoUrl = await getDownloadURL(videoRef);
                    // console.log("videoUrl", videoUrl)
                    setVideoUrl(videoUrl);
                }

                if (calendarItem?.thumbnail) {
                    const imageRef = ref(storage, calendarItem?.thumbnail);
                    const imageUrl = await getDownloadURL(imageRef);
                    // console.log("imageUrl", imageUrl)
                    setImageUrl(imageUrl);
                }
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        })();
    }, [calendarItem?.thumbnail]);

    function loadScript(src: any) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function payNowClickHandler(price: string, applied_karma_points: number) {
        // console.log("appliedKarmaPoints",applied_karma_points,price)
        // console.log("payNowClickHandler", calendarItem, calendarItem?.courseId, calendarItem?.batch[0]?.batchId);
        setAppliedKarmaPoints(applied_karma_points)
        if (state === STATES.PAYMENT_FAILURE_PAGE) {
            Mixpanel.track('click_retry_course_payment', {
                event_action: 'clicked',
            });
            setState(STATES.COURSE_CHECKOUT_PAGE);
        }

        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            toast.error(`Razorpay SDK failed to load. Are you online?`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true,
            });
            return;
        }

        //TODO change price
        // const parsedPrice = 1;
        const parsedPrice = parseFloat(price);
        const userEnrollment = await enrollUserToTheCourse(user?.uid || userData?.uid, calendarItem.courseId, '', applied_karma_points, calendarItem?.batch[0]?.batchId);
        // console.log("userEnrollment", userEnrollment);
        // setLoader(false)

        if (userEnrollment?.status === 400) {
            toast.error(`You are already enrolled in this course.`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true,
            });
            return;
        }

        const options = {
            key: "rzp_live_cJ3YCKf6Ayf4ab", // Enter the Key ID generated from the Dashboard
            amount: parsedPrice * 100,
            currency: "INR",
            name: "Kundalini for Beginners - Seek",
            description: `Course Enrollment Fee-${userEnrollment?.docId}`,
            image: 'https://firebasestorage.googleapis.com/v0/b/seek-c3955.appspot.com/o/Frame.svg?alt=media&token=04498e42-c418-4652-af17-1e2b4b228dd7&_gl=1*vvkkh7*_ga*MTcxMzk5NzQ5Ny4xNjg2NzI1ODcy*_ga_CW55HF8NVT*MTY5OTMzNzEyNi4yOTEuMS4xNjk5MzM3NDYyLjQ1LjAuMA..',
            handler: async function (response: any) {
                // console.log("payment response",response)
                if (response.razorpay_payment_id) {
                    setState(STATES.PAYMENT_SUCCESS_PAGE);
                    dispatch(setForceRefreshOnCoursePurchase(true))
                    try {
                        Mixpanel.track('view_course_payment_successful', {
                            event_action: 'viewed',
                            course_name: calendarItem?.title,
                            userId: user?.uid || userData?.uid,
                            event_page: 'course_payment_page'
                        });
                    } catch (e) {

                    }

                } else {
                    setState(STATES.PAYMENT_FAILURE_PAGE);
                    toast.error(`Your payment of ${price} has been failed due to some technical reasons.`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: true,
                    });

                    try {
                        Mixpanel.track('view_course_payment_failed', {
                            event_action: 'viewed',
                            phone: userData?.phoneNumber || userData?.phone,
                            event_page: 'course_payment_page'
                        });
                    } catch (e) {

                    }

                }
            },
            prefill: {
                name: user?.name || userData?.name,
                // email: user?.email || userData?.email || 'contact@seekapp.co',
                contact: user?.phoneNumber || user?.phone || userData?.phoneNumber || userData?.phone || '9959637700',
            },
            theme: {color: COLORS.PRIMARY},
            modal: {
                ondismiss: function (response: any) {
                    try {
                        Mixpanel.track('view_course_payment_failed', {
                            event_action: 'viewed',
                            phone: userData?.phoneNumber || userData?.phone,
                            event_page: 'course_payment_page'
                        });
                    } catch (e) {

                    }

                    toast.error(`Your payment of ${price} has been failed due to some technical reasons.`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: true,
                    });

                }
            }
        };

        const paymentObject = (window as any).Razorpay(options);
        paymentObject.open();
    }

    const onJoinWhatsAppsCommunityHandler = async () => {
        try {
            // TODO update course whatsapp group link
            let whatsappGroupLink = 'https://chat.whatsapp.com/DjjW2D53bOsK8aZ7YpAFZI';
            Mixpanel.track('click_join_whatsapp_course_group', {
                event_action: 'clicked',
                context_user_id: user?.uid,
                course_name: calendarItem?.title,
                phone: userData?.phoneNumber || userData?.phone,
            });
            window.open(calendarItem?.whatsapp_group_link || whatsappGroupLink, '_blank');
            setTimeout(() => {
                if (user?.source === 'WEB') {
                    navigate('/home')
                }
            }, 1000)

        } catch (error) {
            console.error('Error:', error);
            // Handle the error as needed
        }
    };


    const proceedFromCourseDescriptionPage = async () => {
        // console.log("proceedFromCourseDescriptionPage", userData, user);
        Mixpanel.track('click_enroll_now_course_landing_page', {
            event_action: 'clicked',
            event_page: 'course_landing_page',
        });

        if (currentDateMoment.isAfter(formattedDate)) {
            // console.log("course has already started");
            return;
        }

        if (!isEmpty(user) || userData) {
            setState(STATES.COURSE_CHECKOUT_PAGE)
        } else {
            // console.log("enrollNow user not logged in", user)
            Mixpanel.track('view_register_with_seek', {
                event_action: 'viewed',
                event_page: 'register_with_seek',
            });
            setState(STATES.COURSE_SIGNUP_FORM)
        }
    };

    const onFAQClickHandler = (index: number) => {
        try {
            Mixpanel.track('click_course_landing_page_faqs', {
                event_action: 'clicked',
                faq_heading: FaqsCourse[index],
                event_page: 'course_landing_page',
            });
        } catch (e) {
        }
        setSelectedFAQIndex((prevState) => prevState === index ? null : index);
    }
    const location = useLocation();
    const onBackArrowClick = () => {
        if (state === STATES.COURSE_DESCRIPTION_PAGE) {
            // if (location.key === 'default' && userData) {
            //     // console.log("navigate to home logged in user , but came from public link");
            //     navigate('/home')
            // } else
            if (location.key === 'default' && !userData) {
                // console.log("navigate to seeklive.com not logged in user , but came from public link");
                window.location.href = 'https://seekdaily.live';
                navigate(-1);
            } else {
                // console.log("navigate to home logged in user , but came from app");
                navigate(-1);
            }
        }

        // console.log("onBackArrowClick", userData, user);
        try {
            setState(prevState => {
                switch (prevState) {
                    case STATES.COURSE_CHECKOUT_PAGE:
                        return STATES.COURSE_DESCRIPTION_PAGE;
                    case STATES.COURSE_SIGNUP_FORM:
                        return STATES.COURSE_DESCRIPTION_PAGE;
                    case STATES.COURSE_OTP_FORM:
                        return STATES.COURSE_SIGNUP_FORM;
                    default:
                        return prevState;
                }
            });
        } catch (e) {

        }
    }
    const [userAlreadyExist, setUserAlreadyExist] = useState(false)


    const onFormSubmitHandler = async (fullName: string, phoneNumber: string) => {
        // console.log("onFormSubmitHandler", phoneNumber, fullName)
        const isIndianNumber = phoneNumber.startsWith('91') || phoneNumber.startsWith('+91');
        if (isIndianNumber) {
            // console.log("onFormSubmitHandler indian user", phoneNumber, fullName)
            Mixpanel.track('view_otp_screen', {
                event_page: 'otp_screen',
                event_action: events_action.viewed,
                page: 'course_register_with_seek',
            })
            setState(STATES.COURSE_OTP_FORM);
            setPhone(phoneNumber);
            setFullName(fullName);
            const checkUserExistOrNotResponse = await checkUserExistOrNot(phoneNumber);
            if (checkUserExistOrNotResponse?.status === 409 && checkUserExistOrNotResponse?.message === 'User already exists.Login User') {
                // console.log("checkUserExistOrNotResponse if", checkUserExistOrNotResponse?.message);
                setUserAlreadyExist(true)
            } else {
                // console.log("checkUserExistOrNotResponse not ", checkUserExistOrNotResponse?.message);
                setUserAlreadyExist(false)
            }
        } else {
            // console.log("onFormSubmitHandler foreign user", phoneNumber, fullName)
            // escaping otp for foreign clients.
            const nameArray = fullName.split(' ');
            const firstName = nameArray[0];
            const lastName = nameArray.slice(1).join(' ');

            const formDataMergedFromFirebaseAndSingUpForm = {
                userPartOfWhatsappCommunity: false,
                // userPreferences: userPreferences,
                name: fullName,
                phone: phoneNumber,
                // ...signUpData,
                trialStartDate: moment().format('D MMM YYYY'),
            };
            try {
                const userSignUpResult = await signUpUser(formDataMergedFromFirebaseAndSingUpForm);
                if (userSignUpResult) {
                    setLoader(false);
                    Mixpanel.identify(userSignUpResult.userId);
                    Mixpanel.people.set({
                        $id: userSignUpResult.userId,
                        $first_name: firstName,
                        $last_name: lastName,
                        // $email:signUpData.email,
                        $name: fullName,
                        $phone: phoneNumber,
                    });
                    setLoader(false);
                    Mixpanel.track('SignUp', formDataMergedFromFirebaseAndSingUpForm);
                    dispatch(addUsers(formDataMergedFromFirebaseAndSingUpForm));
                    setState(STATES.COURSE_CHECKOUT_PAGE);
                    // TODO show checkout page for international users without otp screen
                    // onProceedHandler(userSignUpResult.userId, formDataMergedFromFirebaseAndSingUpForm);

                } else {
                    setLoader(false);
                    // setError('Error creating account')
                }
            } catch (e) {

            }
        }
    }


    const onOtpVerifyHandler = async (otp: number) => {
        // setLoader(true)
        if (userAlreadyExist) {
            try {
                const userLoginResult = await verifyOtp(otp, phone, '');
                // console.log("userLoginResult", userLoginResult)
                if (userLoginResult?.status === 200) {
                    // setIsLoginSuccessful(true)
                    Mixpanel.identify(userLoginResult.userData?.uid)
                    Mixpanel.track(events.view_login_successful, {
                        event_page: events_page.sign_up,
                        event_action: events_action.viewed,
                        response: {
                            phone: phone
                        }
                    })

                    const user = {
                        phone: userLoginResult?.userData?.phone,
                        name: userLoginResult?.userData?.name,
                        phoneNumber: userLoginResult?.userData?.phoneNumber,
                        uid: userLoginResult.userData?.uid,
                        // isProfileCompleted: isProfileCompleted,
                    }
                    dispatch(addUsers(userLoginResult.userData))
                    window.localStorage.setItem('user', JSON.stringify(user))
                    setState(STATES.COURSE_CHECKOUT_PAGE)
                    // setLoader(false);
                } else {
                    // setLoader(false);
                    setError('Please enter the correct OTP')
                    Mixpanel.track('otp_incorrect', {
                        event_page: events_page.otp_screen,
                        event_action: events_action.viewed,
                    })
                }
            } catch (e) {
            }
        } else {
            const nameArray = fullName.split(' ');
            const firstName = nameArray[0];
            const lastName = nameArray.slice(1).join(' ');

            const formDataMergedFromFirebaseAndSingUpForm = {
                otp: otp,
                userPartOfWhatsappCommunity: false,
                name: fullName,
                phone: phone,
                firstName: firstName,
                lastName: lastName,
                isProfileCompleted: false,
                trialStartDate: moment().format('D MMM YYYY'),
            };
            try {
                const userSignUpResult = await signUpUser(formDataMergedFromFirebaseAndSingUpForm);
                // console.log("userSignUpResult", userSignUpResult)
                if (userSignUpResult) {
                    // setLoader(false);
                    Mixpanel.identify(userSignUpResult.userId);
                    Mixpanel.people.set({
                        $id: userSignUpResult?.userId,
                        $first_name: firstName,
                        $last_name: lastName,
                        // $email:signUpData?.email,
                        $name: fullName,
                        $phone: phone,
                        $trial_start_date: moment().format('D MMM YYYY'),
                    });
                    Mixpanel.track('SignUp', formDataMergedFromFirebaseAndSingUpForm);
                    // Update redux with user data
                    dispatch(addUsers(userSignUpResult?.userData));
                    setState(STATES.COURSE_CHECKOUT_PAGE)
                } else {
                    // setLoader(false);
                    setError('Please enter the correct OTP')
                    Mixpanel.track('otp_incorrect', {
                        event_page: events_page.otp_screen,
                        event_action: events_action.clicked,
                    })
                }
            } catch (e) {
            }
        }
    }


    const chatWhatsAppsHandler = async () => {
        try {
            Mixpanel.track('click_chat_with_us_landing_page', {
                event_action: 'clicked',
                event_page: 'course_landing_page',
            });
            const whatsappGroupLink = 'https://wa.me/9555434844';
            window.open(whatsappGroupLink, '_blank');
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const onPlayIconClick = () => {
        console.log("onPlayIconClick", isPlayingTopVideo)
        setIsPlayingTopVideo(prevState => !prevState)
    }

    if (loader) {
        return <Loader/>
    } else if (state === STATES.PAYMENT_SUCCESS_PAGE) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '154px',
                    position: 'relative'
                }}>
                <PaymentSuccess width={120} height={120}/>
                <span className={'course-payment-success-hurray'}>Payment Successful 🎉</span>
                <span className={'course-payment-success-plan-description'}>
                {`You have successfully enrolled in `}
                    <strong style={{
                        color: '#2A2A2A',
                        fontWeight: '500',
                        fontFamily: 'Poppins-Medium'
                    }}>{calendarItem?.title}</strong>
                    {`. We will share further details with you over WhatsApp.`}
           </span>
                <PrimaryButton
                    isSticky={false}
                    style={{
                        // backgroundColor: daysToExpire < 0 ? COLORS.DARK_GREY : COLORS.PRIMARY,
                        alignSelf: 'center',
                        width: 'auto',
                        height:'40px',
                        marginTop: '24px',
                        borderRadius: '8px',
                        background: 'var(--Primary-Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%))',
                    }}
                    onClick={onJoinWhatsAppsCommunityHandler}
                >Join WhatsApp Group</PrimaryButton>
            </div>
        )
    } else if (state === STATES.PAYMENT_FAILURE_PAGE) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '50%',
                    position: 'relative'
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="98" height="88" viewBox="0 0 98 88" fill="none">
                    <path
                        d="M40.5607 5.27521C41.4261 3.80618 42.6599 2.58845 44.1402 1.74244C45.6204 0.896423 47.2959 0.451416 49.0009 0.451416C50.7059 0.451416 52.3813 0.896423 53.8616 1.74244C55.3419 2.58845 56.5757 3.80618 57.4411 5.27521L77.0745 38.9413L96.7087 72.6026C97.5586 74.0753 98.004 75.7466 98 77.447C97.9959 79.1473 97.5426 80.8164 96.6858 82.2851C95.829 83.7539 94.5992 84.9701 93.121 85.8105C91.6429 86.6509 89.9688 87.0857 88.2685 87.0708H9.73245C8.0319 87.0859 6.35759 86.6511 4.8792 85.8106C3.4008 84.9701 2.17086 83.7537 1.31401 82.2848C0.457158 80.8158 0.00385102 79.1464 2.4429e-05 77.4458C-0.00380216 75.7452 0.441987 74.0738 1.29222 72.6009L20.9264 38.9413L40.5607 5.27521Z"
                        fill="#FD2727"/>
                    <path
                        d="M45.503 53.5448L44.4848 38.462C44.2941 35.523 44.1988 33.4134 44.1988 32.1333C44.1092 30.6455 44.6058 29.1818 45.5822 28.0557C46.0511 27.5676 46.6183 27.1846 47.2463 26.9322C47.8743 26.6798 48.5487 26.5636 49.225 26.5914C51.0489 26.5914 52.2683 27.216 52.8834 28.4651C53.5845 30.1755 53.8995 32.0195 53.8059 33.8656C53.8043 35.2751 53.7299 36.6835 53.5828 38.0853L52.2149 53.6109C52.1571 55.0735 51.8337 56.5133 51.2605 57.8601C51.0544 58.3223 50.713 58.7113 50.2814 58.9755C49.8497 59.2397 49.348 59.3667 48.8426 59.3399C48.3455 59.3775 47.8491 59.2598 47.4217 59.0033C46.9943 58.7467 46.6571 58.3638 46.4565 57.9075C45.9094 56.5135 45.5873 55.0415 45.5021 53.5464L45.503 53.5448ZM49.033 74.2649C47.7889 74.2831 46.582 73.84 45.6451 73.0213C45.1579 72.5874 44.7747 72.0493 44.5241 71.4469C44.2736 70.8445 44.1621 70.1933 44.198 69.5419C44.1877 68.9228 44.3052 68.3083 44.5432 67.7368C44.7813 67.1652 45.1347 66.6489 45.5814 66.2202C46.0237 65.7742 46.5522 65.4227 47.1346 65.1873C47.717 64.9519 48.3412 64.8373 48.9693 64.8507C49.6018 64.8392 50.2303 64.9544 50.8176 65.1896C51.4049 65.4248 51.9392 65.7752 52.389 66.2202C52.8438 66.6443 53.2047 67.159 53.4485 67.7311C53.6923 68.3032 53.8135 68.9201 53.8043 69.5419C53.8373 70.1893 53.7259 70.8359 53.4783 71.435C53.2307 72.034 52.8531 72.5706 52.3727 73.0058C51.457 73.8304 50.2643 74.2799 49.0322 74.2649"
                        fill="white"/>
                </svg>
                <span style={{
                    color: '#3A3A3A',
                    textAlign: 'center',
                    fontFamily: 'Poppins-SemiBold',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    marginTop: '32px'
                }}>Payment Failed</span>
                <span className={'payment-success-plan-description'}
                      style={{marginTop: '16px'}}>{`Your payment of INR ${calendarItem?.price} has been failed due to some technical reasons.`}</span>

                <CLose onClick={navigate.bind(null, -1)} style={{position: 'absolute', top: -120, right: 24}} width={36}
                       height={36}/>

                <Button
                    isSticky={false}
                    style={{
                        // backgroundColor: daysToExpire < 0 ? COLORS.DARK_GREY : COLORS.PRIMARY,
                        alignSelf: 'center',
                        background: 'var(--primary_lr_grad, linear-gradient(106deg, #534DE0 32.65%, #4641A8 105.18%))',
                    }}
                    onClick={payNowClickHandler.bind(null, calendarItem?.price, appliedKarmaPoints)}
                    label={'Retry Payment'}
                />
            </div>
        )
    } else if (state === STATES.COURSE_CHECKOUT_PAGE) {
        return (
            <CourseCheckoutPage imageUrl={imageUrl} userId={user?.uid || userData?.uid} totalKarma={user?.total_karma}
                                courseItem={calendarItem}
                                payNowClickHandler={payNowClickHandler}
                                onBackArrowClick={onBackArrowClick}/>
        )
    } else if (state === STATES.COURSE_SIGNUP_FORM) {
        return (
            <CourseRegistraionForm loading={loader} onFormSubmitHandler={onFormSubmitHandler} setState={setState}
                                   onBackArrowClick={onBackArrowClick}
                                   user={user}/>
        )
    } else if (state === STATES.COURSE_OTP_FORM) {
        return (
            <CourseOtpForm otpError={error} page={'course_registration_form'} onBackArrowClick={onBackArrowClick}
                           onOtpVerifyHandler={onOtpVerifyHandler}
                           mobileNumber={phone}/>
        )
    }

    return (
        <div className="course-description-container"
             style={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
            <div style={{width: '90%', maxWidth: '400px', marginTop: '16px'}}>
                <BackButton onClick={navigate.bind(null, -1)}/>
                {/*<span className={'toolbar-title'}>{'Checkout Details'}</span>*/}
            </div>
            <div onClick={onPlayIconClick} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'center',
                position: 'relative',
                marginRight: '-16px',
                width: '100%',
                borderRadius: '12px',
                maxWidth: '343px',
                maxHeight: '221px',
                marginTop: '24px'
            }}>
                {videoUrl && !isPlayingTopVideo &&
                    <img
                        onClick={onPlayIconClick}
                        src={watch_intro}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            zIndex:10,
                            transform: 'translate(-50%, -50%)',
                            width: '120px',
                            height: '32px',
                        }}
                        alt="Play Icon"
                    />
                }

                {videoUrl ? <ReactPlayer
                        controls={false}
                        playing={isPlayingTopVideo}
                        autoPlay={false}
                        loop={true}
                        playsinline={true}
                        fileconfig={{attributes: {autoPlay: false}}}
                        url={videoUrl}
                        width={"100%"}
                        onProgress={(progress) => {

                        }}
                        height={221}
                        style={{
                            display: 'flex',
                            width: '100%',
                            height: 'auto',
                            background: 'white',
                            backgroundColor: 'transparent',
                            alignSelf: 'center',
                            margin: '-16px auto', // Center the player horizontally
                        }}
                    /> :
                    imageUrl?<LazyLoadImage src={imageUrl} className={"course-description-image-banner"}/>:<Shimmer style={{height:'200px'}}/>}
            </div>


            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                width: '344px',
                marginTop: '24px',
                alignSelf: 'center',
                justifyContent: 'space-between',
                marginLeft: '16px',
                // marginRight: '16px'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 5
                }}>
                     <span style={{
                         color: '#1B1625',
                         fontFamily: 'Gordita-Medium',
                         width: '312px',
                         fontSize: '20px',
                         fontStyle: 'normal',
                         fontWeight: 500,
                         lineHeight: 'normal',
                         flex: 4
                     }}>{calendarItem?.title}</span>
                    <span style={{
                        color: "#2A2A2A",
                        fontFamily: "Gordita-Medium",
                        fontSize: "12px",
                        fontStyle: "normal",
                        alignSelf: 'center',
                        fontWeight: 500,
                        marginTop: '4px',
                        lineHeight: "16px" // 133.333%
                    }}>{'5'}</span>
                    <Star width={16} height={16} style={{marginLeft: '8px', alignSelf: 'center'}}/>
                </div>


                <div className={'upcoming-course-subheader-container'}>
                    <div className={'upcoming-course-subheader-subContainer'}>
                        <CalendarIcon width={24} height={24}/>
                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: '4px', alignSelf: 'start'}}>
                            <span className={'upcoming-course-subheader-title'}>{formattedDate}</span>
                            <span className={'upcoming-course-subheader-subtitle'}>{'Start Date'}</span>
                        </div>
                    </div>
                    <div className={'upcoming-course-subheader-subContainer'}>
                        <ProgressIcon width={24} height={24}/>
                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: '4px', alignSelf: 'start'}}>
                            <span className={'upcoming-course-subheader-title'}>{calendarItem?.intensity}</span>
                            <span className={'upcoming-course-subheader-subtitle'}>{'Intensity'}</span>
                        </div>
                    </div>
                </div>

                <div className={'upcoming-course-subheader-container'}>
                    <div className={'upcoming-course-subheader-subContainer'}>
                        <LanguageIcon width={24} height={24}/>
                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: '4px', alignSelf: 'start'}}>
                            <div className={'upcoming-course-subheader-title'}>{calendarItem?.language}</div>
                            <span className={'upcoming-course-subheader-subtitle'}>{'Language'}</span>
                        </div>
                    </div>
                    <div className={'upcoming-course-subheader-subContainer'}>
                        <TimeIcon width={24} height={24}/>
                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: '4px', alignSelf: 'start'}}>
                            <span className={'upcoming-course-subheader-title'}>{calendarItem?.duration}</span>
                            <span className={'upcoming-course-subheader-subtitle'}>{'Course Duration'}</span>
                        </div>
                    </div>
                </div>
                <RiskFree style={{marginTop: '24px'}}/>
                <div style={{marginTop: '24px', width: '100%'}}>
                    <span className={'upcoming-course-page-timing-title'}>Class Timings (IST)</span>
                    <span className={'upcoming-course-page-timing-subtitle'}>Join at any time</span>
                    <div className={'upcoming-course-page-slot-timing'}>
                        {calendarItem?.class_slots?.map((item: any, index: number) => (
                            <>
                                <div className={'upcoming-course-page-slot-value'}>{item.slot}</div>
                                {(index !== calendarItem.class_slots.length - 1 && index != 3) && (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="12" viewBox="0 0 2 12"
                                         fill="none" className={'svg-divider'}>
                                        <path d="M0.324005 11.6279V0.371948H1.17601V11.6279H0.324005Z" fill="#E4E4E4"/>
                                    </svg>
                                )}
                            </>
                        ))}
                    </div>
                </div>
                <WeeklyCourseTabView calendarItem={calendarItem}/>
                <span className={'page-section-title'}
                      style={{alignSelf: "start", width: '343px', margin: '32px auto 0px auto'}}>FAQs</span>
                <div style={{marginBottom: '96px'}}>
                    {FaqsCourse?.map((faq, index) =>
                        <FAQ selectedIndex={selectedFAQIndex} index={index} item={faq}
                             onClickHandler={onFAQClickHandler}/>)}
                </div>

                <div onClick={chatWhatsAppsHandler}>
                    <Support style={{
                        position: 'fixed',
                        alignSelf: 'center',
                        bottom: '84px',
                        right: '2px',
                        zIndex: '100',
                        cursor: 'pointer',
                        backgroundColor: 'transparent'
                    }} width={64} height={64}/>
                </div>


                <div className="plan-fixed-bottom-container">
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span className="plan-fixed-bottom-price">{`₹ ${calendarItem?.price}`}</span>
                    </div>
                    <button disabled={calendarItem?.batch[0]?.user_already_enrolled}
                            className="plan-fixed-bottom-button"
                            style={{background: calendarItem?.batch[0]?.user_already_enrolled && '#A4A4A4'}}
                            onClick={proceedFromCourseDescriptionPage}>
                        {currentDateMoment.isAfter(formattedDate) ? 'Enrollment closed' : calendarItem?.batch[0]?.user_already_enrolled ? 'Already enrolled' : 'Enrol Now'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default UpcomingCourseDescriptionPage

