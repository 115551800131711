import axios from "axios";
import {auth} from "./Firebase";
export const  CLOUD_FUNCTION_URL = 'https://us-central1-seek-c3955.cloudfunctions.net'

const getAxiosHeader = async () =>{
    try {
        const token = await auth.currentUser?.getIdToken();
        // console.log("token 1",token)
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        return config;
    }catch (e) {
        // console.log("token parsing error",e)
    }
}


export const postRequest = async (url,params) => {
    const header = await getAxiosHeader();
    try {
        const response = await axios.post(url,params,header);
        return response;
    }catch (e) {
        // console.log("getRequest err",e);
    }

}

export const getRequest = async (url,params) =>{
    const header = await getAxiosHeader();
    try {
        const response = await axios.post(url,params,header);
        return response;
    }catch (e) {
        // console.log("getRequest err",e);
    }
}
