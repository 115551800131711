import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { ReactComponent as BackArrow } from '../../../assets/left_arrow_black.svg';
import './VideoAnimation.css';
import Button from '../../../components/button/Button';
import ReactPlayer from 'react-player';

interface TestimonialPageProps {
	onVideoAnimationPageContinueHandler: () => void;
	onBackArrowClick: () => void;
	videoUrl: string;
	label: string;
	isBackArrow: boolean;
	buffer: number;
}

const VideoAnimation: React.FC<TestimonialPageProps> = ({
	buffer,
	label,
	isBackArrow,
	onVideoAnimationPageContinueHandler,
	onBackArrowClick,
	videoUrl,
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [isVideoEnded, setIsVideoEnded] = useState(false);
	const [isVideoPlaying, setIsVideoPlaying] = useState(false);
	const onAnimationComplete = () => {
		setIsVideoEnded(true);
	};

	const handlePlay = () => {
		setIsVideoPlaying(true);
	};

	return (
		<Container className={'video-animation-page-container'}>
			<div className={'video-animation-header-container'}>
				{isBackArrow ? (
					<BackArrow onClick={onBackArrowClick} width={48} height={48} />
				) : (
					<div style={{ marginTop: '64px' }} />
				)}
				<div />
				<div />
			</div>
			<div className={'video-animation-body-container'}>
				{
					<ReactPlayer
						controls={false}
						playing={true}
						autoPlay={true}
						muted={true}
						loop={true}
						onPlay={handlePlay}
						playsinline={true}
						fileconfig={{ attributes: { autoPlay: true } }}
						url={videoUrl}
						width={'100%'}
						onProgress={(progress) => {
							if (progress.playedSeconds >= progress.loadedSeconds - 20) {
								onAnimationComplete();
							}
						}}
						height={472}
						style={{
							display: 'flex',
							opacity: isVideoPlaying ? '1' : '0.01',
							width: '100%',
							maxWidth: '327px',
							maxHeight: '472px',
							height: 'auto',
							background: 'white',
							backgroundColor: 'transparent',
							alignSelf: 'center',
							margin: '-16px auto',
						}}
					/>
				}

				<Button
					disabled={!isVideoEnded}
					isSticky={true}
					style={{
						alignSelf: 'center',
						marginBottom: '24px',
						background: !isVideoEnded
							? 'var(--Inactive-Color, #D6D6D6)'
							: 'var(--Primary_Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%))',
					}}
					onClick={onVideoAnimationPageContinueHandler}
					label={label}></Button>
			</div>
		</Container>
	);
};

export default VideoAnimation;
