import React, {CSSProperties} from 'react';
import './HorizontalChipWithIconAndTitle.css'; // Import your CSS file for styling

export interface HorizontalChipsWithIconAndTitleProps {
    Icon: any;
    title: string;
    subtitle?:string;
    styles?: {
        icon?: React.CSSProperties;
        title?: React.CSSProperties;
    };
    onIconClick?: () => void;
    customChipStyle?:React.CSSProperties;
}

const HorizontalChipWithIconAndTitle: React.FC<HorizontalChipsWithIconAndTitleProps> = ({ Icon, title, styles,onIconClick,customChipStyle,subtitle }) => {
    return (
        <div onClick={onIconClick} className="horizontal-chip-icon-title-container" style={customChipStyle}>
            <Icon className="horizontal-chip-icon" style={styles?.icon} />
            <span className="horizontal-chip-title" style={styles?.title}>{title}</span>
            {subtitle && <span className="horizontal-chip-subtitle">{subtitle}</span>}
        </div>
    );
};

export default HorizontalChipWithIconAndTitle;
