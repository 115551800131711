export const events = {
    click_sign_up:'click_sign_up',
    view_sign_up_page:'view_sign_up_page',
    view_opt_in_checkbox:'view_opt_in_checkbox',
    click_opt_in_checkbox:'click_opt_in_checkbox',
    click_info_icon_opt_in:'click_info_icon_opt_in',
    click_notification_preference:'click_notification_preference',
    click_proceed_sign_up:'click_proceed_sign_up',
    view_post_ob_video:'view_post_ob_video',
    view_otp_screen:'view_otp_screen',
    click_resend_otp:'click_resend_otp',
    view_account_created_screen:'view_account_created_screen',
    click_choose_your_plan_from_account_created:'click_choose_your_plan_from_account_created',
    get_started:'get_started',
    view_how_to_join_live_class:'view_how_to_join_live_class',
    click_login:'click_login',
    view_login_page:'view_login_page',
    click_login_from_log_in_page:'click_login_from_log_in_page',
    view_login_successful:'view_login_successful',
    remember_me:'remember_me',
    view_oops_after_login:'view_oops_after_login',
    view_plan_details_page:'view_plan_details_page',
    click_view_FAQs_on_plan_details_page:'click_view_FAQs_on_plan_details_page',
    click_proceed_further_from_plan_page:'click_proceed_further_from_plan_page',
    view_payment_screen:'view_payment_screen',
    click_callback_payment:'click_callback_payment',
    click_pay_now:'click_pay_now',
    view_payment_successful:'view_payment_successful',
    click_join_whatsapp_community:'click_join_whatsapp_community',
    view_free_access_page:'view_free_access_page',
    click_callback_free_access:'click_callback_free_access',
    click_whatsapp_community:'click_whatsapp_community',
    view_incorrect_referral_message:'view_incorrect_referral_message',
}

export const events_page = {
    app_landing_page:'app_landing_page',
    sign_up:'sign_up',
    profile:'profile',
    otp_screen:'otp_screen',
    account_created:'account_created',

    login:'login',
    login_successful:'login_successful',
    plan_detail_payment:'plan_detail_payment',
    payment_screen:'payment_screen',
    free_access:'free_access'

}

export const events_action = {
    clicked:'clicked',
    viewed:'viewed',
}
