import React, { useEffect, useState } from 'react';
import Loader from '../components/loader/Loader';
import LandingPageOne from './LandingPageOne';
import { useNavigate } from 'react-router-dom';
import useAuthenticatedUsers from "../hooks/useAuthenticatedUsers";
import {getUserByUserId} from "../services/Firebase";
import moment from "moment-timezone";


function Landing() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const userData = useAuthenticatedUsers();
    console.log("landing userString",userData)

    useEffect(() => {
        if (userData?.uid) {
            getUserByUserId(userData?.uid).then((userLoginResult) => {
                console.log("Landing getUserByUserId",userLoginResult?.userData);
                let isProfileCompleted = true;
                try {
                    if(moment(new Date(userLoginResult.userData?.createdAt?._seconds * 1000)).isAfter(moment('Jul 19 2024')) && !userLoginResult.userData?.isProfileCompleted){
                        isProfileCompleted = false;
                    }else{
                        isProfileCompleted = true;
                    }
                }catch (e) {
                    console.log("signupdate logi errn",e);
                }
                // console.log("Landing isProfileCompleted",isProfileCompleted);
                if(isProfileCompleted){
                    setLoading(false);
                    navigate('/home');

                }else{
                    setLoading(false);
                    navigate('/complete-your-profile');
                }
            })
        }else {
            setLoading(false);
        }
    }, [userData])


    // useEffect(()=>{
    //     if(userData){
    //         if(!userData?.isProfileCompleted){
    //             navigate('/complete-your-profile');
    //         }else {
    //             navigate('/home');
    //         }
    //         setLoading(false);
    //     }else{
    //         setLoading(false);
    //     }
    // },[userData])

    return (
        <div className={'App'}>
            {!loading  && <LandingPageOne />}
            {loading && <Loader />}
        </div>
    );
}

export default Landing;
