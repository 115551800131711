import React, {
	forwardRef,
	Ref,
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
import '../../styles.css';
import './WeekDateRange.css';
import { ReactComponent as LeftArrow } from '../../assets/left-arrow.svg';
import { ReactComponent as RightArrow } from '../../assets/right-slide.svg';
// import { ReactComponent as RightArrow } from "../../assets/right-arrow.svg";
import { ReactComponent as PresentClass } from '../../assets/class-present.svg';
import { ReactComponent as AbsentClass } from '../../assets/class-absent.svg';
import { ReactComponent as TodayClass } from '../../assets/class-today.svg';
import { ReactComponent as FutureClass } from '../../assets/class-future.svg';
import CustomCard from '../card/CustomCard';
import { getLiveStreamingAttendance } from '../../services/Firebase';
import { getTodayDateInUtc, parsedAttendanceData } from '../../utils/utils';
import { Mixpanel } from '../../analytics/Mixpanel';
import dayjs from 'dayjs';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import Shimmer from '../../components-v2/shimmer/Shimmer';
import moment from 'moment-timezone';
import {
	setClassAttendanceOfTheWeek,
	setClassPresentCount,
	setReloadAttendance,
} from '../../store/features/LeaderboardSlice';
import { updateEventsStatus } from '../../store/features/EventsSlices';

interface AttendanceProps {
	day: string;
	icon: string;
	parsedCurrentDate: string;
}

const todayDate = getTodayDateInUtc();

const Attendance: React.FC<AttendanceProps> = ({
	day,
	icon,
	parsedCurrentDate,
}) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<p
				style={{
					color:
						parsedCurrentDate > todayDate
							? '#1B1625'
							: parsedCurrentDate === todayDate && icon !== 'Present'
							? '##FAA037'
							: icon === 'Present'
							? '#1B1625'
							: '#A4A4A4',
					fontFamily: 'Gordita-Regular',
					fontSize: '12px',
					fontStyle: 'normal',
					fontWeight: '400',
					marginBottom: '4px',
					lineHeight: 'normal',
				}}>
				{day}
			</p>
			{parsedCurrentDate > todayDate ? (
				<FutureClass width={24} height={24} />
			) : parsedCurrentDate === todayDate && icon !== 'Present' ? (
				<TodayClass width={24} height={24} />
			) : icon === 'Present' ? (
				<PresentClass width={24} height={24} />
			) : (
				<AbsentClass width={24} height={24} />
			)}
		</div>
	);
};

export interface EmptyAttendanceProps {
	day: string;
	weekStartDate?: Date | null;
}

export const EmptyAttendance: React.FC<EmptyAttendanceProps> = ({
	day,
	weekStartDate,
}) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<p
				style={{
					color: '#2A2A2A',
					fontFamily: 'Poppins-Regular',
					fontSize: '12px',
					fontStyle: 'normal',
					fontWeight: '400',
					marginBottom: '4px',
					lineHeight: 'normal',
				}}>
				{day}
			</p>
		</div>
	);
};

interface WeekDateRangeProps {
	reload?: boolean;
	userId: string;
}

const WeekDateRange = forwardRef((props: WeekDateRangeProps, ref: Ref<any>) => {
	const [today, setToday] = useState<any>(new Date());
	const [weekStartDate, setWeekStartDate] = useState<Date | null>(null);
	const [weekEndDate, setWeekEndDate] = useState<Date | null>(null);
	const [loader, setLoader] = useState(false);
	const classAttendanceOfTheWeek = useSelector(
		(state: any) => state.leaderboard?.data?.classAttendanceOfTheWeek
	);
	const classPresentCount = useSelector(
		(state: any) => state.leaderboard?.data?.classPresentCount
	);
	const reloadAttendance = useSelector(
		(state: any) => state.leaderboard?.data?.reloadAttendance
	);
	const user = useSelector((state: any) => state?.auth?.user?.data);
	const dispatch = useDispatch();
	const view_weekly_attendance = useSelector(
		(state: any) => state?.events?.eventsLog?.view_weekly_attendance
	);

	const logViewEvent = useCallback(() => {
		if (!view_weekly_attendance && classPresentCount && user) {
			Mixpanel.track('view_weekly_attendance', {
				event_action: 'viewed',
				weekly_goal: user?.userPreferences?.goal?.title?.split(' ')?.[0] || 7,
				days_attended: classPresentCount,
				event_page: 'profile',
			});
			dispatch(
				updateEventsStatus({ key: 'view_weekly_attendance', value: true })
			);
		}
	}, [view_weekly_attendance, classPresentCount, user]);

	const cardRef = useIntersectionObserver(logViewEvent, { threshold: 0.5 });
	useEffect(() => {
		const currentDay = moment(today).day();
		const diff = currentDay === 0 ? -6 : 1 - currentDay;

		// Calculate the start date
		const startDate = moment(today)
			.add(diff, 'days')
			.startOf('day')
			.set({ hour: 5, minute: 30 });

		// Calculate the end date by adding 6 days to the start date
		const endDate = moment(startDate).add(6, 'days').endOf('day');

		setWeekStartDate(startDate.toDate());
		setWeekEndDate(endDate.toDate());
	}, [today]);

	useEffect(() => {
		const getData = async () => {
			const daysDifference = dayjs(weekEndDate).diff(
				dayjs(weekStartDate),
				'day'
			);
			if (
				weekStartDate &&
				weekEndDate &&
				weekStartDate < weekEndDate &&
				daysDifference === 6
			) {
				setLoader(true);

				const formattedStartDate = weekStartDate.toLocaleDateString('en-GB'); // Adjust the locale as needed
				const formattedEndDate = weekEndDate.toLocaleDateString('en-GB');

				let [day, month, year] = formattedStartDate.split('/');

				let parsedStartDate = `${year}-${month}-${day}`;

				[day, month, year] = formattedEndDate.split('/');

				let parsedEndDate = `${year}-${month}-${day}`;

				try {
					const liveStreamingAttendanceResponse =
						await getLiveStreamingAttendance(props?.userId, {
							weekStartDate: parsedStartDate,
							weekEndDate: parsedEndDate,
						});
					if (liveStreamingAttendanceResponse?.status === 200) {
						// console.log("liveStreamingAttendanceResponse?.attendance",liveStreamingAttendanceResponse?.attendance);
						const parsedAttendance = parsedAttendanceData(
							liveStreamingAttendanceResponse?.attendance,
							parsedStartDate,
							parsedEndDate
						);
						const presentCount = parsedAttendance.filter(
							(item: any) => item.icon === 'Present'
						).length;
						dispatch(setClassPresentCount(presentCount));
						// setClassPresentCount(presentCount);
						dispatch(setClassAttendanceOfTheWeek(parsedAttendance));
						dispatch(setReloadAttendance(false));
						Mixpanel.track('view_weekly_attendance', {
							event_action: 'viewed',
							context_user_id: props?.userId,
							week_range_shown: {
								startDate: parsedStartDate,
								endDate: parsedEndDate,
							},
							days_attended: parsedAttendance,
							context_plan_id: '',
							event_page: 'home_screen',
						});

						// props.updateClassCount(parsedAttendance, parsedStartDate, parsedEndDate)
						setLoader(false);
					}
				} catch (e: any) {
					// console.log("liveStreamingAttendanceResponse err",e);
					dispatch(setReloadAttendance(false));
				} finally {
					setLoader(false);
					dispatch(setReloadAttendance(false));
				}
			}
		};
		if (
			classAttendanceOfTheWeek === -1 ||
			classPresentCount === -1 ||
			reloadAttendance
		) {
			getData().then();
		}
	}, [weekStartDate, weekEndDate, reloadAttendance]);

	return (
		<>
			{loader ? (
				<Shimmer style={{ height: '132px', marginTop: '24px' }} />
			) : (
				<CustomCard
					style={{
						borderRadius: '16px',
						width: '90%',
						maxWidth: '400px',
						height: 'auto',
						marginTop: '32px',
						display: 'flex',
						flexDirection: 'column',
						boxShadow: 'none',
					}}>
					<div
						ref={cardRef}
						className={'horizontal-container'}
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
						}}>
						<div className={'vertical-container'} style={{ width: '100%' }}>
							<span className={'attendance-title'}>Weekly Attendance</span>
							<p className={'attendance-subtitle'}>{`You attended ${
								classPresentCount > 0 ? classPresentCount : 0
							} of ${
								user?.userPreferences?.goal?.title?.split(' ')?.[0] || 7
							} days this week`}</p>
						</div>
						{/*<HorizontalChipWithIconAndTitle customChipStyle={{*/}
						{/*    borderRadius: '12px',*/}
						{/*    width: '40%',*/}
						{/*    height: '32px',*/}
						{/*    padding: '6px',*/}
						{/*    border: '1px solid var(--Light-Grey-2, #E4E4E4)'*/}
						{/*}} styles={{*/}
						{/*    icon: {width: '16px', height: '16px'},*/}
						{/*    title: {*/}
						{/*        color: 'var(--Body-Font, #000)',*/}
						{/*        fontFamily: 'Gordita-Medium',*/}
						{/*        fontSize: '12px',*/}
						{/*        marginLeft: '4px',*/}
						{/*        alignSelf: 'center',*/}
						{/*        textAlign: 'center',*/}
						{/*        fontStyle: 'normal',*/}
						{/*        fontWeight: 500,*/}
						{/*        lineHeight: '16px'*/}
						{/*    }*/}
						{/*}*/}
						{/*} Icon={Edit} title={'Edit Goal'}/>*/}
					</div>

					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}>
						{classAttendanceOfTheWeek?.length > 0
							? classAttendanceOfTheWeek?.map((item: any) => (
									<Attendance
										parsedCurrentDate={item.parsedCurrentDate}
										day={item.day}
										icon={item.icon}
									/>
							  ))
							: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
									<EmptyAttendance weekStartDate={weekStartDate} day={day} />
							  ))}
					</div>
				</CustomCard>
			)}
			<ToastContainer />
		</>
	);
});

export default WeekDateRange;
