// TODO new upcoming code
import React, {ChangeEvent, useEffect, useState} from 'react'
import useAuthenticatedUsers from "../../hooks/useAuthenticatedUsers";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import moment from "moment-timezone";
import {ReactComponent as CalendarIcon} from "../../assets/course-calandar.svg";
import {ReactComponent as TimeIcon} from "../../assets/course-watch.svg";
import {ReactComponent as ProgressIcon} from "../../assets/progress.svg";
import {ReactComponent as YogaFlower} from "../../assets/yoga_flower.svg";
import {ReactComponent as YogaStar} from "../../assets/yoga_star.svg";
import {ReactComponent as Pulmonology} from "../../assets/pulmonology.svg";


import {
    checkUserEnrolledToCourse,
    getCourseHomework,
} from "../../services/Firebase";

import {storage} from "../../services/Firebase";
import './UpcommingCourseClass.css'
import {
    isEmpty,
} from "../../utils/utils";
import {Mixpanel} from "../../analytics/Mixpanel";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import {Card, Spinner} from "react-bootstrap";
import {toast, ToastContainer} from "react-toastify";
import Loader from "../../components/loader/Loader";
import {COLORS} from "../../utils/colors";
import {useSelector} from "react-redux";
import {getDownloadURL, ref} from "firebase/storage";
import play_icon from "../../assets/play-btn.svg";
import {events_action} from "../../analytics/Events";
import CourseNotEnrolled from "./components/CourseNotEnrolled";
import BestOutOfItIconWithName from "../../components-v2/rows/BestOutOfItIconWithName";
import LoginModal from "../../components-v2/models/LoginModel";
import useLoginHook from "../../hooks/useLogin";
import LoginSuccessModal from "../../components-v2/models/LoginSucessModel";
import LoginFailureModal from "../../components-v2/models/LoginFailureModel";
import KnowYourInstructor from "../instructor/KnowYourInstructor";
import ErrorComponent from "../../components/error/Error";

interface TriggeredEvents {
    [key: string]: boolean;
}

const sectionRanges = {
    section1: {start: 0, end: 220, section_heading: 'Course Class Landing Video'},
    section2: {start: 220, end: 300, section_heading: 'Course Class Header'},
    section3: {start: 300, end: 500, section_heading: 'Highlights'},
    section4: {start: 500, end: 800, section_heading: 'How to get the best of this class?'},
    section5: {start: 800, end: 1700, section_heading: 'Know your Instructor'},
    // Add other sections as needed
} as { [key: string]: { start: number; end: number; section_heading: string } };


export enum STATES {
    LOADING,
    COURSE_DESCRIPTION_PAGE,
    VIMEO_PLAYER_PAGE,
    COURSE_LOGIN_PAGE,
    NOT_ENROLLED_PAGE,

}


const CourseHomework = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [searchParams] = useSearchParams();
    const homeworkId = searchParams.get("homeworkId");
    const [calendarItem, setCalendarItem] = useState<any>();
    const [loader, setLoader] = useState(false);
    const [innerLoader, setInnerLoader] = useState(false)
    const [showPlayer, setShowPlayer] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [state, setState] = useState<STATES>(STATES.COURSE_DESCRIPTION_PAGE);
    const [bestOutOfIt, setBestOutOfIt] = useState(['Use wireless headphones', 'Avoid eating within 2 hours before practicing', 'Kundalini Yoga includes exercises, so do what feels comfortable for you', 'Find a quiet space where you are not disturbed', 'Use yoga mat', 'Keep a glass of water']);
    const [phone, setPhone] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const user = useSelector((state: any) => state?.auth?.user?.data)
    const userData = useAuthenticatedUsers(true)
    const [showOtpPopup, setShowOtpPopup] = useState(false);

    const onClickLogin = (phone: string) => {
        Mixpanel.track('click_join_session_login_popup', {
            event_page: 'course_homework_details',
            event_action: events_action.clicked,
            // email: email,
            phone: phone,
            course_homework_name: calendarItem?.subtitle,
        })
        onLoginSubmitHandler(phone).then();
    }

    const onLoginSuccess = (loginResult: any) => {
        Mixpanel.track('login_successful', {
            event_page: 'course_homework_details',
            event_action: events_action.viewed,
            phone: phone,
            course_homework_name: calendarItem?.subtitle,
        })
        // TODO check if user is enrolled in the course or not after login
        // if (userLoginResult?.userData?.userEnrolledToCourse) {
        //     setShowLoginPopup(false);
        //     setLoader(false)
        //     // setState(STATES.VIMEO_PLAYER_PAGE);
        //     setShowPlayer(true);
        //     Mixpanel.track('view_course_homework_video', {
        //         event_action: 'viewed',
        //         event_page: 'course_homework_details',
        //         course_homework_name: calendarItem?.subtitle,
        //         course_id: calendarItem?.courseId,
        //         email: user?.email,
        //         phone: user?.phone,
        //     });
        // } else {
        //     setShowLoginPopup(false);
        //     setLoader(false)
        //     setState(STATES.NOT_ENROLLED_PAGE);
        //
        // }

    }

    const onLoginFailure = (loginResult: any) => {
        Mixpanel.track('login_failed', {
            event_page: 'course_homework_details',
            event_action: events_action.viewed,
            phone: phone,
            course_homework_name: calendarItem?.subtitle,
        })
        setState(STATES.NOT_ENROLLED_PAGE);
    }

    const {
        isLoginSuccessful,
        isLoginFailure,
        setIsLoginSuccessful,
        setIsLoginFailure,
        onLoginSubmitHandler,
        onOtpVerifyHandler,
    } = useLoginHook({page:'course_homework_details',setLoader, setError, setShowOtpPopup, setShowLoginPopup, onLoginSuccess, onLoginFailure});


    const formattedDate = moment(new Date(calendarItem?.streaming_date?._seconds * 1000 + calendarItem?.streaming_date?._nanoseconds / 1000000)).format("DD MMM YY");
    const targetDate = new Date(formattedDate);

    const currentDate = new Date();
    // const [bestOutOfIt, setBestOutOfIt] = useState(['Use Headphones', 'Find a quiet space where you are not disturbed', 'Use Yoga Mat', 'Keep a Glass of Water']);
    const [specialInstruction, setSpecialInstruction] = useState<any>(null);
    const parsedBio = calendarItem?.instructorData?.bio?.split('/');
    const [readMore, setReadMore] = useState(false);

    useEffect(() => {
        const modifiedValue = calendarItem?.how_to_get_the_best_of_this_class?.list_item_one;
        if (modifiedValue) {
            // setBestOutOfIt(prevArray => [...prevArray, modifiedValue]);
            setSpecialInstruction(modifiedValue);
        }
    }, [calendarItem?.how_to_get_the_best_of_this_class?.list_item_one])


    targetDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    const [instructorUrl, setInstructorUrl] = useState('');
    const [triggeredEvents, setTriggeredEvents] = useState<TriggeredEvents>({
        section1: false,
        section2: false,
        section3: false,
        section4: false,
        section5: false,
    });

    useEffect(() => {
        (async () => {
            try {
                const imageRef = ref(storage, calendarItem?.thumbnail);
                const url = await getDownloadURL(imageRef);
                setImageUrl(url);
                const instructorImageRef = ref(storage, calendarItem?.instructorData?.photo);
                const instructorUrl = await getDownloadURL(instructorImageRef);
                setInstructorUrl(instructorUrl);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        })();
    }, [calendarItem]);


    useEffect(() => {
        setLoader(true)
        getCourseHomework(homeworkId)
            .then((result: any) => {
                setCalendarItem(result.data);
                // console.log("getCourseHomework", result.data);
                setLoader(false)
            })
            .catch((err: any) => {
                console.log("getUpcomingClasses err", err);
            });
    }, []);


    const onBackArrowClick = () => {
        try {
            setState(prevState => {
                switch (prevState) {
                    case STATES.NOT_ENROLLED_PAGE:
                        return STATES.COURSE_DESCRIPTION_PAGE;
                    case STATES.VIMEO_PLAYER_PAGE:
                        return STATES.COURSE_DESCRIPTION_PAGE;
                    default:
                        return prevState;
                }
            });
        } catch (e) {

        }
    }

    const markMixPanelEvent = (position: number) => {
        const checkAndTriggerEvent = (sectionKey: string, start: number, end: number) => {
            if (!triggeredEvents[sectionKey] && position >= start - 10 && position <= end + 10) {
                Mixpanel.track('view_course_homework_details_sections', {
                    event_action: 'viewed',
                    event_section: sectionKey,
                    section_heading: sectionRanges[sectionKey].section_heading,
                    event_page: 'course_homework_details',
                    course_homework_name: calendarItem?.subtitle,
                });
                setTriggeredEvents((prevEvents: any) => ({...prevEvents, [sectionKey]: true}));
            }
        };

        Object.entries(sectionRanges).forEach(([sectionKey, {start, end}]) => {
            checkAndTriggerEvent(sectionKey, start, end);
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            markMixPanelEvent(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [triggeredEvents]);


    const enrollNow = async () => {
        if (state === STATES.NOT_ENROLLED_PAGE) {
            Mixpanel.track('click_enroll_now', {
                event_page: 'not_enrolled_in_course_homework',
                event_action: events_action.clicked,
                course_homework_name: calendarItem?.subtitle,
                course_id: calendarItem?.courseId,
            })
        } else {
            Mixpanel.track('click_enroll_now', {
                event_page: 'course_homework_login_popup',
                event_action: events_action.clicked,
                course_homework_name: calendarItem?.subtitle,
                course_id: calendarItem?.courseId,

            })
        }


        navigate('/upcoming-course', {
            state: {
                // courseItem,
                // daysToExpire,
                user,
                // isWithinStreamingWindow,
                // activeStreamingPlan
            }
        });
    }


    // useEffect(() => {
    //     if (!user && userData?.email) {
    //         loginUser(userData?.email, userData?.phone).then((userLoginResult) => {
    //             setUser(userLoginResult?.userData)
    //         })
    //     }
    // }, [userData])

    const navigate = useNavigate();


    const handlePhoneChange = (value: string, country: string, e: ChangeEvent<HTMLInputElement>, formattedValue: string): void => {
        setError('')
        setPhone(value);
    };

    const closeErrorPopup = () => {
        setError('');
    }


    const closeModal = () => {
        setIsLoginSuccessful(false);
        setIsLoginFailure(false);
    };


    const onSignUpClickHandler = () => {
        setLoader(false);
        setShowLoginPopup(false);
        Mixpanel.track('click_enroll_now', {
            event_page: 'login_page',
            event_action: events_action.clicked,
        })
        try {
            navigate('/onboarding')
        } catch (e) {

        }
    }

    const onPlayIconClickHandler = async () => {
        if (isEmpty(user) && !userData) {
            // TODO user not authenticated, open login popup
            Mixpanel.track('click_course_homework_full_screen', {
                event_action: 'clicked',
                event_page: 'course_homework_details',
                course_homework_name: calendarItem?.subtitle,
            });

            Mixpanel.track('view_login_popup_course_homework', {
                event_action: 'viewed',
                event_page: 'course_homework_details',
                course_homework_name: calendarItem?.subtitle,
            });
            setShowLoginPopup(true)


        } else {
            setInnerLoader(true)
            Mixpanel.identify(user?.uid || userData?.uid)
            Mixpanel.track('click_course_homework_full_screen', {
                event_action: 'clicked',
                event_page: 'course_homework_details',
                course_homework_name: calendarItem?.subtitle,
            });

            checkUserEnrolledToCourse(user?.uid || userData?.uid, calendarItem?.courseId)
                .then((result: any) => {
                    // console.log("Api call checkUserEnrolledToCourse", result);
                    // TODO if user enrolled to course ,open viemo player
                    if (result?.status === 200) {
                        // setState(STATES.VIMEO_PLAYER_PAGE);
                        setShowPlayer(true);
                        Mixpanel.track('view_course_homework_video', {
                            event_action: 'viewed',
                            event_page: 'course_homework_details',
                            course_homework_name: calendarItem?.subtitle,
                            course_id: calendarItem?.courseId,
                            email: user?.email,
                            phone: user?.phone,
                        });
                    } else {
                        setState(STATES.NOT_ENROLLED_PAGE);
                    }

                })
                .catch((err: any) => {
                    // TODO if user not enrolled to course , show not enrolled page
                    setState(STATES.NOT_ENROLLED_PAGE)
                    // console.log("getUpcomingClasses err", err);
                }).finally(() => {
                setInnerLoader(false)
            });
        }
    };
    const readMoreHandler = () => {
        Mixpanel.track('read_more', {
            event_action: 'clicked',
            event_page: 'course_modules_details',
            course_homework_name: calendarItem?.subtitle,
        });
        setReadMore(prevState => !prevState);
    }

    if (loader) {
        return <Loader/>
    } else if (state === STATES.NOT_ENROLLED_PAGE) {
        Mixpanel.track('view_not_enrolled_screen', {
            event_action: 'viewed',
            event_page: 'not_enrolled_in_course_homework',
            course_homework_name: calendarItem?.subtitle,
            course_id: calendarItem?.courseId,
        });
        return (<CourseNotEnrolled onEnrollNowHandler={enrollNow} onBackArrowClick={onBackArrowClick}/>
        )
    }
    return (
        <div className="course-description-container"
             style={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'center',
                position: 'relative',
                marginRight: '-16px',
            }}>
                {
                    showPlayer && <div style={{width: '100vw', height: '232px', padding: '0', position: 'relative'}}>
                        <iframe
                            src={`https://player.vimeo.com/video/${calendarItem?.videoId}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                            // src={`https://player.vimeo.com/video/${calendarItem?.videoId}?h=f403fad7cf`}
                            frameBorder="0" allow="autoplay; fullscreen"
                            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                            title="Class 24th Jan"></iframe>
                    </div>
                }

                {imageUrl && !showPlayer &&
                    <LazyLoadImage src={imageUrl} alt="User" className={"course-image-banner"}/>}
                {innerLoader && <Spinner color={COLORS.PRIMARY} style={{
                    width: '32px',
                    height: '32px',
                    color: COLORS.PRIMARY,
                    position: 'absolute',
                    top: '45%'
                }}/>}
                {!innerLoader && !showPlayer && <img
                    onClick={onPlayIconClickHandler}
                    src={play_icon}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '32px',
                        height: '32px',
                    }}
                    alt="Play Icon"
                />}
            </div>


            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                width: '344px',
                alignSelf: 'center',
                justifyContent: 'space-between',
                marginLeft: '16px',
                // marginRight: '16px'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 3
                }}>
                     <span style={{
                         color: '#2A2A2A',
                         fontFamily: 'Lora-Medium',
                         width: '264px',
                         fontSize: '20px',
                         fontStyle: 'normal',
                         fontWeight: 500,
                         marginTop: '12px',
                         lineHeight: 'normal',
                         flex: 2
                     }}>{calendarItem?.title}</span>
                </div>
                <span className={'course-number-of-enrollment'}>{calendarItem?.subtitle}</span>
                <span style={{
                    color: '#2A2A2A',
                    fontFamily: 'Poppins-Regular',
                    fontSize: '12px',
                    fontWeight: 400,
                    alignSelf: 'start',
                    marginTop: '6px',
                    lineHeight: 'normal'
                }}>{calendarItem?.instructor}</span>

                <div className={'upcoming-course-subheader-container'}>
                    <div className={'upcoming-course-subheader-subContainer'}>
                        <CalendarIcon width={32} height={32}/>
                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: '4px', alignSelf: 'start',}}>
                            <span className={'upcoming-course-subheader-title'}
                                  style={{marginLeft: '-12px', marginTop: '4px'}}>{calendarItem?.module_no}</span>
                            <span className={'upcoming-course-subheader-subtitle'}>{'Module no.'}</span>
                        </div>

                    </div>
                    <div style={{width: '3px', height: '40px', background: '#ECEFF2'}}/>
                    <div className={'upcoming-course-subheader-subContainer'}>
                        <TimeIcon width={32} height={32}/>
                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: '4px'}}>
                            <span
                                className={'upcoming-course-subheader-title'}>{`${calendarItem?.duration} Mins`}</span>
                            <span className={'upcoming-course-subheader-subtitle'}>{'Duration'}</span>
                        </div>
                    </div>
                    <div style={{width: '3px', height: '40px', background: '#ECEFF2'}}/>
                    <div className={'upcoming-course-subheader-subContainer'}>
                        <ProgressIcon width={32} height={32}/>
                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: '4px', alignSelf: 'start'}}>
                            <span className={'upcoming-course-subheader-title'}>{'Beginner'}</span>
                            <span className={'upcoming-course-subheader-subtitle'}>{'Level'}</span>
                        </div>
                    </div>
                </div>

                {calendarItem?.description?.split(' ')?.length > 30 ? <p style={{
                    display: 'flex',
                    fontFamily: 'Poppins-Regular',
                    fontSize: '14px',
                    fontWeight: '400',
                    width: '343px',
                    marginTop: specialInstruction ? '0px' : '12px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}>
                    {readMore ? calendarItem?.description : calendarItem?.description?.split(' ').slice(0, 25).join(' ') + '...'}
                </p> : <p style={{
                    display: 'flex',
                    fontFamily: 'Poppins-Regular',
                    fontSize: '14px',
                    fontWeight: '400',
                    width: '343px',
                    marginTop: specialInstruction ? '0px' : '12px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}>
                    {calendarItem?.description}
                </p>}

                {calendarItem?.description?.split(' ')?.length > 30 &&
                    <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'start', marginTop: '-8px',}}>
                     <span onClick={readMoreHandler} style={{
                         display: 'flex',
                         color: '#534DE0',
                         textDecorationLine: 'underline',
                         fontFamily: 'Poppins-Medium',
                         fontWeight: '500',
                         lineHeight: '16px',
                         fontSize: '10px',
                         flexDirection: 'column',
                         alignItems: 'center',
                         justifyContent: 'flex-start'
                     }}>{readMore ? "Read Less " : "Read More"}
                </span>
                        {readMore ?
                            <svg style={{alignSelf: 'center', marginTop: '-4px'}} xmlns="http://www.w3.org/2000/svg"
                                 width="24" height="24" viewBox="0 0 20 20"
                                 fill="none">
                                <path
                                    d="M10.0002 9.00003L13.4202 12.141C13.5503 12.2699 13.7261 12.3422 13.9092 12.3422C14.0923 12.3422 14.2681 12.2699 14.3982 12.141C14.4625 12.0778 14.5135 12.0023 14.5483 11.9192C14.5831 11.836 14.6011 11.7467 14.6011 11.6565C14.6011 11.5664 14.5831 11.4771 14.5483 11.3939C14.5135 11.3107 14.4625 11.2353 14.3982 11.172L10.4902 7.34203C10.3598 7.21336 10.1839 7.14121 10.0007 7.14121C9.81749 7.14121 9.64164 7.21336 9.51121 7.34203L5.60321 11.172C5.53896 11.2353 5.48794 11.3107 5.45311 11.3939C5.41828 11.4771 5.40035 11.5664 5.40035 11.6565C5.40035 11.7467 5.41828 11.836 5.45311 11.9192C5.48794 12.0023 5.53896 12.0778 5.60321 12.141C5.73335 12.2699 5.90908 12.3422 6.09221 12.3422C6.27534 12.3422 6.45108 12.2699 6.58121 12.141L10.0002 9.00003Z"
                                    fill="#534DE0"/>
                            </svg>
                            : <svg style={{alignSelf: 'center', marginTop: '-4px'}} xmlns="http://www.w3.org/2000/svg"
                                   width="24" height="24"
                                   viewBox="0 0 20 20" fill="none">
                                <path
                                    d="M10.0002 11L13.4202 7.85897C13.5503 7.73012 13.7261 7.65784 13.9092 7.65784C14.0923 7.65784 14.2681 7.73012 14.3982 7.85897C14.4625 7.92224 14.5135 7.99766 14.5483 8.08084C14.5831 8.16402 14.6011 8.2533 14.6011 8.34347C14.6011 8.43365 14.5831 8.52292 14.5483 8.6061C14.5135 8.68928 14.4625 8.7647 14.3982 8.82797L10.4902 12.658C10.3598 12.7866 10.1839 12.8588 10.0007 12.8588C9.81749 12.8588 9.64164 12.7866 9.51121 12.658L5.60321 8.82797C5.53896 8.7647 5.48794 8.68928 5.45311 8.6061C5.41828 8.52292 5.40035 8.43365 5.40035 8.34347C5.40035 8.2533 5.41828 8.16402 5.45311 8.08084C5.48794 7.99766 5.53896 7.92224 5.60321 7.85897C5.73335 7.73012 5.90908 7.65784 6.09221 7.65784C6.27534 7.65784 6.45108 7.73012 6.58121 7.85897L10.0002 11Z"
                                    fill="#534DE0"/>
                            </svg>}
                    </div>}


                {calendarItem?.highlights && <span className={'upcoming-course-highlights'}>{'Highlights'}</span>}
                {calendarItem?.highlights && calendarItem?.highlights?.map((item: { key: string }, index: number) => (
                    <BestOutOfItIconWithName name={item?.key} index={index}/>
                ))}


                {calendarItem?.session_info?.[0]?.key && <Card
                    style={{
                        flexDirection: 'row',
                        backgroundColor: '#F7EEFF',
                        marginTop: '12px',
                        paddingBottom: '-10px',
                        boxShadow: "none",
                        border: 'none',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '12px',
                    }}>
                    <YogaStar width={40} height={40}
                              style={{marginRight: '8px', marginLeft: '12px', alignSelf: 'center'}}/>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <p className={'upcoming-course-target-title'}>Movement/Kriyas</p>
                        <p className={'upcoming-course-target-subtitle'}>{calendarItem?.session_info?.[0]?.key}</p>
                    </div>
                </Card>}


                {calendarItem?.session_info?.[1]?.key && <Card
                    style={{
                        flexDirection: 'row',
                        backgroundColor: '#FFFAEC',
                        marginTop: '12px',
                        paddingBottom: '-10px',
                        boxShadow: "none",
                        border: 'none',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '12px',
                    }}>
                    <Pulmonology width={40} height={40}
                                 style={{marginRight: '8px', marginLeft: '12px', alignSelf: 'center'}}/>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <p className={'upcoming-course-target-title'}>Breathworks</p>
                        <p className={'upcoming-course-target-subtitle'}>{calendarItem?.session_info?.[1]?.key}</p>
                    </div>
                </Card>}


                {calendarItem?.session_info?.[2]?.key && <Card
                    style={{
                        flexDirection: 'row',
                        backgroundColor: '#E9F6FF',
                        marginTop: '12px',
                        paddingBottom: '-10px',
                        boxShadow: "none",
                        border: 'none',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '16px',
                    }}>
                    <YogaFlower width={40} height={40}
                                style={{marginRight: '8px', marginLeft: '12px', alignSelf: 'center'}}/>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <p className={'upcoming-course-target-title'}>Meditation</p>
                        <p className={'upcoming-course-target-subtitle'}>{calendarItem?.session_info?.[2]?.key}</p>
                    </div>
                </Card>}

                <div style={{marginTop: '24px'}}>
                    <span style={{
                        color: '#1A1A1A',
                        fontFamily: 'Lora-Regular',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        alignSelf: 'start',
                        lineHeight: '20px',
                        letterSpacing: '0.36px'
                    }}>How to get the best of this class?</span>

                    {bestOutOfIt.filter(value => value !== null).map((value, index) => (
                        <BestOutOfItIconWithName key={index} index={index} name={value}/>
                    ))}
                </div>
                <KnowYourInstructor calendarItem={calendarItem}/>
            </div>

            <LoginFailureModal
                isLoginFailure={isLoginFailure}
                closeModal={closeModal}
                onSignUpClickHandler={onSignUpClickHandler}
            />

            <LoginModal
                page={'course_homework_details'}
                setError={setError}
                showEnrollNowCta={false}
                onEnrollNowClickHandler={onSignUpClickHandler}
                onOtpVerifyHandler={onOtpVerifyHandler}
                showOtpPopup={showOtpPopup}
                setShowOtpPopup={setShowOtpPopup}
                showLoginPopup={showLoginPopup}
                setShowLoginPopup={setShowLoginPopup}
                error={error}
                closeErrorPopup={closeErrorPopup}
                phone={phone}
                handlePhoneChange={handlePhoneChange}
                onLoginSubmitHandler={onClickLogin}
                customInputStyle={{ /* Your custom styles here */}}
            />

            <ToastContainer/>
        </div>
    )
}

export default CourseHomework;

