import React, {useCallback, useEffect, useState} from 'react'
import {Container} from "react-bootstrap";
import './CheckoutPage.css'
import '../../../styles.css'
import moment from "moment-timezone";
import Timer from "../../../timer/Timer";
import {STREAMING_PLAN_RECURRENCE_TYPE} from "../../../utils/utils";
import {ReactComponent as OfferIcon} from "../../../assets/offer.svg";
import {Mixpanel} from "../../../analytics/Mixpanel";
import PausePlanModel from "../../../components-v2/models/PausePlanModel";

interface CheckoutPageProps {
    total_karma: number,
    payNowClickHandler: (price: number,name:any) => void,
    onBackArrowClick: () => void,
    selectedPlanVariant: any,
    isCommunityMember: boolean,
    isUserLogin:boolean;
}

const CheckoutPage: React.FC<CheckoutPageProps> = ({
                                                       isUserLogin,
                                                       total_karma,
                                                       payNowClickHandler,
                                                       onBackArrowClick,
                                                       selectedPlanVariant,
                                                       isCommunityMember
                                                   }) => {

    const currentDate = moment().format('DD MMM YYYY');
    const endDate = moment(currentDate);

    const [showPauseBanner, setShowPauseBanner] = useState(false);


    useEffect(()=>{
        if(showPauseBanner){
            Mixpanel.track('view_pause_membership_info', {
                event_action: 'viewed',
                event_page: 'checkout_page',
            });
        }
    },[showPauseBanner])


    if (selectedPlanVariant.type === STREAMING_PLAN_RECURRENCE_TYPE.MONTHLY) {
        endDate.add(1, 'months');
    } else if (selectedPlanVariant.type === STREAMING_PLAN_RECURRENCE_TYPE.QUARTERLY) {
        endDate.add(3, 'months');
        // endDate.add(1, 'months'); // add 1 month ,offer extension
    } else if (selectedPlanVariant.type === STREAMING_PLAN_RECURRENCE_TYPE.YEARLY) {
        endDate.add(1, 'year');
        // endDate.add(2, 'months'); // add 2 months ,offer extension
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const openWhatsappHelp = useCallback(() => {
        const link = 'https://api.whatsapp.com/send?phone=919555434844';
        try {
            window.open(link, '_blank');
        } catch (e: any) {
            console.error('Error:', e.message);
            // Handle the error as needed
        }
    }, []);


    let price = 0;
    if (isCommunityMember) {
        price = selectedPlanVariant.community_total_price;
    } else {
        price = selectedPlanVariant.total_price;
    }



    return (
        <Container className={'checkout-container'}>
            <span className={'checkout-details'}>Plan Details</span>
            <div style={{background: '#F0F2FE', borderRadius: '12px', marginTop: '12px',width:'95%', maxWidth:'400px', padding: '12px'}}>

                <div className={'checkout-plan-details-container'}>
                    <span className={'checkout-plan-start-date'}>{'Plan Name'}</span>
                    <span className={'checkout-plan-start-date-value'}>{`${selectedPlanVariant?.name?.split(' ')?.[0]} Months`}</span>
                </div>

                <svg style={{marginTop:'-12px',marginBottom:'-12px'}} xmlns="http://www.w3.org/2000/svg" width="321" height="2" viewBox="0 0 321 2" fill="none">
                    <path d="M1 1H320" stroke="#E4E4E4" stroke-width="0.64" stroke-linecap="round" stroke-dasharray="4 4"/>
                </svg>
                <div className={'checkout-plan-details-container'}>
                    <span className={'checkout-plan-start-date'}>{'Starting Date'}</span>
                    <span className={'checkout-plan-start-date-value'}>{`${currentDate}`}</span>
                </div>
                <svg style={{marginTop:'-12px',marginBottom:'-12px'}} xmlns="http://www.w3.org/2000/svg" width="321" height="2" viewBox="0 0 321 2" fill="none">
                    <path d="M1 1H320" stroke="#E4E4E4" stroke-width="0.64" stroke-linecap="round" stroke-dasharray="4 4"/>
                </svg>
                <div className={'checkout-plan-details-container'}>
                    <span className={'checkout-plan-start-date'}>{'Ending Date'}</span>
                    <span className={'checkout-plan-start-date-value'}>{`${endDate.format('DD MMM YYYY')}`}</span>
                </div>
            </div>


            {/*{selectedPlanVariant?.extra_offer &&*/}
            {/*    <span className={'checkout-details'} style={{marginTop: '32px'}}>Offers Applied</span>}*/}
            {/*{selectedPlanVariant?.free_extension && <div style={{*/}
            {/*    flexDirection: 'row',*/}
            {/*    display: 'flex',*/}
            {/*    width: '95%',*/}
            {/*    maxWidth:'400px',*/}
            {/*    borderRadius: '8px',*/}
            {/*    // border: '1.6px solid #E4E4E4',*/}
            {/*    background: '#FFF',*/}
            {/*    justifyContent: 'start',*/}
            {/*    marginTop: '16px'*/}
            {/*}}>*/}
            {/*    <OfferIcon height={24} width={24}></OfferIcon>*/}
            {/*    <div className={"checkout-plan-offer"}>{`Free ${selectedPlanVariant?.free_extension} extra`}</div>*/}
            {/*</div>}*/}

            {/*{selectedPlanVariant?.extra_offer && <div style={{*/}
            {/*    flexDirection: 'row',*/}
            {/*    display: 'flex',*/}
            {/*    width: '95%',*/}
            {/*    maxWidth:'400px',*/}
            {/*    borderRadius: '8px',*/}
            {/*    // border: '1.6px solid #E4E4E4',*/}
            {/*    background: '#FFF',*/}
            {/*    justifyContent: 'start',*/}
            {/*    // alignItems:'center',*/}
            {/*    marginTop: '16px'*/}
            {/*}}>*/}
            {/*    <OfferIcon height={24} width={24}></OfferIcon>*/}
            {/*    <div*/}
            {/*        className={"checkout-plan-offer"}>{`${selectedPlanVariant?.pause_limit} Days of membership pause`}</div>*/}
            {/*    <div style={{*/}
            {/*        display: "flex",*/}
            {/*        width: "20px",*/}
            {/*        height: "20px",*/}
            {/*        padding: "10px",*/}
            {/*        marginLeft: '4px',*/}
            {/*        justifyContent: "center",*/}
            {/*        alignItems: "center",*/}
            {/*        gap: "10px",*/}
            {/*        alignSelf: 'center',*/}
            {/*        position: 'relative',*/}
            {/*        borderRadius: "10px",*/}
            {/*        border: "0.64px solid var(--purple-gradient, #534DE0)"*/}
            {/*    }}>*/}
            {/*        <svg onClick={setShowPauseBanner.bind(null, true)} style={{position: 'absolute'}}*/}
            {/*             xmlns="http://www.w3.org/2000/svg" width="13" height="12"*/}
            {/*             viewBox="0 0 13 12" fill="none">*/}
            {/*            <path*/}
            {/*                d="M7.25 10.5001V4.5001H5V4.8751H5.75V10.5001H5V10.8751H8V10.5001H7.25ZM6.49531 3.38682C7.11875 3.38682 7.625 2.88057 7.625 2.25713C7.625 1.63369 7.11875 1.12744 6.49531 1.12744C5.87188 1.12744 5.36563 1.63369 5.36563 2.25713C5.36563 2.88057 5.87188 3.38682 6.49531 3.38682Z"*/}
            {/*                fill="#534DE0"/>*/}
            {/*        </svg>*/}
            {/*    </div>*/}
            {/*</div>}*/}

            {/*<PausePlanModel setShowPauseBanner={setShowPauseBanner} showPauseBanner={showPauseBanner}/>*/}
            {/*{selectedPlanVariant?.extra_offer && <Timer/>}*/}
            <div style={{marginBottom: '132px'}}></div>
            <div className="plan-fixed-bottom-container" style={{paddingBottom:'12px'}}>
                <span className="plan-fixed-bottom-price">{'₹' + price}</span>
                <button className="plan-fixed-bottom-button " onClick={payNowClickHandler.bind(null, price,selectedPlanVariant?.name)}>
                    {isUserLogin?'Pay Now':'Login & Pay'}
                </button>
            </div>
        </Container>
    )
}

export default CheckoutPage
