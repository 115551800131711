import React, {memo, useEffect, useState} from 'react'
import "./TestimonialCard.css"

import moment from "moment-timezone";

import { ref, getDownloadURL } from "firebase/storage"
import CustomCard from "../../../components/card/CustomCard";
import {storage} from "../../../services/Firebase";
import ReactPlayer from "react-player";
import {ReactComponent as Mute} from "../../../assets/mute.svg";
import {ReactComponent as UnMute} from "../../../assets/unmute.svg";


interface TestimonialCardProps {
    index:number;
    name:string,
    location:any,
    videoUrl:string;
    selectedIndex:number;
    playingVideo:boolean
    onVideoPlayPause:(index:number,name:string) => void;
}

const TestimonialCard: React.FC<TestimonialCardProps> = memo(({videoUrl, name, location, selectedIndex,playingVideo,index,onVideoPlayPause}) => {

    return (
        <div className={'testimonial-card-container'} onClick={onVideoPlayPause.bind(null,index,name)}>
            <ReactPlayer controls={false}
                         playing={playingVideo}
                         autoplay
                         // muted
                         loop={true}
                         playsinline={true}
                         fileConfig={{ attributes: { autoPlay: true } }}
                         muted={selectedIndex != index}
                         height={'164px'} width={'164px'} url={videoUrl}/>
            {selectedIndex != index ? <Mute width={24} height={24}
                                    style={{position: 'absolute', top: '16px', right: '16px', color: 'white'}}/> :
                <UnMute width={24} height={24}
                        style={{position: 'absolute', top: '16px', right: '16px', color: 'white'}}/>}
            <div className={'testimonial-customer-name'}>{name}</div>
            <div className={'testimonial-customer-location'}>{location}</div>
        </div>
    );
});

export default TestimonialCard;


