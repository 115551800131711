import React from 'react';
import './Shimmer.css';

interface ShimmerProps {
    style?: React.CSSProperties;
    classname?:string,
}

const Shimmer: React.FC<ShimmerProps> = ({ style,classname }) => {
    return (
        <div className={`shimmer-wrapper ${classname}`} style={style}>
            <div className="shimmer"></div>
        </div>
    );
};

export default Shimmer;
