import React, { CSSProperties, useEffect, useState, useRef, useCallback } from 'react';
import './LiveClassCard.css';
import Image from "react-bootstrap/Image";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import HorizontalChipWithIconAndTitle from "../chips/HorizontalChipWithIconAndTitle";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../services/Firebase";
import ShimmerLoader from "../shimmer/Shimmer";
import { Mixpanel } from "../../analytics/Mixpanel";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import {isEmpty} from "../../utils/utils";
import {useDispatch, useSelector} from "react-redux";
import {updateEventsStatus} from "../../store/features/EventsSlices";

interface LiveClassCardProps {
    imageUrl: string;
    title: string;
    subtitle: string;
    primaryButtonText?: string;
    secondaryButtonText?: string;
    customStyle?: CSSProperties;
    icons?: Array<{
        icon?: any;
        title?: string;
    }>;
    tag?: string;
    isLocked?: boolean;
    onLiveClassClickHandler: (classData: any) => void;
    classData: any;
    chipContainerCustomStyle?: CSSProperties;
}

const LiveClassCard: React.FC<LiveClassCardProps> = React.memo(({
                                                                    chipContainerCustomStyle,
                                                                    onLiveClassClickHandler,
                                                                    classData,
                                                                    isLocked,
                                                                    tag,
                                                                    imageUrl,
                                                                    title,
                                                                    subtitle,
                                                                    primaryButtonText,
                                                                    secondaryButtonText,
                                                                    icons,
                                                                    customStyle
                                                                }) => {

    const [thumbnailUrl, setThumbnailUrl] = useState<string>('');
    const thumbnailUrlRef = useRef<string | null>(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const view_upcoming_classes = useSelector((state: any) => state?.events?.eventsLog?.view_upcoming_classes);
    const logViewEvent = useCallback(() => {
        if (!view_upcoming_classes) {
            Mixpanel.track('view_upcoming_classes', {
                event_action: 'viewed',
                event_page: 'live_classes',
            });
            dispatch(updateEventsStatus({key:'view_upcoming_classes',value:true}))
        }
    }, [view_upcoming_classes]);

    const cardRef = useIntersectionObserver(logViewEvent, { threshold: 0.5 });

    useEffect(() => {
        if (!thumbnailUrlRef.current) {
            (async () => {
                try {
                    const imageRef = ref(storage, imageUrl);
                    const url = await getDownloadURL(imageRef);
                    setThumbnailUrl(url);
                    thumbnailUrlRef.current = url;
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                }
            })();
        } else {
            setThumbnailUrl(thumbnailUrlRef.current);
            setLoading(false);
        }
    }, [imageUrl]);

    const handleClick = useCallback(() => {
        onLiveClassClickHandler(classData);
    }, [onLiveClassClickHandler, classData]);

    // if (loading) return <ShimmerLoader />;

    return (
        <div ref={cardRef} className="live-class-card-container" style={customStyle}>
            <div className="live-class-card-horizontal">
                <LazyLoadImage
                    className="live-class-card-image-container img-fluid"
                    src={thumbnailUrl}
                    alt={title}
                    effect="blur"
                    onLoad={()=><ShimmerLoader classname="course-card-image-placeholder" />}
                />
                <div className="live-class-card-content">
                    <span className="live-class-card-title">{title}</span>
                    <span className="live-class-card-subtitle">{subtitle}</span>
                </div>
            </div>

            {tag && <div className="top-left-content">{tag}</div>}

            {icons && (
                <div className="live-class-card-icons-container" style={chipContainerCustomStyle}>
                    {
                        icons?.map((iconItem:any, index:number) => {
                            if (isEmpty(iconItem)) return (
                                <div style={{width: '1px',alignSelf:'center',
                                    height: '16px',background: '#E4E4E4'}}/>)
                            else
                                return <HorizontalChipWithIconAndTitle
                                    customChipStyle={{ width: 'auto', alignSelf: 'center' }}
                                    key={index}
                                    title={iconItem.title}
                                    Icon={iconItem.icon}
                                />
                        })
                    }
                </div>
            )}
            <div className="live-class-card-buttons-container">
                {primaryButtonText && (
                    <PrimaryButton style={{borderRadius:'8px'}} onClick={handleClick} disabled={isLocked}>
                        {primaryButtonText}
                    </PrimaryButton>
                )}
                {secondaryButtonText && <SecondaryButton>{secondaryButtonText}</SecondaryButton>}
            </div>
        </div>
    );
});

export default LiveClassCard;
