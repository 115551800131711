import React from 'react';
import {ReactComponent as PaymentSuccess} from "../../assets/payment-success.svg";
import './PaymentSuccessScreen.css';
import {useLocation, useNavigate} from "react-router-dom";
import PrimaryButton from "../../components-v2/buttons/PrimaryButton";
import {Mixpanel} from "../../analytics/Mixpanel";
import {useSelector} from "react-redux";
import useAuthenticatedUsers from "../../hooks/useAuthenticatedUsers";


interface PaymentSuccessModalProps {
}

const PlanPaymentSuccess: React.FC<PaymentSuccessModalProps> = ({}) => {
        const userData = useAuthenticatedUsers(true);
        const location = useLocation();
        const selectedPlanVariant = location.state && location.state?.selectedPlanVariant;
        const user = useSelector((state: any) => state?.auth?.user?.data);
        const navigate = useNavigate();

        const onJoinWhatsAppsCommunityHandler = async () => {
            try {
                // TODO update course whatsapp group link
                let whatsappGroupLink = 'https://chat.whatsapp.com/GnKYkPjiPnV8WdePWtDw3s';
                Mixpanel.track('click_join_community', {
                    event_action: 'clicked',
                    event_page: 'payment_successful_screen',
                    context_user_id: user?.uid,
                    phone: userData?.phoneNumber || userData?.phone,
                });
                window.open(whatsappGroupLink, '_blank');
                navigate('/complete-your-profile');
            } catch (error) {
                console.error('Error:', error);
                // Handle the error as needed
            }
        };


        return (
            <div className="payment-success-container">
                <PaymentSuccess width={120} height={120}/>
                <span className="payment-success-hurray">Payment Successful 🎉</span>
                <span className="payment-success-plan-description">
                        You have successfully bought{' '}
                    {selectedPlanVariant ? <span>{selectedPlanVariant.name}</span> : 'a plan'}
                    </span>
                <div style={{width: '240px', height: '40px', alignSelf: 'center', display: 'flex', alignItems: 'center'}}>
                    <PrimaryButton
                        isSticky={false}
                        style={{
                            alignSelf: 'center',
                            height: '40px',
                            marginTop: '24px',
                            borderRadius: '8px',
                        }}
                        onClick={onJoinWhatsAppsCommunityHandler}
                    >Join WhatsApp Community</PrimaryButton>
                </div>

            </div>)
    }
;

export default PlanPaymentSuccess;
