import React, { useState, useEffect } from 'react';
import './CourseOtpForm.css';
// import '../../signup-form/Otp.css'
import Timer from '../../../components/countdownTimer/Timer';
import Success from '../../../components/success/Success';
import ErrorComponent from '../../../components/error/Error';
import { resendOtp } from '../../../services/Firebase';
import { Mixpanel } from '../../../analytics/Mixpanel';
import { events, events_action } from '../../../analytics/Events';
import { COLORS } from '../../../utils/colors';
import Loader from '../../../components/loader/Loader';
import PrimaryButton from '../../../components-v2/buttons/PrimaryButton';

import { ReactComponent as BackArrow } from '../../../assets/left_arrow_black.svg';
import { ReactComponent as SeekLogo } from '../../../assets/seek-logo.svg';
import InputOTP from '../../../components-v2/inputs/InputOTP';

const CourseOtpForm = ({
	mobileNumber,
	onOtpVerifyHandler,
	page,
	onBackArrowClick,
	otpError,
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [otpValues, setOtpValues] = useState([]);
	const [error, setError] = useState(otpError);
	const [isTimerOver, setIsTimerOver] = useState(false);
	const [otpResend, setOtpResend] = useState(false);
	const [initialTimer, setInitialTimer] = useState(60);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(false);
		setError(otpError);
	}, [otpError]);

	function onOTPVerify() {
		setLoading(true);
		setError('');
		Mixpanel.track('click_verify_otp', {
			event_page: 'otp_screen',
			page: 'course_register_with_seek',
			event_action: events_action.clicked,
			phone: mobileNumber,
		});
		if (otpValues?.length === 0) {
			setLoading(false);
			setError('Please enter the correct otp');
			Mixpanel.track('otp_incorrect', {
				page: page,
				event_page: 'otp_screen',
				event_action: events_action.clicked,
			});
			setTimeout(() => {
				setError(null);
			}, 2000);
			return;
		}
		onOtpVerifyHandler(Number(otpValues.join('')));
	}

	const onResendOtpHandler = () => {
		if (!isTimerOver) {
			return;
		}
		Mixpanel.track(events.click_resend_otp, {
			page: page,
			event_page: 'otp_screen',
			event_action: events_action.clicked,
		});
		setOtpResend(true);
		setTimeout(() => {
			setOtpResend(false);
		}, 2000);
		setInitialTimer(60);
		setIsTimerOver(false);
		resendOtp(mobileNumber).then();
	};

	const closeErrorPopup = () => {
		setError('');
	};

	return (
		<div className={'course-otp-container'}>
			{loading && <Loader />}
			{otpResend && !error && (
				<Success
					customStyles={{
						position: 'absolute',
						top: 10,
						left: 10,
						right: 10,
					}}
					message={`An OTP has been re-sent on the given mobile number ${mobileNumber}`}
				/>
			)}
			{error && (
				<ErrorComponent
					onClose={closeErrorPopup}
					customStyles={{
						position: 'absolute',
						justifyContent: 'flex-start',
						alignItems: 'center',
						top: 10,
						left: 10,
						right: 10,
					}}
					message={error}
				/>
			)}

			<div
				className={'header-container'}
				style={{
					width: '100%',
					marginTop: '25px',
					justifyContent: 'space-around',
				}}>
				<BackArrow
					style={{ marginLeft: '14px', marginTop: '6px' }}
					width={48}
					height={48}
					onClick={onBackArrowClick}
				/>
				<SeekLogo
					style={{
						alignSelf: 'center',
						width: '100%',
						marginTop: '2px',
						alignItems: 'center',
					}}
					width={108}
					height={44}
				/>
				<div></div>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					width: '100%',
					maxWidth: '400px',
					alignItems: 'center',
					marginTop: '24px',
				}}>
				<span className={'course-otp-title'}>Verify your number</span>
				<span className={'course-otp-subtitle'}>
					{`Please enter the 6-digit code sent on `}
					<span>{'+' + mobileNumber}</span>
				</span>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						marginTop: '32px',
					}}>
					<InputOTP setOtpValues={setOtpValues} otpValues={otpValues} />
					<span className={'course-otp-resend'}>
						Didn’t get the OTP?{' '}
						{isTimerOver ? (
							<span
								onClick={onResendOtpHandler}
								style={{
									marginLeft: '5px',
									fontSize: '14px',
									fontFamily: 'Gordita-Bold',
									fontWeight: '700',
									color: '#4462EF',
								}}>
								Resend It{' '}
							</span>
						) : (
							<span
								onClick={onResendOtpHandler}
								style={{
									marginLeft: '5px',
									fontSize: '14px',
									fontFamily: 'Gordita-Bold',
									fontWeight: '700',
								}}>
								Resend It{' '}
								<Timer
									onTimerComplete={setIsTimerOver.bind(null, true)}
									initialTime={initialTimer}
								/>
							</span>
						)}
					</span>
				</div>
			</div>
			<PrimaryButton
				isSticky={true}
				disabled={loading}
				style={{
					backgroundColor: loading ? COLORS.LIGHT_GREY : COLORS.PRIMARY,
					alignSelf: 'center',
					marginBottom: '24px',
					width: '327px',
					height: '50px',
				}}
				textStyle={{ fontSize: '16px' }}
				onClick={onOTPVerify}>
				{'Verify'}
			</PrimaryButton>
		</div>
	);
};

export default CourseOtpForm;
