import React, {useEffect, useState} from 'react'
import {getCurrentStreamingPlanByUserid, loginUser, verifyOtp} from "../../services/Firebase";
import {Mixpanel} from "../../analytics/Mixpanel";
import { useNavigate} from "react-router-dom";
import Loader from "../../components/loader/Loader";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch} from "react-redux";
import {addUsers} from "../../store/features/AuthSlice";
import { Testers} from "../../utils/utils";
import Otp from "../signup-form/Otp";
import LoginFrom from "./LoginFrom";
import {events, events_action, events_page} from "../../analytics/Events";
import dayjs from "dayjs";
import {
    updateDaysToExpire,
    updateDaysToExpireTrail,
    updateTotalClassesAttendedTillDate, updateUsersPlan
} from "../../store/features/PlanSlice";
import { get} from "../../services/AuthService";
import LoginFailureModal from "../../components-v2/models/LoginFailureModel";
import MasterLoginSheet from "../../components/model/MasterLoginSheet";
import ErrorComponent from "../../components/error/Error";
import moment from "moment-timezone";

export enum STATES {
    LOGIN_FORM,
    OTP,
    MASTER_LOGIN_FORM
}

function Login() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [state, setState] = useState<STATES>(STATES.LOGIN_FORM);
    const [phone, setPhone] = useState('');
    const [masterPhone, setMasterPhone] = useState('');
    const [error, setError] = useState<string>('');

    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
    const [isLoginFailure, setIsLoginFailure] = useState(false);
    const [remember, setRememberMe] = useState<boolean>(false);
    const dispatch = useDispatch();
    const userString = window.localStorage.getItem('user');

    useEffect(() => {
        setLoader(true)

        if (userString) {
            navigate('/home');
            setLoader(false)
        } else {
            setLoader(false)
        }
    }, [userString])



    useEffect(() => {
        try {
            Mixpanel.track(events.view_login_page, {
                event_page: events_page.login,
                event_action: events_action.viewed,
            })
        } catch (e) {

        }
    }, [])

    const onLoginSubmitHandler = async (phone: string) => {
        if (phone === "" || phone?.length < 10) {
            setError('Looks like you have entered a wrong phone number.')
            return;
        }
        setLoader(true)
        try {
            setPhone(phone);
            const userLoginResult = await loginUser(phone);
            Mixpanel.track(events.click_login_from_log_in_page, {
                event_page: events_page.login,
                event_action: events_action.clicked,
                response: {
                    phone
                }
            })
            if (userLoginResult?.status === 200 && userLoginResult?.message === 'User login success.Open Otp page') {
                setLoader(false);
                if (Testers.includes(phone)) {
                    // console.log('Phone number exists in the list.');
                    setState(STATES.MASTER_LOGIN_FORM)
                } else {
                    // console.log('Phone number does not exist in the list.');
                    Mixpanel.track('view_otp_screen', {
                        event_page: 'otp_screen',
                        event_action: events_action.viewed,
                        page: 'login',
                    })
                    setState(STATES.OTP);
                }
                return;
            } else if (userLoginResult?.status === 404) {
                setLoader(false);
                setIsLoginFailure(true);
                try {
                    Mixpanel.track(events.view_oops_after_login, {
                        event_page: events_page.login_successful,
                        event_action: events_action.viewed,
                        response: {
                            phone
                        }
                    })
                } catch (e) {
                }
            }
        } catch (e) {
            setLoader(false);
            setIsLoginFailure(true);
            try {
                Mixpanel.track(events.view_oops_after_login, {
                    event_page: events_page.login_successful,
                    event_action: events_action.viewed,
                    response: {
                        phone
                    }
                })
            } catch (e) {
            }
        }
    }


    const onSignUpClickHandler = () => {
        Mixpanel.track('click_enroll_now', {
            event_page: 'login_page',
            event_action: events_action.clicked,
        })
        setLoader(false);
        try {
            navigate('/onboarding')
        } catch (e) {

        }
    }


    useEffect(() => {
        const storedPhone = get('Phone');
        const remember = get('Remember');
        if (remember) {
            setRememberMe(true)
        }

        if (storedPhone) {
            setPhone('' + storedPhone);
        }
    }, []);


    const onOtpVerifyHandler = async (otp: number) => {
        setLoader(true)
        try {
            Mixpanel.track("click_verify_otp", {
                event_page: 'otp_screen',
                page: 'login',
                event_action: events_action.clicked,
                response: {
                    phone
                }
            })
            const userLoginResult = await verifyOtp(otp, phone, masterPhone);
            if (userLoginResult?.status === 200) {
                setIsLoginSuccessful(true)
                Mixpanel.identify(userLoginResult.userData?.uid)
                Mixpanel.track(events.view_login_successful, {
                    event_page: events_page.login,
                    event_action: events_action.viewed,
                    response: {
                        phone
                    }
                })
                let isProfileCompleted = true;
                try {
                    if(moment(new Date(userLoginResult.userData?.createdAt?._seconds * 1000)).isAfter(moment('Jul 19 2024')) && !userLoginResult.userData?.isProfileCompleted){
                        isProfileCompleted = false;
                    }else{
                        isProfileCompleted = true;
                    }
                }catch (e) {
                    console.log("signupdate logi errn",e);
                }

                // TODO make these changes for sign up also
                const user = {
                    phone: userLoginResult?.userData?.phone,
                    name: userLoginResult?.userData?.name,
                    phoneNumber: userLoginResult?.userData?.phoneNumber,
                    uid: userLoginResult.userData?.uid,
                    isProfileCompleted:isProfileCompleted,
                }
                dispatch(addUsers(userLoginResult.userData))
                window.localStorage.setItem('user', JSON.stringify(user))
                if(isProfileCompleted){
                    setTimeout(() => {
                        setIsLoginSuccessful(false)
                        navigate('/home', {state: {user: userLoginResult?.userData}, replace: true});
                    }, 500)
                }else{
                    setIsLoginSuccessful(false)
                    navigate('/complete-your-profile');
                }

            } else {
                setLoader(false);
                setError('Please enter the correct OTP')
            }
        } catch (e) {
        }
    }


    const onBackArrowClick = () => {
        setLoader(true)
        if (state === STATES.LOGIN_FORM) {
            navigate('/landing-page-one');
            return;
        }
        try {
            setLoader(false);
            setState(prevState => {
                switch (prevState) {
                    case STATES.OTP:
                        return STATES.LOGIN_FORM;
                    default:
                        return prevState;
                }
            });
        } catch (e) {

        }
    }

    const closeModal = () => {
        setIsLoginSuccessful(false);
        setIsLoginFailure(false);
    };

    const onMasterLoginSubmitHandler = (masterPhone: string) => {
        setMasterPhone(masterPhone);
        setState(STATES.OTP)
    }


    return (
        <div style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            background: '#F4F4F4',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf:'center',
        }}>
            {loader && <Loader/>}
            {error && <ErrorComponent onClose={setError.bind(null, '')} customStyles={{
                position: 'fixed',
                justifyContent: 'flex-start',
                alignItems: 'center',
                top: 10,
                left: 10,
                right: 10
            }} message={error}/>}
            {state === STATES.LOGIN_FORM &&
                <LoginFrom onEnrollNowClickHandler={onSignUpClickHandler} onBackArrowClick={onBackArrowClick}
                           onFormSubmitHandler={onLoginSubmitHandler}/>}
            {state === STATES.OTP && <Otp onBackArrowClick={onBackArrowClick} page={'login'} onOtpVerifyHandler={onOtpVerifyHandler}
                                          mobileNumber={phone}/>}

            {state === STATES.MASTER_LOGIN_FORM && <MasterLoginSheet onSkip={setState.bind(null, STATES.OTP)}
                                                                     onSubmitMasterLogin={onMasterLoginSubmitHandler}/>}

            <LoginFailureModal
                isLoginFailure={isLoginFailure}
                closeModal={closeModal}
                onSignUpClickHandler={onSignUpClickHandler}
            />
            <ToastContainer/>
        </div>
    )
}

export default Login
