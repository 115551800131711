import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Autoplay} from 'swiper/modules';
import {Nudge} from "./Nudge";
import {ReactComponent as SpecialCourseIcon} from '../../assets/new-icons/new.svg';

interface CustomCarouselProps {
    slides: any;
    onClick: any;
}

const IngressNudgesSwiper: React.FC<CustomCarouselProps> = ({
                                                                onClick,
                                                                slides,
                                                            }) => {

    return (
        <>
            <Swiper
                onSlideChange={(swiper:any)=>{
                    // console.log("swiper",swiper?.re);
                }}
                spaceBetween={0}
                style={{
                    height: 'auto',
                    width: '100%',
                    marginTop: '4px',
                    paddingLeft:'16px',
                    // paddingRight:'0px',
                    // paddingRight:'16px',
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignSelf: 'center',
                    // alignItems: 'center',
                    // justifyContent: 'center'

                }}
                centeredSlides={false}
                slidesPerView={1.04}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                loop={true}
                modules={[Autoplay]}
                // className="ingress-nudges-swiper"
            >
                {slides?.map((item: any, index: number) => (
                    <SwiperSlide
                        style={{width:'320px'}}
                        key={index}
                    >
                        <Nudge course_name={item?.course_name} size={slides?.length}
                               nudge_type={item.nudge_type} redirection_link={item.redirection_link}
                               onClick={onClick} isVisible={item.isVisible} index={index}
                               Icon={item?.Icon || SpecialCourseIcon}
                               title={item.title} key={index}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}

export default IngressNudgesSwiper;
