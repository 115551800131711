import React, {memo} from 'react';
import './LeaderBoardRow.css';
import HorizontalChipWithIconAndTitle from "../../../components-v2/chips/HorizontalChipWithIconAndTitle";
import {ReactComponent as Coins} from "../../../assets/new-icons/coins.svg";
import {capitalizeWords} from "../../../utils/utils";

interface LeaderBoardRowProps {
    name: string;
    points: number;
    rank: number;
    phone: string;
    userPhone: string;
    showIcon?: boolean;
    className?: string;
    textColor?: string,
}

const LeaderBoardRow: React.FC<LeaderBoardRowProps> = ({
                                                           name,
                                                           points,
                                                           rank,
                                                           phone,
                                                           userPhone,
                                                           showIcon = true,
                                                           className,
                                                           textColor
                                                       }) => {
    const truncatedName = name.length > 18 ? `${name.slice(0, 18)}...` : name;
    const isCurrentUser = phone === userPhone;
    console.log("LeaderBoardRow",phone,userPhone);

    return (
        <>
            <div className={`leaderboard-points-row`}>
                    <span style={{color: textColor}} className="leaderboard-rank">{`${rank < 10 ? '0'+rank:rank}. `}</span>
                    <span className="leaderboard-name" style={{color: textColor,fontWeight:isCurrentUser?'500':'400', fontFamily:isCurrentUser?'Gordita-Medium':'Gordita-Regular'}}>
                        {`${capitalizeWords(truncatedName)}`} <span style={{color: textColor}}
                                                   className={'leaderboard-you-surname'}>{isCurrentUser && ' (you) '}</span></span>
                <div style={{display: "flex",flex:1,alignItems:'center',justifyContent:'center',flexDirection:'row'}}>
                    <HorizontalChipWithIconAndTitle styles={{
                        icon: {width: '24px', height: '24px'},
                        title: {
                            color: 'var(--Body-Font, #4A4A4A)',
                            fontFamily: 'Gordita-Medium',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px'
                        }
                    }
                    } Icon={Coins} title={points+""}/>
                    <span style={{fontSize:'12px',lineHeight:'16px',width:'100%',height:'100%',marginTop:'2px',marginLeft:'4px',fontWeight:'400',fontFamily:'Gordita-Regular'}}>{'KP'}</span>
                </div>
            </div>
            {!showIcon && <div style={{width: '343px', height: '1px', marginTop: '8px', backgroundColor: '#BAB8F3'}}/>}
            <svg xmlns="http://www.w3.org/2000/svg" width="343" height="2" viewBox="0 0 343 2" fill="none">
                <path d="M0 1H343" stroke="#F4F4F4"/>
            </svg>
        </>
    );
};

export default memo(LeaderBoardRow);
