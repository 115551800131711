import React, {useEffect, useRef, useState} from 'react';
import {Autocomplete, StandaloneSearchBox} from '@react-google-maps/api';
import './PlaceInput.css'

interface Place {
    place_id: string;
    description: string;
}

interface PlaceInputProps {
    onSelectPlace: (place: Place) => void;
    selectedLocation: string,
}

const PlaceInput: React.FC<PlaceInputProps> = ({onSelectPlace, selectedLocation}) => {
    const [isStartButtonDisabled, setIsStartButtonDisabled] = useState(true);
    const searchBox = useRef<google.maps.places.Autocomplete | null>(null);
    const [inputValue, setInputValue] = useState(selectedLocation);

    useEffect(() => {
        setInputValue(selectedLocation);
    }, [selectedLocation]);

    const handlePlacesChanged = () => {
        const place = searchBox.current?.getPlace();
        if (place) {
            setIsStartButtonDisabled(false)
            const formattedPlace: Place = {
                place_id: place.place_id || '',
                description: place.formatted_address || '',
            };
            setInputValue(place.formatted_address || '');  // Update input value to selected place name
            onSelectPlace(formattedPlace);
        } else {
            setIsStartButtonDisabled(true)
        }
    };


    return (
        <Autocomplete
            onLoad={(ref) => (searchBox.current = ref)}
            // onPlacesChanged={handlePlacesChanged}
            onPlaceChanged={handlePlacesChanged}
            options={{types: ['(cities)']}}
            className={'place-input-container'}
        >
            <input
                value={inputValue}
                type="text"
                placeholder="Enter your city"
                onChange={(e) => setInputValue(e.target.value)}
                style={{
                    textOverflow: 'ellipses',
                    opacity: isStartButtonDisabled ? 0.5 : 1,
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontFamily: 'Gordita-Regular',
                    fontWeight: '400',
                    borderRadius: '16px',
                    width: '100%',
                    height: '100%',
                    color: '#1B1625',
                    background:isStartButtonDisabled ?'#ffffff':'white',
                    paddingLeft: '16px',
                    border: 'none',
                    textAlign: 'start',
                }}
            />
        </Autocomplete>
    );
};

export default PlaceInput;
