import React from 'react';
import './Header.css';
import {ReactComponent as Coins} from "../../assets/new-icons/coins.svg";
import HorizontalChipWithIconAndTitle from "../chips/HorizontalChipWithIconAndTitle"; // Import your CSS file for styling
import Image from "react-bootstrap/Image";

interface HeaderProps {
    logoUrl: string;
    icon?: any;
    title?: string;
    karma_points:string,
    onClick:any,
}

const Header: React.FC<HeaderProps> = ({logoUrl, icon, title,karma_points,onClick}) => {
    return (
        <header className="header-v2-container">
            <div className="header-v2-left-content">
                <Image src={logoUrl} alt="Logo" className="header-v2-logo img-fluid"/>
            </div>
            {parseInt(karma_points) > 0 && <div className="header-v2-right-content">
                <div className="header-v2-icon-title-container">
                    <HorizontalChipWithIconAndTitle onIconClick={onClick} styles={{
                        icon: {width: '32px', height: '32px'},
                        title: {
                            color: 'var(--Body-Font, #4A4A4A)',
                            fontFamily: 'Gordita-Bold',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '20px'
                        }
                    }
                    } Icon={Coins} title={(karma_points)} subtitle={'KP'}/>
                </div>
            </div>}

        </header>
    );
};
export default Header;
