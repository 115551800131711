import React, {lazy, memo, Suspense, useEffect} from 'react'
import {useSelector, useDispatch} from "react-redux";
import {addUsers} from "../../store/features/AuthSlice";
import '../home/components/AccountCreated.css'
import 'react-lazy-load-image-component/src/effects/blur.css';
import {
    getUpcomingCourses,
    getUserByUserId,
} from "../../services/Firebase";
import {useNavigate} from "react-router-dom";
import './CourseContainer.css'
import seek_logo_url from "../../assets/seek-logo.svg";
import {
    isEmpty,
} from "../../utils/utils";
import {Mixpanel} from "../../analytics/Mixpanel";
import useAuthenticatedUsers from "../../hooks/useAuthenticatedUsers";
import Header from "../../components-v2/header/Header";
import ShimmerLoader from "../../components-v2/shimmer/Shimmer";
import moment from "moment-timezone";
import {ReactComponent as CalendarIcon} from "../../assets/new-icons/calendar_blue.svg";
import {ReactComponent as EasyDifficultyLevel} from "../../assets/new-icons/easy-difficulty-level.svg";
import {ReactComponent as LanguageIcon} from "../../assets/new-icons/language-icon.svg";
import {setForceRefreshOnCoursePurchase, setUpcomingCourses} from "../../store/features/UpcomingClassesSlice";

const CourseCard = lazy(() => import("../../components-v2/course/CourseCard"));

const CourseContainer = memo(() => {
    const userData = useAuthenticatedUsers(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state?.auth?.user?.data);
    const upcomingCourses = useSelector((state: any) => state.upcomingClasses?.upcomingCourse?.data);
    const force_refresh_on_course_purchase = useSelector((state: any) => state.upcomingClasses?.force_refresh_on_course_purchase); // Assuming upcomingClasses state is stored in Redux

    useEffect(() => {
        if (isEmpty(user) && userData?.uid) {
            getUserByUserId(userData?.uid).then((userLoginResult) => {
                dispatch(addUsers(userLoginResult?.userData))
                Mixpanel.identify(userLoginResult?.userData?.uid)
            })
        }
    }, [userData, user])


    useEffect(() => {
        if (isEmpty(upcomingCourses) || force_refresh_on_course_purchase) {
            // setLoader(true)
            getUpcomingCourses(userData?.uid)
                .then((result: any) => {
                    dispatch(setUpcomingCourses(result.data));
                    dispatch(setForceRefreshOnCoursePurchase(false));
                })
                .catch((err: any) => {
                    console.log("getUpcomingClasses err", err);
                });
        }
    }, [upcomingCourses,userData,force_refresh_on_course_purchase]);


    const onKarmaPointsClickHandler = () => {
        Mixpanel.track('click_karma_points_badge', {
            event_action: 'clicked',
            context_user_id: user?.uid,
            total_karma_points: user?.total_karma || 0,
            event_page: 'home_screen',
        });
        navigate('/karma', {state: {email: user?.email, total_karma: user?.total_karma}, replace: false})
    }


    const onCourseClickHandler = (courseItem: any) => {
        Mixpanel.track('click_upcoming_course', {
            event_action: 'clicked',
            course_name: courseItem?.title || upcomingCourses[0]?.title,
            course_date: moment(new Date(upcomingCourses[0]?.streaming_date._seconds * 1000 + upcomingCourses[0]?.streaming_date._nanoseconds / 1000000)).format('DD MMM YYYY'),
            event_page: 'courses',
        });
        navigate(`/upcoming-course/${courseItem?.courseId}`, {
            state: {
                courseItem,
                user,
            }
        });
    }


    return (
        <div className={'course-list-container'}>
            <Header onClick={onKarmaPointsClickHandler} logoUrl={seek_logo_url} karma_points={user?.total_karma}/>
            <Suspense fallback={<ShimmerLoader/>}>
                {upcomingCourses?.length > 0 ? upcomingCourses?.map((course: any, index: number) =>
                    <CourseCard
                        course_type={course?.batch[0]?.tag_name}
                        onClick={onCourseClickHandler.bind(null, course)}
                        courseDate={moment(new Date(course?.batch[0]?.start_date._seconds * 1000 + course?.batch[0]?.start_date._nanoseconds / 1000000)).format('DD MMM YY')}
                        tag={'Scheduled'} icons={[{
                        title: moment(new Date(course?.batch[0]?.start_date._seconds * 1000 + course?.batch[0]?.start_date._nanoseconds / 1000000)).format('DD MMM YY'),
                        icon: CalendarIcon
                    }, {}, {
                        title: course?.intensity + " Intensity",
                        icon: EasyDifficultyLevel
                    }, {}, {title: course?.language, icon: LanguageIcon}]}
                        title={course?.title}
                        imageUrl={course?.thumbnail}
                        price={course?.price}
                        subtitle={"By " + course?.instructor}
                        primaryButtonText={'View Details'}/>) : <><ShimmerLoader style={{height:'300px'}}/><ShimmerLoader style={{height:'300px'}}/></>}
            </Suspense>
        </div>
    )
})

export default CourseContainer;

