import React, { CSSProperties } from 'react';
import { Card } from 'react-bootstrap';
import './FAQ.css';
import { ReactComponent as UpArrow } from '../../../assets/new-icons/_xmark.svg';
import { ReactComponent as DownArrow } from '../../../assets/new-icons/_plus.svg';

interface faqItem {
    q: string;
    a: string;
    b?: string;
    c?: string;
    d?: string;
    e?: string;
}

interface FaqProps {
    page?: string;
    item: faqItem;
    index: number;
    selectedIndex?: number | null;
    onClickHandler: (index: number) => void;
    style?: CSSProperties;
}

const FAQ: React.FC<FaqProps> = React.memo(({ item, index, selectedIndex, onClickHandler, page, style }) => {
    return (
        <div
            style={
                index === selectedIndex
                    ? {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        maxWidth:'354px',
                        borderRadius: '12px',
                        paddingTop: '12px',
                        paddingLeft:'16px',
                        border: '1px solid #FAA53D',
                        background: '#FFF6EC',
                        justifyContent: 'center',
                        alignItems: 'start',
                        marginTop: '16px',
                        position: 'relative',
                    }
                    : {
                        borderRadius: '12px',
                        display: 'flex',
                        background: '#FFF',
                        paddingLeft:'16px',
                        border: '1px solid #D6D6D6',
                        flexDirection: 'column',
                        width: '100%',
                        maxWidth:'354px',
                        justifyContent: 'center',
                        alignItems: 'start',
                        marginTop: '16px',
                        position: 'relative',
                    }
            }
        >
            <div
                onClick={onClickHandler.bind(null, index)}
                className={index === selectedIndex ? 'faq-container-selected' : 'faq-container'}
            >
                <p className={'question'} style={{...style,marginTop:index === selectedIndex?'0px':'15px',fontWeight:index === selectedIndex?'500':'400',fontFamily:index === selectedIndex?'Gordita-Medium':'Gordita-Regular'}}>
                    {item.q}
                </p>
                {index === selectedIndex && (
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <p className={'answer'} style={style}>
                            {item.a}
                        </p>
                        {item?.b && (
                            <p className={'answer'} style={style}>
                                {item?.b}
                            </p>
                        )}
                        {item?.c && (
                            <p className={'answer'} style={style}>
                                {item?.c}
                            </p>
                        )}
                        {item?.d && (
                            <p className={'answer'} style={style}>
                                {item?.d}
                            </p>
                        )}
                    </div>
                )}
            </div>
            {index === selectedIndex ? (
                <UpArrow
                    onClick={onClickHandler.bind(null, index)}
                    style={{ position: 'absolute', top: 22, right: 12 }}
                    height={32}
                    width={32}
                />
            ) : (
                <DownArrow
                    onClick={onClickHandler.bind(null, index)}
                    style={{ position: 'absolute', top: '50%', right: 10, transform: 'translateY(-50%)' }}
                    height={32}
                    width={32}
                />
            )}
        </div>
    );
});

export default FAQ;
