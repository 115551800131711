import {configureStore} from "@reduxjs/toolkit";
import leaderboardReducer from '../store/features/LeaderboardSlice';
import authReducer from '../store/features/AuthSlice';
import planReducer from '../store/features/PlanSlice';
import upcomingClassesReducer from "../store/features/UpcomingClassesSlice"
import bottomNavReducer from "../store/features/BottomNavSlice"
import eventsSliceReducer from "../store/features/EventsSlices";
import pocFormReducer from "../store/features/PocCounsellingSlice";


export const store = configureStore({
    reducer: {
        leaderboard: leaderboardReducer,
        auth: authReducer,
        plan: planReducer,
        upcomingClasses: upcomingClassesReducer,
        bottomNav:bottomNavReducer,
        events:eventsSliceReducer,
        pocFormReducer:pocFormReducer,
        // Add other reducers if needed
    },
});

