import React, {useState, ChangeEvent, useEffect} from "react";
import PhoneInputComponent from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "./PhoneInput.css";

interface PhoneInputComponentProps {
    defaultCountry?: string;
    onPhoneChange: (value: string, country: string, e: ChangeEvent<HTMLInputElement>, formattedValue: string) => void;
    inputStyle?: React.CSSProperties;
    dropdownStyle?: React.CSSProperties;
    phoneNumber?: string,
    onFocusHandle?:any,
    onBlurHandle?:any,

}

const PhoneInput: React.FC<PhoneInputComponentProps> = ({
                                                            onBlurHandle,
                                                            onFocusHandle,
                                                            defaultCountry = "in",
                                                            onPhoneChange,
                                                            inputStyle,
                                                            dropdownStyle,
                                                            phoneNumber
                                                        }) => {

    const [phone, setPhone] = useState("");


    useEffect(() => {
        if (phoneNumber) setPhone(phoneNumber)
    }, [phoneNumber])

    const handlePhoneChange = (value: string, country: string, e: ChangeEvent<HTMLInputElement>, formattedValue: string): void => {
        setPhone(value);
        onPhoneChange(value, country, e, formattedValue);
    };

    const allowedCountries = [
        'in', // India
        'us', // United States
        'ae', // United Arab Emirates
        'au', // Australia
        'sg', // Singapore
        'cn', // China
        'id', // Indonesia
        'br', // Brazil
        'pk', // Pakistan
        'bd', // Bangladesh
        'ru', // Russia
        'jp', // Japan
        'de', // Germany
        'fr', // France
        'it', // Italy
        'es', // Spain
        'gb', // United Kingdom
        'nl', // Netherlands
        'be', // Belgium
        'se', // Sweden
        'ch', // Switzerland
    ];


    return (
        <PhoneInputComponent
            country={defaultCountry}
            onlyCountries={allowedCountries}
            // enableSearch={true}
            placeholder="Enter your phone number"
            countryCodeEditable={false}
            value={phone}
            inputProps={{
                name: 'phone',
                required: true,
                // autoFocus: true
                onFocus:onFocusHandle,
                onBlur:onBlurHandle,
                placeholder: 'xxxxxxxxxxx'
            }}

            onChange={handlePhoneChange}
            inputStyle={{
                display: 'flex',
                alignSelf: 'center',
                padding: '8px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Gordita-Regular',
                paddingLeft: '48px',
                fontSize: '16px',
                border: '1px solid #D6D6D6',
                borderRadius: '12px',
                width: '100%',
                height: '52px',
                // paddingTop:'-30px',
                marginTop: '8px',
                color: '#2A2A2A',
                ...inputStyle, // Merge with custom input styles
            }}
            dropdownStyle={{
                borderRadius: '5px',
                paddingTop: '8px',
                ...dropdownStyle, // Merge with custom dropdown styles
            }}
        />
    );
};

export default PhoneInput;

