import React, {ChangeEvent, useEffect, useState} from 'react'

import {
    getLiveStreamingPlans,
} from "../../services/Firebase";
import {Mixpanel} from "../../analytics/Mixpanel";
import {useLocation, useNavigate} from "react-router-dom";
import CheckoutPage from "./components/CheckoutPage";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Plan.css'
import '../login/Login.css'
import useAuthenticatedUsers from "../../hooks/useAuthenticatedUsers";
import PlanDescriptionPage from "./components/PlanDescriptionPage";
import {isEmpty} from "../../utils/utils";
import {events_action} from "../../analytics/Events";
import { useSelector} from "react-redux";
import Header from "../../components-v2/header/Header";
import seek_logo_url from "../../assets/seek-logo.svg";
import BackButton from "../../components-v2/buttons/BackButton";
import Shimmer from "../../components-v2/shimmer/Shimmer";
import LoginModal from "../../components-v2/models/LoginModel";
import LoginFailureModal from "../../components-v2/models/LoginFailureModel";
import PaymentSuccessModal from "../../components-v2/models/PaymentSuccessModel";
import PaymentFailedModal from "../../components-v2/models/PaymentFailedModel";
import useLoginHook from "../../hooks/useLogin";
import useRazorpay from "../../hooks/useRazorpay";

export enum STATES {
    LOADING,
    PLAN_DESCRIPTION_PAGE,
    CHECKOUT_PAGE,
}

function Plan() {

    const userData = useAuthenticatedUsers(true);
    const location = useLocation();
    const prevScreenName = location.state ? location.state.screenName : null;
    const user = useSelector((state: any) => state?.auth?.user?.data);
    const [state, setState] = useState<STATES>(STATES.PLAN_DESCRIPTION_PAGE);
    const [loader, setLoader] = useState(true);
    const [paymentSuccessPopup, setPaymentSuccessPopup] = useState(false);
    const [paymentFailedPopup, setPaymentFailedPopup] = useState(false);
    const [plans, setPlans] = useState<any>();
    const [selectedPlanVariant, setSelectedPlanVariant] = useState<any>();
    const [selectedPlanIndex, setSelectedPlanIndex] = useState<any>(0);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [showOtpPopup, setShowOtpPopup] = useState(false);
    const [phone, setPhone] = useState<string>('');
    const [error, setError] = useState<string>('');

    const onClickLogin = (phone:string) =>{
        Mixpanel.track('click_login_popup_checkout_page', {
            event_page: 'payment_screen/payment_one',
            event_action: events_action.clicked,
            // email: email,
            phone: phone,
        })
        onLoginSubmitHandler(phone).then();
    }

    const onLoginSuccess = (loginResult:any) =>{
        toast.success('Login Successful', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: true,
        });
        Mixpanel.track('login_successful_checkout_page', {
            event_page: 'payment_screen/payment_one',
            event_action: events_action.viewed,
            // email: email,
            phone: phone,
        })
    }
    const onLoginFailure = (loginResult:any) =>{
        Mixpanel.track('login_failed_checkout_page', {
            event_page: 'payment_screen/payment_one',
            event_action: events_action.viewed,
            phone: phone,
        })
    }

    const {
        isLoginSuccessful,
        isLoginFailure,
        setIsLoginSuccessful,
        setIsLoginFailure,
        onLoginSubmitHandler,
        onOtpVerifyHandler,
    } = useLoginHook({page:'payment_screen/payment_one',setLoader, setError,setShowOtpPopup,setShowLoginPopup,onLoginSuccess,onLoginFailure});


    const {displayRazorpay} = useRazorpay({
        setPaymentFailedPopup,
        setPaymentSuccessPopup,
        setShowLoginPopup,
        user,
        userData,
        selectedPlanVariant,
        prevScreenName,
    });

    const navigate = useNavigate();
    useEffect(() => {
        Mixpanel.track('view_payment_landing_page', {
            event_action: 'viewed',
            event_page: 'plan_landing page',
        });
        (async () => {
            try {
                const plansResponse = await getLiveStreamingPlans();
                setPlans(plansResponse.plans[0]?.variants);
                setLoader(false);
                // setState(STATES.PLAN_DETAILS_PAGE);
            } catch (e) {

            }
        })()
    }, [])


    const onProceedFromPlanDetailsPageHandler = async (planVariant: any, index: number) => {
        // console.log("onProceedFromPlanDetailsPageHandler",planVariant)
        setSelectedPlanVariant(planVariant);
        setSelectedPlanIndex(index);
        setLoader(true)
        try {
            Mixpanel.track('click_continue_landing_page', {
                event_action: 'clicked',
                plan_name: planVariant?.name,
                plan_amount: planVariant?.monthly_price,
                userId: user?.uid,
                event_page: 'plan_landing_page',
            });
            if (!userData) {
                Mixpanel.track('view_login_and_pay_checkout_page', {
                    event_action: 'viewed',
                    plan_name: planVariant?.name,
                    plan_amount: planVariant?.monthly_price,
                    userId: user?.uid,
                    event_page: 'payment_screen/payment_one',
                });
            } else {
                Mixpanel.track('view_payment_screen_updated', {
                    event_action: 'viewed',
                    plan_name: planVariant?.name,
                    plan_amount: planVariant?.monthly_price,
                    userId: user?.uid,
                    event_page: 'payment_screen/payment_one',
                });
            }
            setState(STATES.CHECKOUT_PAGE)
            setLoader(false);
        } catch (e) {

        }
    }


    const onBackArrowClick = () => {
        setLoader(true)
        if (state === STATES.PLAN_DESCRIPTION_PAGE) {
            // if (location.key === 'default' && userData) {
            //     // console.log("navigate to home logged in user , but came from public link");
            //     navigate('/home')
            // } else
            if (location.key === 'default' && !userData) {
                // console.log("navigate to seeklive.com not logged in user , but came from public link");
                window.location.href = 'https://seekdaily.live';
                navigate(-1);
            } else {
                // console.log("navigate to home logged in user , but came from app");
                navigate(-1);
            }
        }
        try {
            setLoader(false);
            setState(prevState => {
                switch (prevState) {
                    case STATES.CHECKOUT_PAGE:
                        return STATES.PLAN_DESCRIPTION_PAGE;
                    default:
                        return prevState;
                }
            });
        } catch (e) {

        }
    }

    const handlePhoneChange = (value: string, country: string, e: ChangeEvent<HTMLInputElement>, formattedValue: string): void => {
        setError('')
        setPhone(value);
    };

    const closeErrorPopup = () => {
        setError('');
    }


    const closeModal = () => {
        setIsLoginSuccessful(false);
        setIsLoginFailure(false);
    };

    const onSignUpClickHandler = () => {
        setLoader(false);
        setShowLoginPopup(false);
        try {
            navigate('/onboarding')
        } catch (e) {

        }
    }

    const onKarmaPointsClickHandler = () => {
        Mixpanel.track('click_karma_points_badge', {
            event_action: 'clicked',
            context_user_id: user?.uid,
            total_karma_points: user?.total_karma || 0,
            event_page: 'home_screen',
        });
        navigate('/karma', {state: {email: user?.email, total_karma: user?.total_karma}, replace: false})
    }

    return (
        <div className={'plan-container'}>
            {state === STATES.PLAN_DESCRIPTION_PAGE &&
                <Header onClick={onKarmaPointsClickHandler} logoUrl={seek_logo_url} karma_points={user?.total_karma}/>}

            <div style={{width: '90%', maxWidth: '400px', marginTop: '24px'}}>
                <BackButton onClick={onBackArrowClick}/>
            </div>

            {loader && <><Shimmer style={{
                height: '100px',
                width: '90%',
                maxWidth: '375px',
                color: 'linear-gradient(180deg, rgba(68, 98, 239, 0.12) 0%, rgba(68, 98, 239, 0.12) 22%, #fff 22%, #fff 100%)'
            }}/>
                <Shimmer style={{height: '100px', width: '90%', maxWidth: '375px'}}/>
                <Shimmer/> <Shimmer/></>}
            {!loader && state === STATES.PLAN_DESCRIPTION_PAGE &&
                <PlanDescriptionPage selectedPlanIndex={selectedPlanIndex} isCommunityMember={user?.referralCode}
                                     plans={plans}
                                     onProceedFromPlanDetailsPageHandler={onProceedFromPlanDetailsPageHandler}/>}
            {!loader && state === STATES.CHECKOUT_PAGE &&
                <CheckoutPage isUserLogin={(userData != null || !isEmpty(user)) || isLoginSuccessful}
                              isCommunityMember={user?.referralCode}
                              total_karma={user?.total_karma} selectedPlanVariant={selectedPlanVariant}
                              onBackArrowClick={onBackArrowClick} payNowClickHandler={displayRazorpay}/>}

            <PaymentSuccessModal
                paymentSuccessPopup={paymentSuccessPopup}
                setPaymentSuccessPopup={setPaymentSuccessPopup}
                selectedPlanVariant={selectedPlanVariant}
            />

            <PaymentFailedModal
                paymentFailedPopup={paymentFailedPopup}
                setPaymentFailedPopup={setPaymentFailedPopup}
                selectedPlanVariant={selectedPlanVariant}
                displayRazorpay={displayRazorpay}
            />
            <LoginFailureModal
                isLoginFailure={isLoginFailure}
                closeModal={closeModal}
                onSignUpClickHandler={onSignUpClickHandler}
            />
            <LoginModal
                page={'payment_screen/payment_one'}
                setError={setError}
                showEnrollNowCta={true}
                onEnrollNowClickHandler={onSignUpClickHandler}
                onOtpVerifyHandler={onOtpVerifyHandler}
                showOtpPopup={showOtpPopup}
                setShowOtpPopup={setShowOtpPopup}
                showLoginPopup={showLoginPopup}
                setShowLoginPopup={setShowLoginPopup}
                error={error}
                closeErrorPopup={closeErrorPopup}
                phone={phone}
                handlePhoneChange={handlePhoneChange}
                onLoginSubmitHandler={onClickLogin}
                customInputStyle={{ /* Your custom styles here */}}
            />
            <ToastContainer/>
        </div>
    )
}

export default Plan
