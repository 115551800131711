import React, {useEffect, useRef, useState} from 'react'
import './PostOBScreen.css'

import {Mixpanel} from "../../../analytics/Mixpanel";
import {events, events_action} from "../../../analytics/Events";
import {useNavigate} from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import {ToastContainer} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {updateEventsStatus} from "../../../store/features/EventsSlices";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../../services/Firebase";
import ReactPlayer from "react-player";
import Button from "../../../components/button/Button";


const PostOBScreen = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [accountCreatedVideoUrl, setAccountCreatedVideoUrl] = useState('');
    const [isVideoEnded, setIsVideoEnded] = useState(false);
    const playerRef = useRef<any>(null);

    const onAnimationComplete = () => {
        setIsVideoEnded(true);
    }
    const dispatch = useDispatch();
    const view_post_ob_video = useSelector((state: any) => state?.events?.eventsLog?.view_post_ob_video);
    useEffect(() => {
        if (!view_post_ob_video) {
            Mixpanel.track(events.view_post_ob_video, {
                event_action: events_action.viewed,
                event_page: 'get_started',
            })
            dispatch(updateEventsStatus({key: 'view_post_ob_video', value: true}))
        }
    }, [view_post_ob_video])


    const proceedHandler = () => {
        try {
            Mixpanel.track('click_get_started', {
                event_page: 'get_started',
                event_action: events_action.clicked,
            })
        } catch (e) {
        }
        navigate('/home');
    }

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const userAgent = window.navigator.userAgent.toLowerCase();
                let videoPath = '/video/Post Sign Up Videos/PostSignUpOB_iOS.webm';
                if (userAgent.indexOf('android') > -1) {
                    videoPath = '/video/Post Sign Up Videos/PostSignUpOB_Android.webm';
                }
                const accountCreatedUrlRef = ref(storage, videoPath);
                const accountCreatedUrl = await getDownloadURL(accountCreatedUrlRef);
                setAccountCreatedVideoUrl(accountCreatedUrl);

            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };
        fetchImage().then();
    }, []);

    useEffect(() => {
        try {
            const handleBackButton = (event: PopStateEvent) => {
                event.preventDefault();
                Mixpanel.track('back_button_pressed', {
                    event_page: 'get_started',
                    event_action: events_action.clicked,
                });
                console.log('Back button pressed');
                navigate('/home');
            };

            window.addEventListener('popstate', handleBackButton);
            return () => {
                window.removeEventListener('popstate', handleBackButton);
            };
        }
        catch (e) {
            console.log("error while navigating")
        }
    }, []);



    return (
        <div style={{
            display: 'flex',
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            background: 'white',
            paddingTop: '32px'
        }}>
            {loader && <Loader/>}
            <div className={'post-ob-video-animation-body-container'} style={{marginTop: '42px'}}>
                {
                    <ReactPlayer
                        ref={playerRef}
                        controls={false}
                        playing={true}
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        playsinline={true}
                        fileconfig={{attributes: {autoPlay: true}}}
                        url={accountCreatedVideoUrl}
                        width={"100%"}
                        onProgress={(progress) => {
                            if (progress.playedSeconds >= progress.loadedSeconds - 25) {
                                onAnimationComplete(); // Call your function to handle the end of the video
                            }
                        }}
                        height={472}
                        style={{
                            display: 'flex',
                            width: '100%',
                            maxWidth: '327px',
                            maxHeight: '472px',
                            height: 'auto',
                            background: 'white',
                            backgroundColor: 'transparent',
                            alignSelf: 'center',
                            margin: '-16px auto', // Center the player horizontally
                        }}
                    />
                }

                <Button
                    disabled={!isVideoEnded}
                    isSticky={true} style={{
                    alignSelf: 'center',
                    marginBottom: '24px',
                    background: !isVideoEnded ? 'var(--Inactive-Color, #D6D6D6)' : 'var(--Primary_Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%))'
                }} onClick={proceedHandler} label={'Get Started'}></Button>

            </div>
            <ToastContainer/>
        </div>
    )
}

export default PostOBScreen
