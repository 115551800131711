import React, {useEffect, useState} from 'react'
import './ManageReminders.css'
import {useLocation, useNavigate} from "react-router-dom";
import ToggleComponent from "./components/ToggleComponent";
import useAuthenticatedUsers from "../../hooks/useAuthenticatedUsers";
import {getUserByUserId, updateUser} from "../../services/Firebase";
import {isEmpty, managedAvailableClassSlots} from "../../utils/utils";
import {Spinner} from "react-bootstrap";
import {COLORS} from "../../utils/colors";
import {Mixpanel} from "../../analytics/Mixpanel";
import Header from "../../components-v2/header/Header";
import seek_logo_url from "../../assets/seek-logo.svg";
import BackButton from "../../components-v2/buttons/BackButton";
import {useDispatch, useSelector} from "react-redux";
import {updateEventsStatus} from "../../store/features/EventsSlices";
import {addUsers} from "../../store/features/AuthSlice";

const ManageReminders = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const userData = useAuthenticatedUsers();
    const location = useLocation();
    const user = useSelector((state: any) => state?.auth?.user?.data);
    const navigate = useNavigate()
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        if (isEmpty(user) && userData?.uid) {
            getUserByUserId(userData?.uid).then((userLoginResult) => {
                dispatch(addUsers(userLoginResult.userData))
                Mixpanel.identify(userLoginResult?.userData?.uid)
            })
        }
    }, [userData, user])

    const [selectedSlotCount, setSelectedSlotCount] = useState<number>(
        user?.userPreferences?.managedReminders
            ? Object.values(user.userPreferences.managedReminders).length
            : 2
    );

    const [userManagedReminders, setUserManagedReminders] = useState<any>(
        user?.userPreferences?.managedReminders
            ? Object.values(user.userPreferences.managedReminders)
            : []
    );


    useEffect(() => {
        setSelectedSlotCount(
            user?.userPreferences?.managedReminders
                ? Object.values(user.userPreferences.managedReminders).length
                : 2
        );

        setUserManagedReminders(
            user?.userPreferences?.managedReminders
                ? Object.values(user.userPreferences.managedReminders)
                : []
        );
    }, [user]);


    useEffect(() => {
        if (isEmpty(user) && userData?.uid) {
            getUserByUserId(userData?.uid).then((userLoginResult) => {
                dispatch(addUsers(userLoginResult.userData))
                setLoader(false);
                Mixpanel.identify(userLoginResult?.userData?.uid)
            })
        } else {
            setLoader(false);
        }
    }, [userData, user])

    const onToggleSlotHandler = (slotValue: string, isChosen: boolean) => {
        if (userManagedReminders?.length === 1) {
            return;
        }
        if (userManagedReminders?.length === 1) {
            return;
        }
        Mixpanel.track('click_manage_reminder_slots', {
            event_action: 'clicked',
            slot_time: slotValue,
            slot_status: isChosen,
            event_page: 'manage_reminder',
        });
        const updatedReminders = [...userManagedReminders];
        const index = updatedReminders.indexOf(slotValue);

        if (index !== -1 && !isChosen) {
            updatedReminders.splice(index, 1);
        } else if (index === -1 && isChosen && updatedReminders.length < 4) {
            updatedReminders.push(slotValue);
        }
        setUserManagedReminders(updatedReminders);
        setSelectedSlotCount(updatedReminders?.length)
        onSlotUpdateHandler(updatedReminders)

    };

    const dispatch = useDispatch();
    const view_manage_reminder_page = useSelector((state: any) => state?.events?.eventsLog?.view_manage_whatsapp_reminder);

    useEffect(() => {
        if (!view_manage_reminder_page && userManagedReminders) {
            Mixpanel.track('view_manage_whatsapp_reminder', {
                event_action: 'viewed',
                event_page: 'manage_reminder',
                reminder_time_slots: userManagedReminders
            });
            dispatch(updateEventsStatus({key: 'view_manage_whatsapp_reminder', value: true}))
        }
    }, [view_manage_reminder_page, userManagedReminders])


    const onSlotUpdateHandler = (updatedReminders: any) => {
        updatedReminders?.sort((a: any, b: any) => {
            // Extract hours and AM/PM from strings
            const [hoursA, periodA] = a?.split(' ');
            const [hoursB, periodB] = b?.split(' ');

            // Convert hours to numbers
            const hoursNumA = parseInt(hoursA);
            const hoursNumB = parseInt(hoursB);

            // Convert AM/PM to 24-hour format
            const hours24FormatA = periodA === 'AM' ? hoursNumA : hoursNumA + 12;
            const hours24FormatB = periodB === 'AM' ? hoursNumB : hoursNumB + 12;

            // Compare hours in 24-hour format
            if (hours24FormatA < hours24FormatB) {
                return -1;
            } else if (hours24FormatA > hours24FormatB) {
                return 1;
            } else {
                // If hours are equal, compare based on AM/PM
                if (periodA === 'AM' && periodB === 'PM') {
                    return -1;
                } else if (periodA === 'PM' && periodB === 'AM') {
                    return 1;
                } else {
                    return 0;
                }
            }
        });

        const reminderToUpdate = {
            slot_one: updatedReminders?.[0],
            slot_two: updatedReminders?.[1],
            slot_three: updatedReminders?.[2],
            slot_four: updatedReminders?.[3],
        }
        Mixpanel.track('click_change_reminder', {
            event_action: 'clicked',
            reminder_time_slots: reminderToUpdate,
            event_page: 'manage_reminder_page',
        });
        updateUser(user?.uid || userData?.uid, {'userPreferences.managedReminders': reminderToUpdate}).then(() => {
        });
    }
    const onKarmaPointsClickHandler = () => {
        Mixpanel.track('click_karma_points_badge', {
            event_action: 'clicked',
            context_user_id: user?.uid,
            total_karma_points: user?.total_karma || 0,
            event_page: 'home_screen',
        });

        navigate('/karma', {state: {email: user?.email, total_karma: user?.total_karma}, replace: false})
    }

    const onBackPress = () => {
        if (location.key === 'default') {
            window.location.href = 'https://seekdaily.live/';
        } else {
            navigate(-1);
        }
    }

    return (
        <div className={'manage-reminder-container'}>
            <Header onClick={onKarmaPointsClickHandler} logoUrl={seek_logo_url} karma_points={user?.total_karma}/>
            <div style={{width: '90%', maxWidth: '400px', marginTop: '24px'}}>
                <BackButton onClick={onBackPress}/>
            </div>
            <div className={'manage-reminder-body-container'}>
                <div className={'manage-reminder-title'}>Manage Reminders</div>
                <div className={'manage-reminder-note'} style={{marginTop: '2px'}}>Note: Reminders will be sent on
                    WhatsApp
                </div>
                {
                    loader ? <Spinner style={{
                        color: COLORS.PRIMARY,
                        alignSelf: 'center',
                        marginTop: '40%'
                    }}></Spinner> : managedAvailableClassSlots?.map((slot: any, index: number) => <ToggleComponent
                        index={index}
                        selectedSlotCount={selectedSlotCount} onToggleSlot={onToggleSlotHandler}
                        slotValue={slot?.slotValue}
                        userManagedReminders={userManagedReminders} title={slot?.title} subtitle={slot?.subtitle}/>)
                }
            </div>
            {/*<Button isSticky={true} style={{alignSelf: 'center', marginBottom: '24px'}} onClick={onSlotUpdateHandler}*/}
            {/*        label={'Change Reminder'}></Button>*/}
        </div>
    )
}

export default ManageReminders;
