// AppLayout.js

import React from 'react';
import './GreyAppLayout.css'; // Import the stylesheet for styling

const GreyAppLayout = ({ children }) => {
    return (
        <div className="grey-app-layout">
            <div className="grey-background-image" />
            {children}
        </div>
    );
};

export default GreyAppLayout;
