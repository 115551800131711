import mixpanel from 'mixpanel-browser';
// TODO update mixpanelID manually for dev-prd , automate it later.
const MixpanelDevId = '0f9d7765f5e2a852b1e298f7fe4b52b8';
const MixpanelPrdId = 'de437f939c1eaaf7548b735d4a603c8e';

mixpanel.init(MixpanelPrdId);
let env_check = true;
let actions = {
    identify: (id) => {
        if (env_check) mixpanel.identify(id);
    },
    resetIdentity:() =>{
        if (env_check) mixpanel.reset();
    },
    alias: (id) => {
        if (env_check) mixpanel.alias(id);
    },
    track: (name, props) => {
        if (env_check) mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            if (env_check) mixpanel.people.set(props);
        },
    },

};

export let Mixpanel = actions;
