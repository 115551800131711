import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './WeeklyCourseTabView.css'
import React, {useEffect, useState} from "react";
import {Mixpanel} from "../../../analytics/Mixpanel";
import BestOutOfItIconWithName from "../../../components-v2/rows/BestOutOfItIconWithName";
import {
    CourseModuleCardCollapsedNew, CourseSingleWeekModuleCardCollapsedNew,
    CustomeIconWithTitle,
} from "../../../components-v2/rows/CourseStructureCard";
import {ReactComponent as DocIcon} from "../../../assets/docs.svg";
import {ReactComponent as PlayIcon} from "../../../assets/new-icons/no-of-class.svg";
import {CourseReviews, groupByWeek, LANDING_COMPONENT, sortItemsByKey} from "../../../utils/utils";
import KnowYourInstructor from "../../instructor/KnowYourInstructor";
import enroll_one from "../../../assets/webp-images/pointer-one.webp";
import enroll_two from "../../../assets/webp-images/pointer-two.webp";
import enroll_three from "../../../assets/webp-images/pointer-three.webp";
import TestimonialsVideoSlider from "../../plans/components/TestimonialsVideoSlider";
import CustomerReview from "../../../components-v2/rows/CustomerReview";


interface CardComponentProps {
    title: string;
    name: string;
    no_of_classes: number;
    number_of_self_practices_exercises: number;
    description: string;

}

const CardComponent: React.FC<CardComponentProps> = ({
                                                         title,
                                                         name,
                                                         no_of_classes,
                                                         number_of_self_practices_exercises,
                                                         description
                                                     }) => {
    return (
        <div className="vertical-aligned-component">
            <span className={'course-title'}>{title}</span>
            <div className="info">
                <span className="tab-name">{name}</span>
                <span className="description">{description}</span>
            </div>
            {/*<CustomeIconWithTitle title="Sample Title" Icon={CustomSVGIcon} />*/}
            <CustomeIconWithTitle title={no_of_classes+""} Icon={PlayIcon}></CustomeIconWithTitle>
            <CustomeIconWithTitle title={number_of_self_practices_exercises+""}
                                  Icon={DocIcon}></CustomeIconWithTitle>
        </div>
    );
};


interface TriggeredEvents {
    [key: string]: Record<string, boolean>;
}

const tabNames = ['Overview', 'Schedule', 'Instructor'];

const sectionRangesOverview = {
    section1: {start: 300, end: 500, section_heading: 'What’s Included'},
    section2: {start: 500, end: 2200, section_heading: 'Course Structure'},
    section3: {start: 2200, end: 2600, section_heading: 'Benefits'},
    section4: {start: 2600, end: 3500, section_heading: 'What do they say?'}, // 5200-5800
    section5: {start: 3500, end: 3800, section_heading: 'Who’s it For?'}, //
    section6: {start: 3800, end: 4800, section_heading: 'What happens when you enrol?'},
    // Add other sections as needed
} as { [key: string]: { start: number; end: number; section_heading: string } };



const sectionRangesSchedule = {
    section1: {start: 300, end: 1200, section_heading: 'Weekly course module'},
    section4: {start: 1200, end: 2000, section_heading: 'What do they say?'}, // 5200-5800
    section5: {start: 2000, end: 2300, section_heading: 'Who’s it For?'}, //
    // Add other sections as needed
} as { [key: string]: { start: number; end: number; section_heading: string } };

const sectionRangesInstructor = {
    section1: {start: 300, end: 1500, section_heading: 'Know your instructor'},
    section4: {start: 1500, end: 2400, section_heading: 'What do they say?'}, // 5200-5800
    section5: {start: 2400, end: 3600, section_heading: 'Who’s it For?'}, //
    // Add other sections as needed
} as { [key: string]: { start: number; end: number; section_heading: string } };

interface WeeklyCourseTabViewType {
    calendarItem: any,
}

const WeeklyCourseTabView: React.FC<WeeklyCourseTabViewType> = ({calendarItem}) => {
    // console.log("WeeklyCourseTabView calendarItem", calendarItem)
    const [activeTab, setActiveTab] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [reviews, setReviews] = useState(CourseReviews);
    const [showMore, setShowMore] = useState(false);
    const [showText, setShowText] = useState('Show More')
    const groupedSchedule: any = groupByWeek(calendarItem?.course_weekly_schedule);
    // console.log("groupedSchedule", groupedSchedule);

    const sectionRanges = [sectionRangesOverview, sectionRangesSchedule, sectionRangesInstructor];



    const initialTriggeredEvents: TriggeredEvents = {
        Overview: { section1: false, section2: false, section3: false, section4: false, section5: false, section6: false },
        Schedule: { section1: false, section4: false, section5: false },
        Instructor: { section1: false, section4: false, section5: false },
    };
    const [triggeredEvents, setTriggeredEvents] = useState<TriggeredEvents>(initialTriggeredEvents);
    const markMixPanelEvent = (position: number) => {
        const currentSectionRanges = sectionRanges[activeTab];
        const currentTabName = tabNames[activeTab];

        const checkAndTriggerEvent = (sectionKey: string, start: number, end: number) => {
            if (!triggeredEvents[currentTabName][sectionKey] && position >= start - 10 && position <= end + 10) {
                Mixpanel.track('view_course_landing_page_section', {
                    event_action: 'viewed',
                    // event_section: sectionKey,
                    section_heading: currentSectionRanges[sectionKey].section_heading,
                    event_page: 'course_landing_page',
                    course_name: calendarItem?.title,
                });
                setTriggeredEvents(prevEvents => ({
                    ...prevEvents,
                    [currentTabName]: { ...prevEvents[currentTabName], [sectionKey]: true },
                }));
            }
        };

        Object.entries(currentSectionRanges).forEach(([sectionKey, { start, end }]) => {
            checkAndTriggerEvent(sectionKey, start, end);
        });
    };


    useEffect(() => {
        const handleScroll = () => {
            markMixPanelEvent(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [triggeredEvents, activeTab]);



    // const markMixPanelEvent = (position: number) => {
    //     const checkAndTriggerEvent = (sectionKey: string, start: number, end: number) => {
    //         if (!triggeredEvents[sectionKey] && position >= start - 10 && position <= end + 10) {
    //             Mixpanel.track('view_course_landing_page_section', {
    //                 event_action: 'viewed',
    //                 event_section: sectionKey,
    //                 section_heading: sectionRangesOverview[sectionKey].section_heading,
    //                 event_page: 'course_landing_page',
    //                 course_name: calendarItem?.title,
    //             });
    //             setTriggeredEvents((prevEvents: any) => ({...prevEvents, [sectionKey]: true}));
    //         }
    //     };
    //
    //     Object.entries(sectionRangesOverview).forEach(([sectionKey, {start, end}]) => {
    //         checkAndTriggerEvent(sectionKey, start, end);
    //     });
    // };



    useEffect(() => {
        if (showMore) {
            setReviews(CourseReviews);
            setShowText('Show Less')
        } else {
            setShowText('Show More')
            setReviews(CourseReviews.slice(0, 3)); // Show only the first 3 reviews
        }
    }, [showMore, CourseReviews]);

    const handleTabSelect = (index: number) => {
        setActiveTab(index);
        console.log("Tab selected:", index);
        if (index >= 0 && index < tabNames.length) {
            Mixpanel.track('click_course_info_tabs', {
                event_action: 'clicked',
                course_info_tabs: tabNames[index],
                event_page: 'course_landing_page',
            });
        }
    };

    const onClickShowMoreReviews = () => {
        setShowMore(prevState => !prevState)
        Mixpanel.track('click_show_more_reviews', {
            event_action: 'clicked',
            event_page: 'plan_landing_page',
        });
    }


    return (
        <Tabs onSelect={handleTabSelect}>
            <TabList className={'weekly-course-tab-list'}>
                <Tab>Overview</Tab>
                <Tab>Schedule</Tab>
                <Tab>Instructor</Tab>
            </TabList>

            <TabPanel>
                <div style={{marginTop: '24px'}}>
                    <span className={'page-section-title'}>What’s Included</span>
                    {calendarItem?.whats_included && sortItemsByKey(calendarItem.whats_included).map((item: any, index) => (
                        <BestOutOfItIconWithName key={index} index={index} name={item}/>
                    ))}

                    <div className={'page-section-title'}>Course Structure
                    </div>
                    {calendarItem?.course_structure?.map((item: any, index: number) => (
                        <CardComponent no_of_classes={item?.number_of_classes}
                                       number_of_self_practices_exercises={item?.number_of_self_practices_exercises}
                                       description={item?.description} name={item?.name} title={item.module}/>
                    ))}

                    <div className={'page-section-title'}>Benefits</div>
                    {calendarItem?.benefits && sortItemsByKey(calendarItem.benefits).map((item: any, index) => (
                        <BestOutOfItIconWithName key={index} index={index} name={item}/>
                    ))}

                    <div className={'horizontal-container'}
                         style={{justifyContent: 'space-between', alignItems: 'center', width: '343px'}}>
                        <span className={'page-section-title'}>What do they say?</span>
                        {/*<span className={'plan-description-review-count'}>320+ Reviews</span>*/}
                    </div>
                    {/*<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>*/}
                    {/*    <TestimonialsVideoSlider*/}
                    {/*        pageName={'course_overview'}*/}
                    {/*        style={{*/}
                    {/*            width: '364px',*/}
                    {/*            height: '208px',*/}
                    {/*            marginTop: '8px',*/}
                    {/*        }} naturalSlideHeight={256} naturalSlideWidth={240}*/}
                    {/*        component_name={LANDING_COMPONENT.TESTIMONIAL}/>*/}
                    {/*</div>*/}

                    <div style={{width: '95%', alignSelf: 'center'}}>
                        {reviews?.map((review: any, index: number) => <CustomerReview count={reviews?.length}
                                                                                      index={index}
                                                                                      imageSrc={review.imageSrc}
                                                                                      name={review.name}
                                                                                      period={review.period}
                                                                                      bio={review.bio}
                                                                                      rating={review.rating}/>)}
                    </div>


                    <div className={'horizontal-container'}
                         style={{width: '343px', alignItems: 'center', justifyContent: 'start', marginTop: '14px'}}
                         onClick={onClickShowMoreReviews}>
                        <span style={{
                            color: "#000",
                            fontFamily: "Gordita-Medium",
                            fontSize: "12px",
                            fontStyle: "normal",
                            textDecoration:'underline',
                            fontWeight: 500,
                            alignSelf: 'start',
                            textAlign:'start',
                            lineHeight: "18px" // or "150%"
                        }}>{showText}</span>
                        {showMore ?
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 width="24" height="24" viewBox="0 0 20 20" fill="none">
                                <path
                                    d="M10.0002 9.00003L13.4202 12.141C13.5503 12.2699 13.7261 12.3422 13.9092 12.3422C14.0923 12.3422 14.2681 12.2699 14.3982 12.141C14.4625 12.0778 14.5135 12.0023 14.5483 11.9192C14.5831 11.836 14.6011 11.7467 14.6011 11.6565C14.6011 11.5664 14.5831 11.4771 14.5483 11.3939C14.5135 11.3107 14.4625 11.2353 14.3982 11.172L10.4902 7.34203C10.3598 7.21336 10.1839 7.14121 10.0007 7.14121C9.81749 7.14121 9.64164 7.21336 9.51121 7.34203L5.60321 11.172C5.53896 11.2353 5.48794 11.3107 5.45311 11.3939C5.41828 11.4771 5.40035 11.5664 5.40035 11.6565C5.40035 11.7467 5.41828 11.836 5.45311 11.9192C5.48794 12.0023 5.53896 12.0778 5.60321 12.141C5.73335 12.2699 5.90908 12.3422 6.09221 12.3422C6.27534 12.3422 6.45108 12.2699 6.58121 12.141L10.0002 9.00003Z"
                                    fill="#534DE0"/>
                            </svg> :
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                                 fill="none">
                                <path d="M12.5 6.25L8 10.75L3.5 6.25" stroke="#1B1625" stroke-width="1.5"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        }
                    </div>

                    <div style={{height:'32px'}}></div>
                    <span className={'page-section-title'}>Who is it for ?</span>
                    <div style={{width: '100%'}}>
                        {calendarItem?.who_is_it_for && sortItemsByKey(calendarItem.who_is_it_for).map((item: any, index) => (
                            <BestOutOfItIconWithName key={index} index={index} name={item}/>
                        ))}
                    </div>
                    <div style={{marginTop: '24px'}}>
                        <span className={'page-section-title'}>What happens when you enrol?</span>
                        <img
                            src={enroll_one}
                            alt=""
                            style={{
                                width: '343px',
                                height: 'auto',
                                marginTop: '8px',
                                objectFit: 'cover'
                            }}
                        />
                        <img
                            src={enroll_two}
                            alt=""
                            style={{
                                width: '343px',
                                height: 'auto',
                                marginTop: '16px',
                                objectFit: 'cover'
                            }}
                        />
                        <img
                            src={enroll_three}
                            alt=""
                            style={{
                                width: '343px',
                                height: 'auto',
                                marginTop: '16px',
                                objectFit: 'cover'
                            }}
                        />
                    </div>

                </div>
            </TabPanel>
            <TabPanel>

                {groupedSchedule && Object.keys(groupedSchedule).length > 1 && Object.keys(groupedSchedule).map((week, index: number) => (
                    <CourseModuleCardCollapsedNew
                        index={index}
                        selectedIndex={selectedIndex}
                        key={index}
                        setSelectedIndex={setSelectedIndex}
                        moduleTitle={`Week ${week}`}
                        weekDays={groupedSchedule[week]}
                    />
                ))}

                {groupedSchedule && Object.keys(groupedSchedule).length === 1 &&  Object.keys(groupedSchedule).map((week, index: number) => (
                    <CourseSingleWeekModuleCardCollapsedNew
                        index={index}
                        selectedIndex={selectedIndex}
                        key={index}
                        setSelectedIndex={setSelectedIndex}
                        moduleTitle={`Week ${week}`}
                        weekDays={groupedSchedule[week]}
                    />
                ))}

                <div className={'horizontal-container'}
                     style={{justifyContent: 'space-between', alignItems: 'center', width: '343px'}}>
                    <span className={'page-section-title'}>What do they say?</span>
                    {/*<span className={'plan-description-review-count'}>320+ Reviews</span>*/}
                </div>
                {/*<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>*/}
                {/*    <TestimonialsVideoSlider*/}
                {/*        pageName={'course_schedule'}*/}
                {/*        style={{*/}
                {/*            width: '364px',*/}
                {/*            height: '208px',*/}
                {/*            marginTop: '24px',*/}
                {/*        }} naturalSlideHeight={256} naturalSlideWidth={240}*/}
                {/*        component_name={LANDING_COMPONENT.TESTIMONIAL}/>*/}
                {/*</div>*/}

                {
                    reviews?.map((review: any, index: number) => <CustomerReview count={reviews?.length} index={index}
                                                                                 imageSrc={review.imageSrc}
                                                                                 name={review.name}
                                                                                 period={review.period}
                                                                                 bio={review.bio}
                                                                                 rating={review.rating}/>)
                }

                <div className={'horizontal-container'}
                     style={{width: '343px', alignItems: 'center', justifyContent: 'start', marginTop: '14px'}}
                     onClick={onClickShowMoreReviews}>
                <span style={{
                    color: "#000",
                    fontFamily: "Gordita-Medium",
                    fontSize: "12px",
                    fontStyle: "normal",
                    textDecoration: 'underline',
                    fontWeight: 500,
                    alignSelf: 'start',
                    textAlign: 'start',
                    lineHeight: "18px" // or "150%"
                }}>{showText}</span>
                    {showMore ?
                        <svg xmlns="http://www.w3.org/2000/svg"
                             width="24" height="24" viewBox="0 0 20 20" fill="none">
                            <path
                                d="M10.0002 9.00003L13.4202 12.141C13.5503 12.2699 13.7261 12.3422 13.9092 12.3422C14.0923 12.3422 14.2681 12.2699 14.3982 12.141C14.4625 12.0778 14.5135 12.0023 14.5483 11.9192C14.5831 11.836 14.6011 11.7467 14.6011 11.6565C14.6011 11.5664 14.5831 11.4771 14.5483 11.3939C14.5135 11.3107 14.4625 11.2353 14.3982 11.172L10.4902 7.34203C10.3598 7.21336 10.1839 7.14121 10.0007 7.14121C9.81749 7.14121 9.64164 7.21336 9.51121 7.34203L5.60321 11.172C5.53896 11.2353 5.48794 11.3107 5.45311 11.3939C5.41828 11.4771 5.40035 11.5664 5.40035 11.6565C5.40035 11.7467 5.41828 11.836 5.45311 11.9192C5.48794 12.0023 5.53896 12.0778 5.60321 12.141C5.73335 12.2699 5.90908 12.3422 6.09221 12.3422C6.27534 12.3422 6.45108 12.2699 6.58121 12.141L10.0002 9.00003Z"
                                fill="#534DE0"/>
                        </svg> :
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path
                                d="M10 11L13.42 7.859C13.5501 7.73015 13.7259 7.65787 13.909 7.65787C14.0921 7.65787 14.2679 7.73015 14.398 7.859C14.4622 7.92227 14.5133 7.9977 14.5481 8.08087C14.5829 8.16405 14.6009 8.25333 14.6009 8.3435C14.6009 8.43368 14.5829 8.52295 14.5481 8.60613C14.5133 8.68931 14.4622 8.76473 14.398 8.828L10.49 12.658C10.3596 12.7867 10.1837 12.8588 10.0005 12.8588C9.81728 12.8588 9.64143 12.7867 9.511 12.658L5.603 8.828C5.53875 8.76473 5.48773 8.68931 5.4529 8.60613C5.41807 8.52295 5.40014 8.43368 5.40014 8.3435C5.40014 8.25333 5.41807 8.16405 5.4529 8.08087C5.48773 7.99769 5.53875 7.92227 5.603 7.859C5.73313 7.73015 5.90886 7.65787 6.092 7.65787C6.27513 7.65787 6.45086 7.73015 6.581 7.859L10 11Z"
                                fill="#534DE0"/>
                        </svg>
                    }
                </div>
                <div style={{height: '32px'}}></div>
                <span className={'page-section-title'}>Who is it for ?</span>
                <div style={{width: '100%'}}>
                    {calendarItem?.who_is_it_for && Object.values(calendarItem?.who_is_it_for).map((item: any, index) => (
                        <BestOutOfItIconWithName key={index} index={index} name={item}/>
                    ))}
                </div>


            </TabPanel>
            <TabPanel>
                <KnowYourInstructor calendarItem={calendarItem}/>
                <div className={'horizontal-container'}
                     style={{justifyContent: 'space-between', alignItems: 'center', width: '343px'}}>
                    <span className={'page-section-title'}>What do they say?</span>
                    {/*<span className={'plan-description-review-count'}>320+ Reviews</span>*/}
                </div>
                {/*<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>*/}
                {/*    <TestimonialsVideoSlider*/}
                {/*        pageName={'course_instructor'}*/}
                {/*        style={{*/}
                {/*            width: '364px',*/}
                {/*            height: '208px',*/}
                {/*            marginTop: '24px',*/}
                {/*        }} naturalSlideHeight={256} naturalSlideWidth={240}*/}
                {/*        component_name={LANDING_COMPONENT.TESTIMONIAL}/>*/}
                {/*</div>*/}
                {
                    reviews?.map((review: any, index: number) => <CustomerReview count={reviews?.length} index={index}
                                                                                 imageSrc={review.imageSrc}
                                                                                 name={review.name}
                                                                                 period={review.period}
                                                                                 bio={review.bio}
                                                                                 rating={review.rating}/>)
                }

                <div className={'horizontal-container'}
                     style={{width: '343px', alignItems: 'center', justifyContent: 'start', marginTop: '14px'}}
                     onClick={onClickShowMoreReviews}>
                <span style={{
                    color: "#000",
                    fontFamily: "Gordita-Medium",
                    fontSize: "12px",
                    fontStyle: "normal",
                    textDecoration: 'underline',
                    fontWeight: 500,
                    alignSelf: 'start',
                    textAlign: 'start',
                    lineHeight: "18px" // or "150%"
                }}>{showText}</span>
                    {showMore ?
                        <svg xmlns="http://www.w3.org/2000/svg"
                             width="24" height="24" viewBox="0 0 20 20" fill="none">
                            <path
                                d="M10.0002 9.00003L13.4202 12.141C13.5503 12.2699 13.7261 12.3422 13.9092 12.3422C14.0923 12.3422 14.2681 12.2699 14.3982 12.141C14.4625 12.0778 14.5135 12.0023 14.5483 11.9192C14.5831 11.836 14.6011 11.7467 14.6011 11.6565C14.6011 11.5664 14.5831 11.4771 14.5483 11.3939C14.5135 11.3107 14.4625 11.2353 14.3982 11.172L10.4902 7.34203C10.3598 7.21336 10.1839 7.14121 10.0007 7.14121C9.81749 7.14121 9.64164 7.21336 9.51121 7.34203L5.60321 11.172C5.53896 11.2353 5.48794 11.3107 5.45311 11.3939C5.41828 11.4771 5.40035 11.5664 5.40035 11.6565C5.40035 11.7467 5.41828 11.836 5.45311 11.9192C5.48794 12.0023 5.53896 12.0778 5.60321 12.141C5.73335 12.2699 5.90908 12.3422 6.09221 12.3422C6.27534 12.3422 6.45108 12.2699 6.58121 12.141L10.0002 9.00003Z"
                                fill="#534DE0"/>
                        </svg> :
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path
                                d="M10 11L13.42 7.859C13.5501 7.73015 13.7259 7.65787 13.909 7.65787C14.0921 7.65787 14.2679 7.73015 14.398 7.859C14.4622 7.92227 14.5133 7.9977 14.5481 8.08087C14.5829 8.16405 14.6009 8.25333 14.6009 8.3435C14.6009 8.43368 14.5829 8.52295 14.5481 8.60613C14.5133 8.68931 14.4622 8.76473 14.398 8.828L10.49 12.658C10.3596 12.7867 10.1837 12.8588 10.0005 12.8588C9.81728 12.8588 9.64143 12.7867 9.511 12.658L5.603 8.828C5.53875 8.76473 5.48773 8.68931 5.4529 8.60613C5.41807 8.52295 5.40014 8.43368 5.40014 8.3435C5.40014 8.25333 5.41807 8.16405 5.4529 8.08087C5.48773 7.99769 5.53875 7.92227 5.603 7.859C5.73313 7.73015 5.90886 7.65787 6.092 7.65787C6.27513 7.65787 6.45086 7.73015 6.581 7.859L10 11Z"
                                fill="#534DE0"/>
                        </svg>
                    }
                </div>
                <div style={{height: '32px'}}></div>
                <span className={'page-section-title'}>Who is it for ?</span>
                <div style={{width: '100%'}}>
                    {calendarItem?.who_is_it_for && Object.values(calendarItem?.who_is_it_for).map((item: any, index) => (
                        <BestOutOfItIconWithName key={index} index={index} name={item}/>
                    ))}
                </div>


            </TabPanel>
        </Tabs>
    )
}

export default WeeklyCourseTabView;
