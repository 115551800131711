import React, {memo, useCallback, useEffect, Suspense, lazy} from 'react'
import {useSelector, useDispatch} from "react-redux";
import '../home/components/AccountCreated.css'
import 'react-lazy-load-image-component/src/effects/blur.css';
import {
    getCurrentStreamingPlanByUserid,
    getLeaderBoardUsers, getUserByUserId,
} from "../../services/Firebase";


import {useNavigate} from "react-router-dom";
import './Home.css'


import seek_logo_url from "../../assets/seek-logo.svg";

import {
    getUserListWithSpecialUser, isEmpty,
} from "../../utils/utils";
import {Mixpanel} from "../../analytics/Mixpanel";
import dayjs from "dayjs";

import {
    updateDaysToExpire,
    updateUsersPlan
} from "../../store/features/PlanSlice";
import {
    setLeaderboardUsers,
    setTopFourLeaderboardUsers
} from "../../store/features/LeaderboardSlice";
import useAuthenticatedUsers from "../../hooks/useAuthenticatedUsers";
import moment from "moment-timezone";
import ClassCard from "../../components-v2/favourite-class/ClassCard";
import Header from "../../components-v2/header/Header";
import ViewAll from "../../components-v2/chips/ViewAll";
import ShimmerLoader from "../../components-v2/shimmer/Shimmer";
import {addUsers, setReloadPlan} from "../../store/features/AuthSlice";

const LeaderNudge = lazy(() => import("../leaderboard/components/LeaderNudge"));


const Home = memo(() => {
    const userData = useAuthenticatedUsers(false)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state?.auth?.user?.data)
    const leaderboardUsers = useSelector((state: any) => state?.leaderboard?.data?.leaderboardUsers)
    const topFourLeaderboardUsers = useSelector((state: any) => state?.leaderboard?.data?.topFourLeaderboardUsers)
    const currentUserLeaderboardStats = leaderboardUsers?.find((leaderboardUser: any) => leaderboardUser?.email === user?.email);
    const reloadPlan = useSelector((state: any) => state?.auth?.user?.reloadPlan);
    // console.log("reloadPlan home",reloadPlan)
    const upcomingCourses = useSelector((state: any) => state.upcomingClasses?.upcomingCourse?.data);
    const favouriteClasses = useSelector((state: any) => state.upcomingClasses?.favouriteClasses?.data);
    const activeStreamingPlan = useSelector((state: any) => state.plan?.userPlan?.activeStreamingPlan);
    const daysToExpire = useSelector((state: any) => state.plan?.userPlan?.daysToExpire);

    useEffect(() => {
        if (isEmpty(user) && userData?.uid) {
            getUserByUserId(userData?.uid).then((userLoginResult) => {
                dispatch(addUsers(userLoginResult?.userData))
                Mixpanel.identify(userData?.uid)
            })
        }
    }, [userData])


    useEffect(() => {
        if ((isEmpty(activeStreamingPlan) && (userData?.uid||user?.uid)) || (reloadPlan && (userData?.uid||user?.uid))) {
            const streamingPlanPromise = getCurrentStreamingPlanByUserid(userData.uid||user?.uid);
            Promise.all([streamingPlanPromise])
                .then(([activePlanRes]) => {
                    if (activePlanRes.activePlan != null) {
                        // console.log("activePlanRes home 1", activePlanRes.activePlan?.userLiveStreamingPlans[0]);
                        const formattedEndDate = dayjs(new Date(activePlanRes.activePlan?.userLiveStreamingPlans[0]?.endDate?._seconds * 1000))?.format('MMM DD YYYY');
                        const todayDate = dayjs().format('MMM DD YYYY');
                        const date1 = dayjs(formattedEndDate);
                        const date2 = dayjs(todayDate);
                        const daysDifference = date1.diff(date2, 'day');
                        dispatch(updateDaysToExpire(daysDifference));
                        dispatch(updateUsersPlan(activePlanRes.activePlan?.userLiveStreamingPlans[0]));
                        dispatch(setReloadPlan(false));
                    }
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [userData,reloadPlan]);


    const onKarmaPointsClickHandler = useCallback(() => {
        Mixpanel.track('click_karma_points_badge', {
            event_action: 'clicked',
            context_user_id: user?.uid,
            total_karma_points: user?.total_karma || 0,
            event_page: 'home_screen',
        });
        navigate('/karma', {state: {email: user?.email, total_karma: user?.total_karma}, replace: false});
    }, [user, navigate]);

    const onLeaderboardClickHandler = useCallback(() => {
        Mixpanel.track('click_weekly_leaderboard', {
            event_action: 'clicked',
            rank: currentUserLeaderboardStats?.rank,
            weekly_karma_points: currentUserLeaderboardStats?.total_karma,
            event_page: 'homescreen',
        });
        navigate('/leaderboard', {state: {user}, replace: false});
    }, [user, navigate]);


    useEffect(() => {
        if (!leaderboardUsers || leaderboardUsers.length === 0) {
            const today = moment();
            const currentDay = today.day();
            const diff = today.date() - currentDay + (currentDay === 0 ? -6 : 1);

            // Calculate the start date
            const startDate = moment(today).date(diff);

            // Reset today to the original value
            today.subtract(7, 'days');

            // Calculate the end date by adding 6 days to the start date
            const endDate = moment(startDate).add(6, 'days');
            if (startDate.isBefore(endDate) && today.isSameOrBefore(endDate)) {
                const parsedStartDate = startDate.format('YYYY-MM-DD');
                const parsedEndDate = endDate.format('YYYY-MM-DD');
                // dispatch(setReload(false));
                getLeaderBoardUsers(null, parsedStartDate, parsedEndDate)
                    .then((result) => {
                        const topFourLeaderboardUsers = getUserListWithSpecialUser(result?.users, userData?.phone);
                        // console.log("making api call to fetch weekly leaderboard users", topFourLeaderboardUsers);
                        dispatch(setLeaderboardUsers(result?.users));
                        dispatch(setTopFourLeaderboardUsers(topFourLeaderboardUsers));
                    })
                    .catch((error) => {
                        console.error("Error fetching leaderboard users:", error);
                    });
            }
        }
    }, [leaderboardUsers, userData]);


    const onFavouriteClassClickHandler = useCallback((favouriteClass: any) => {
        Mixpanel.track('click_favourite_classes', {
            event_action: 'clicked',
            class_name: favouriteClass?.title,
            instructor_name: favouriteClass?.instructor,
            event_page: 'homescreen',
        });

        navigate('/favourite-classes', {
            state: {
                favouriteClass: favouriteClass,
            }
        });
    }, [navigate]);



    return (
        <div className={'home-container'}>
            <Header onClick={onKarmaPointsClickHandler} logoUrl={seek_logo_url} karma_points={user?.total_karma}/>
            <div className={'home-component-wrapper'} style={{backgroundColor: 'white'}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: '16px',
                    maxWidth: '400px',
                    width: '100%'
                }}>
                        <span style={{
                            color: '#1B1625',
                            fontFamily: 'Galaxie-Copernicus-Book',
                            fontSize: '20px',
                            width: '100%',
                            alignSelf: 'start',
                            textAlign: 'start',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '28px',
                        }}>Favourite Classes</span>
                </div>
                {(!favouriteClasses || favouriteClasses.length === 0) ? (
                    <>
                        <ShimmerLoader/>
                        <ShimmerLoader/>
                    </>
                ) : (
                    favouriteClasses.map((favouriteClass: any, index: number) => (
                        <ClassCard
                            key={index}
                            favouriteClass={favouriteClass}
                            onClick={onFavouriteClassClickHandler}
                            imageUrl={favouriteClass?.thumbnail}
                            title={favouriteClass?.title}
                            subtitle={"By " + favouriteClass?.instructor}
                        />
                    ))
                )}
            </div>

            <div className={'home-component-wrapper'} style={{background: 'white'}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: '16px',
                    maxWidth: '400px',
                    width: '100%'
                }}>
                        <span style={{
                            color: '#1B1625',
                            fontFamily: 'Galaxie-Copernicus-Book',
                            fontSize: '20px',
                            width: '100%',
                            alignSelf: 'start',
                            textAlign: 'start',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '28px',
                        }}>Weekly Leaderboard</span>
                </div>
                <Suspense fallback={<ShimmerLoader/>}>
                    <LeaderNudge currentUserLeaderboardStats={currentUserLeaderboardStats}
                                 onClick={onLeaderboardClickHandler} phone={user?.phone || userData?.phone}
                                 leaderboardUsers={topFourLeaderboardUsers}/>
                </Suspense>
                <div style={{width: '90%', maxWidth: '400px', marginTop: '12px'}}>
                    <ViewAll onClick={onLeaderboardClickHandler}/>
                </div>

            </div>


        </div>
    )
})

export default Home;

