import React, { useEffect } from 'react';
import './BottomSheet.css';

interface BottomSheetProps {
	show: boolean;
	onHide: () => void;
	title: string;
	content: React.ReactNode;
	showHeader?: boolean;
	customStyle?: any;
}

const BottomSheet: React.FC<BottomSheetProps> = ({
	show,
	onHide,
	title,
	customStyle,
	content,
	showHeader = true,
}) => {
	useEffect(() => {
		if (show) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'initial';
		}

		return () => {
			document.body.style.overflow = 'initial';
		};
	}, [show]);
	return (
		<div className={`bottom-sheet-container ${show ? 'open' : ''}`}>
			<div className='backdrop' onClick={onHide}></div>
			<div className='bottom-sheet' style={customStyle}>
				<div className='bottom-sheet-content'>{content}</div>
			</div>
		</div>
	);
};

export default BottomSheet;
