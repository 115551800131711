import React, {memo, useEffect, useState} from 'react';
import './PlanVariant.css';
import {ReactComponent as RecommendedTag} from "../../../assets/recommended.svg";
import {COLORS} from "../../../utils/colors";
import CustomCard from "../../../components/card/CustomCard";
import Button from "../../../components/button/Button";
import {ReactComponent as OfferIcon} from "../../../assets/offer.svg";
import {ReactComponent as BestOfThisClassIcon} from "../../../assets/best_of_this_class.svg";


interface CircularCheckboxProps {
    title: string;
    subtitle: string;
    index: number,
    selectedIndex: number,
}

interface CircularCheckboxProps {
    title: string;
    subtitle: string;
    index: number;
    selectedIndex: number;
}

const CircularRadioButton: React.FC<CircularCheckboxProps> = ({title, subtitle, index, selectedIndex}) => {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(index === selectedIndex);
    }, [index, selectedIndex]);


    return (
        <div className="price-container">
            <div className="price-left-container">
                <div className={`${isChecked ? 'price-radio-selected' : 'price-radio'}`}></div>
                <span className="price-title">{title}<span>{title === '01' ? ' Month':' Months'}</span></span>
            </div>
            <span className={`${isChecked ? 'price-subtitle-selected':'price-subtitle'}`}>{`₹ ${subtitle}`}</span>
        </div>
    );
};


interface PlanVariantProps {
    strike_price: number,
    extra_offer: number,
    free_extension: string,
    name: string;
    monthly_price: number;
    total_price: number;
    duration: number;
    recommended: boolean;
    type: string;
    index: number;
    selectedIndex: number;
    onClick: (index: number) => void;
    pause_limit: number;
    setShowPauseBanner: any;
    count: number;

}

const PlanVariant: React.FC<PlanVariantProps> = ({
                                                     count,
                                                     setShowPauseBanner,
                                                     pause_limit,
                                                     strike_price,
                                                     extra_offer,
                                                     free_extension,
                                                     name,
                                                     monthly_price,
                                                     total_price,
                                                     duration,
                                                     recommended,
                                                     type,
                                                     index,
                                                     selectedIndex,
                                                     onClick
                                                 }) => {
    return (
        <CustomCard onClick={onClick.bind(null, index)} style={{
            height: 'auto',
            borderRadius: '12px',
            width: '95%',
            maxWidth: '354px',
            backgroundColor: COLORS.WHITE,
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            border: index === selectedIndex ? '1px solid var(--Primary-Gradient, #4462EF)' : '1px solid var(--Light-Grey-2, #E4E4E4)'
        }}>
            <div className={`plan-variants-row`}>
                <CircularRadioButton index={index} selectedIndex={selectedIndex} title={`${name?.split(' ')?.[0]}`}
                                     subtitle={`${total_price}`}/>
                {recommended &&
                    <RecommendedTag style={{position: 'absolute', right: 10, top: -12}} width={103} height={24}/>}


                {/*{index != count - 1 &&*/}
                {/*    <svg style={{marginTop: '6px'}} xmlns="http://www.w3.org/2000/svg" width="321" height="2"*/}
                {/*         viewBox="0 0 321 2" fill="none">*/}
                {/*        <path d="M1 1H320" stroke="#E4E4E4" stroke-width="0.64" stroke-linecap="round"*/}
                {/*              stroke-dasharray="4 4"/>*/}
                {/*    </svg>}*/}

                {/*{free_extension && <div style={{*/}
                {/*    flexDirection: 'row',*/}
                {/*    display: 'flex',*/}
                {/*    width: '100%',*/}
                {/*    justifyContent: 'start',*/}
                {/*    alignItems: 'center',*/}
                {/*    marginLeft: '0px',*/}
                {/*    marginTop: '10px'*/}
                {/*}}>*/}
                {/*    <OfferIcon height={24} width={24}></OfferIcon>*/}
                {/*    <span className={"plan-variants-offer"}>{`Free ${free_extension} extra`}</span>*/}
                {/*</div>}*/}

                {/*{extra_offer && <div style={{*/}
                {/*    flexDirection: 'row',*/}
                {/*    display: 'flex',*/}
                {/*    width: '100%',*/}
                {/*    alignItems: 'center',*/}
                {/*    justifyContent: 'start',*/}
                {/*    marginTop: '8px'*/}
                {/*}}>*/}
                {/*    <OfferIcon height={24} width={24}></OfferIcon>*/}
                {/*    <span className={"plan-variants-offer"}>{`${pause_limit} Days of membership pause`}</span>*/}
                {/*    <span onClick={setShowPauseBanner.bind(null, true)} style={{*/}
                {/*        display: "flex",*/}
                {/*        width: "20px",*/}
                {/*        height: "20px",*/}
                {/*        padding: "10px",*/}
                {/*        marginLeft: '4px',*/}
                {/*        justifyContent: "center",*/}
                {/*        alignItems: "center",*/}
                {/*        gap: "10px",*/}
                {/*        alignSelf: 'center',*/}
                {/*        position: 'relative',*/}
                {/*        borderRadius: "10px",*/}
                {/*        border: "0.64px solid var(--purple-gradient, #534DE0)"*/}
                {/*    }}>*/}
                {/*        <svg style={{position: 'absolute'}} xmlns="http://www.w3.org/2000/svg" width="13" height="12"*/}
                {/*             viewBox="0 0 13 12" fill="none">*/}
                {/*            <path*/}
                {/*                d="M7.25 10.5001V4.5001H5V4.8751H5.75V10.5001H5V10.8751H8V10.5001H7.25ZM6.49531 3.38682C7.11875 3.38682 7.625 2.88057 7.625 2.25713C7.625 1.63369 7.11875 1.12744 6.49531 1.12744C5.87188 1.12744 5.36563 1.63369 5.36563 2.25713C5.36563 2.88057 5.87188 3.38682 6.49531 3.38682Z"*/}
                {/*                fill="#534DE0"/>*/}
                {/*        </svg>*/}
                {/*    </span>*/}
                {/*</div>}*/}

                {/*<Button style={{width:'160px',height:'40px',marginTop:'12px',alignSelf:'center'}} isSticky={false} onClick={onClick.bind(null,index)} label={'Buy Now'}/>*/}
            </div>
        </CustomCard>
    );
};

export default memo(PlanVariant);
